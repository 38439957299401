import ChangeOwnPassword from "../../containers/ChangeOwnPassword";
import EditPatientProfile from "../../features/EditPatientProfile";
import DashboardHome from "./DashboardHome";
import PrescriptionList from "./PrescriptionList";

export const PATIENT_ROUTES = {
  changePassword: '/patient/profile/changePassword',
  profile: '/patient/profile/edit',
  editPatient: '/patient/profile/:patientId',
  home: '/patient',
  recipeList: '/patient/recipies/:patientId',
}

const routes = [
  {
    path: PATIENT_ROUTES.changePassword,
    exact: true,
    page: ChangeOwnPassword
  },
  {
    path: PATIENT_ROUTES.profile,
    exact: true,
    page: EditPatientProfile
  },
  {
    path: PATIENT_ROUTES.home,
    exact: true,
    page: DashboardHome
  },
  {
    path: PATIENT_ROUTES.recipeList,
    exact: true,
    page: PrescriptionList
  },
];

export default routes;
