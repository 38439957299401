import React from 'react';
import AddPrescriptionModal from './AddPrescriptionModal';
import RegionProvider from '../../providers/RegionProvider';
import PrescriptionProvider from '../../providers/PrescriptionProvider';
import PharmacyProvider from '../../providers/PharmacyProvider';

export default (props) => {
    const { patientData } = props,
        { countyId, cityId, id: patientId } = patientData;

    return (
        <RegionProvider>
            <PharmacyProvider>
                <PrescriptionProvider isAdd={true} countyId={countyId} cityId={cityId} patientId={patientId} patientData={patientData}>
                    <AddPrescriptionModal {...props} />
                </PrescriptionProvider>
            </PharmacyProvider>
        </RegionProvider>
    )
};
