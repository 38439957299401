import React from "react";
import DrawerContentList from './DrawerContentList';
import { Divider } from "@material-ui/core";

export interface ListItem {
  href: string;
  action?: () => {}
  title: string;
  icon?: any;
}

export interface DrawerItems {
  type: string;
  title: string;
  items: ListItem[];
}

export const DrawerContent = (props) => {
  const { onMenuItemSelect, drawerItems } = props;

  const renderItems = () => {
    return drawerItems.map(drawerContent => {
      return drawerContent.type === "divider" ? (
        <Divider key={drawerContent.title} />
      ) : (
          <DrawerContentList
            items={drawerContent.items}
            onMenuItemSelect={onMenuItemSelect}
            key={drawerContent.title}
          />
        )
    });
  }

  return (
    <React.Fragment>
      {renderItems()}
    </React.Fragment>
  );
}

export default DrawerContent;
