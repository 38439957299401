import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

interface NotificationComponentProps {
  message: string;
  duration?: number;
  type: "success" | "info" | "warning" | "error" | undefined;
  onClose?: ((event: React.SyntheticEvent<Element, Event>) => void) | undefined;
}

export default function NotificationComponent(props: NotificationComponentProps) {
  const { duration, message, type, onClose } = props,
    classes = useStyles();

  if (!message) {
    return null;
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={true}
      onClose={onClose}
      className={classes.root}
      autoHideDuration={duration || null}
    >
      <MuiAlert severity={type} onClose={onClose} variant="filled">
        {message}
      </MuiAlert>
    </Snackbar>
  );
};
