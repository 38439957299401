import React from 'react';
import { withTranslation } from 'react-i18next';
import RecoverPasswordStyles from './RecoverPasswordStyles';
import RecoverPasswordValidations from './RecoverPasswordValidations';
import { makeValidate, makeRequired } from 'mui-rff';
import { Container, CssBaseline, Grid, Button } from '@material-ui/core';
import { Form } from 'react-final-form';
import { Alert } from '@material-ui/lab';
import FormFieldsRenderer from '../FormElements/FormFieldsRenderer';

const renderFormError = (error, t) => {
  if (!error || !error.errorCode) {
    return null;
  }

  return (
    <Grid container style={{ paddingTop: 20 }}>
      <Grid item xs>
        <Alert severity={error.severity || 'warning'} variant='outlined'>
          {t(`errors.${error.errorCode}`)}
        </Alert>
      </Grid>
    </Grid>
  );
}

const renderForm = (classes, requiredFields, t, isSubmitting) => ({ handleSubmit }) => {
  const formFields = [
      {
        label: t('recoverPassword.email'),
        prefix: 'recoverPassword',
        isRequired: requiredFields.email,
        fieldType: 'Text',
        isFullWidth: true,
        subType: 'text',
        name: 'email'
      }
    ];

  return (
    <form className={classes.form} onSubmit={handleSubmit} noValidate>
      <Grid container spacing={1}>
        <FormFieldsRenderer
          fields={formFields as any}
        />
      </Grid>

      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={isSubmitting}
      >
        {t('recoverPassword.buttonLabel')}
      </Button>
    </form>
  );
};

const RecoverPasswordForm = (props) => {
  const classes = RecoverPasswordStyles(),
    { error, onSubmit, t, isSubmitting } = props,
    validationSchema = RecoverPasswordValidations(t),
    validate = makeValidate(validationSchema),
    required = makeRequired(validationSchema);

  return (
    <Container component="section" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>

        {renderFormError(error, t)}

        <Form
          onSubmit={onSubmit}
          validate={validate}
          validateOnBlur={false}
          render={renderForm(classes, required, t, isSubmitting)}
        />

      </div>
    </Container>
  );
};

export default withTranslation()(RecoverPasswordForm);
