import React, { useContext, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import {
  registerPatient,
} from '../../services/patientServices';
import { notifcationError, notificationSuccess } from '../../reducers/notificationReducer';
import addPatientModalForm from '../../forms/addPatientModal/addPatientModal';
import addPatientModalValidation from '../../forms/addPatientModal/addPatientModalValidation';
import RegistrationForm from '../../components/RegistrationForm/RegistrationForm';
import { FORM_ERROR } from 'final-form';
import { getLoggedInUserId } from '../../helpers/authSession';
import { copyObject } from '../../helpers/utilities';
import useRegions from '../../hooks/useRegions';
import { PharmacyContext } from '../../providers/PharmacyProvider';
import { RegionContext } from '../../providers/RegionProvider';

const AddPatientModal = props => {
  const dispatch = useDispatch(),
    {
      isOpen,
      onClose,
      t
    } = props,
    regionProps = useContext(RegionContext),
    pharmacyContext = useContext(PharmacyContext),
    { handleCountyChange, handleRegionChange } = useRegions(async (countyId, cityId) => {
      pharmacyContext.getPharmacies(countyId, cityId)
    });

  const handleSubmit = async (formData) => {
    formData.pharmacyId = formData.pharmacy && formData.pharmacy.id;
    delete formData.pharmacy;

    const augmentedFormData = copyObject(formData);
    augmentedFormData.physicianId = getLoggedInUserId();

    try {
      await registerPatient(augmentedFormData);

      onClose(true);

      setTimeout(() => {
        dispatch(notificationSuccess(t('patientProfile.registrationComplete')));
      }, 0);
    } catch (err) {
      dispatch(notifcationError(err.requestError.message));

      return {
        [FORM_ERROR]: err.requestError.message
      };
    };
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="add-receipe-dialog-title"
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
    >
      <DialogTitle id="add-receipe-dialog-title">{t('registerPatient.title')}</DialogTitle>
      <DialogContent>
        <RegistrationForm
          {...props}
          onSubmit={handleSubmit}
          regionProps={regionProps as any}
          pharmacyProps={pharmacyContext}
          onCountyChange={handleCountyChange}
          onRegionChange={handleRegionChange}
          onCancel={onClose}
          validationSchema={addPatientModalValidation(t)}
          createFormFields={addPatientModalForm}
          submitButtonLabel={t('patientProfile.registerButtonLabel')}
        />
      </DialogContent>
    </Dialog>
  )
};

const mapStateToProps = (state) => ({
  regionProps: state.regions,
  pharmacyProps: state.pharmacies
});


export default connect(mapStateToProps)(withTranslation()(AddPatientModal));
