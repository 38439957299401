import { Container, Typography, Avatar, Grid } from "@material-ui/core";
import React from "react";
import PublicAppHeader from "../PublicAppHeader/PublicAppHeader";
import publicFormWrapperStyles from './PublicFormWrapperStyles';

const PublicFormWrapper = (props) => {
  const classes = publicFormWrapperStyles(),
    { children, icon, title } = props;

  return (
    <Container component="div" maxWidth='sm'>
      <PublicAppHeader />
      <Container component="main" className={classes.paper}>
        <Typography component="h1" variant="h5" className={classes.title}>
          <Avatar className={classes.avatar}>
            {icon}
          </Avatar>

          {title}
        </Typography>
      </Container>

      <Container component="main" className={classes.paper}>
        <Grid container className={classes.formContainer}>
          {children}
        </Grid>
      </Container>
    </Container>
  );
}

export default PublicFormWrapper;
