import { useState, useEffect } from "react";
import store from "../../store";
import { displayLoader, clearLoader } from "../../reducers/genericLoader";
import { notifcationError } from "../../reducers/notificationReducer";
import { getPatientById } from "../../services/patientServices";

const usePrescriptionLoader = (patientId, t): editDataInterface => {
  const [initialValues, setInitialValues] = useState({
    isLoading: false,
    isError: false,
    error: undefined
  }),
  [profileData, setProfileData] = useState({}),
    { dispatch } = store;

    const loadInitialData = async () => {
    try {
      if(!patientId) {
        setInitialValues({
          isError: true,
          error: t('errors.errPatiantLoad'),
          isLoading: false
        });

        return;
      }

      dispatch(displayLoader(t('loader.loadingInitialData')));
      setInitialValues({
        isLoading: true,
        isError: false,
        error: undefined
      });
  
      const patient = await getPatientById(patientId);
  
      setProfileData(patient);

      setInitialValues({
        isLoading: false,
        isError: false,
        error: undefined
      });
    } catch (exc) {
      dispatch(notifcationError(t('loader.dataLoadError')));
  
      setInitialValues({
        isError: true,
        error: exc,
        isLoading: false
      });
    } finally {
      dispatch(clearLoader());
    }
  }

  useEffect(() => {
    loadInitialData();
  }, [patientId]);

  return {
    initialValues,
    profileData
  };
}

export default usePrescriptionLoader;
