import React from 'react';
import { withTranslation } from 'react-i18next';
import ChangePasswordFormStyle from './ChangePasswordFormStyle';
import ChangePasswordFormValidations from './ChangePasswordFormValidations';
import { makeValidate, makeRequired } from 'mui-rff';
import { Container, CssBaseline, Grid, Button } from '@material-ui/core';
import { Form } from 'react-final-form';
import { Alert } from '@material-ui/lab';
import FormFieldsRenderer from '../FormElements/FormFieldsRenderer';

const renderFormError = (error, t) => {
  if (!error || !error.errorCode) {
    return null;
  }

  return (
    <Grid container style={{ paddingTop: 20 }}>
      <Grid item xs>
        <Alert severity={error.severity || 'warning'} variant='outlined'>
          {t(`errors.${error.errorCode}`)}
        </Alert>
      </Grid>
    </Grid>
  );
}

const renderForm = (classes, requiredFields, t, isSubmitting) => ({ handleSubmit }) => {
  const formFields = [
    {
      label: t('changePassword.oldPassword'),
      prefix: 'oldPassword',
      isRequired: requiredFields.oldPassword,
      fieldType: 'Text',
      isFullWidth: true,
      subType: 'password',
      name: 'oldPassword',
      withNoStrengthMetter: true
    },
    {
      label: t('changePassword.newPassword'),
      prefix: 'newPassword',
      isRequired: requiredFields.newPassword,
      fieldType: 'Text',
      isFullWidth: true,
      subType: 'password',
      name: 'newPassword',
      withNoStrengthMetter: false
    },
    {
      label: t('changePassword.passwordConfirmation'),
      prefix: 'passwordConfirmation',
      isRequired: requiredFields.passwordConfirmation,
      fieldType: 'Text',
      isFullWidth: true,
      subType: 'password',
      name: 'passwordConfirmation',
      withNoStrengthMetter: true
    }
  ];

  return (
    <form className={classes.form} onSubmit={handleSubmit} noValidate>
      <Grid container spacing={1}>
        <FormFieldsRenderer
          fields={formFields as any}
        />
      </Grid>

      <Button
        type="submit"
        fullWidth
        variant="outlined"
        color="primary"
        className={classes.submit}
        disabled={isSubmitting}
      >
        {t('changePassword.buttonLabel')}
      </Button>
    </form>
  );
}

const ChangePasswordForm = (props) => {
  const classes = ChangePasswordFormStyle(),
    { error, onSubmit, t, isSubmitting } = props,
    validationSchema = ChangePasswordFormValidations(t),
    validate = makeValidate(validationSchema),
    required = makeRequired(validationSchema);

  return (
    <Container component="section" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>

        {renderFormError(error, t)}

        <Form
          onSubmit={onSubmit}
          validate={validate}
          validateOnBlur={false}
          render={renderForm(classes, required, t, isSubmitting)}
        />

      </div>
    </Container>
  );
};

export default withTranslation()(ChangePasswordForm);
