import React from 'react';
import { lighten, withStyles, LinearProgress } from "@material-ui/core";
import orange from '@material-ui/core/colors/orange';

const COLOR_ORANGE = orange[500];

const BorderLinearProgressOrange = withStyles({
  root: {
    height: 5,
    backgroundColor: lighten(COLOR_ORANGE, 0.5),
  },
  bar: {
    borderRadius: 20,
    backgroundColor: COLOR_ORANGE,
  },
})(LinearProgress);

export interface ProgressBarProps {
  barStyles?: string;
  value: number;
}

const ProgressBarOrange = (props: ProgressBarProps) => (
  <BorderLinearProgressOrange
        className={props.barStyles}
        variant="determinate"
        color="primary"
        value={props.value}
      />
);

export default ProgressBarOrange;
