import React from 'react';

import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps, IconButton, FormHelperText, InputLabel } from '@material-ui/core';

import { Field, FieldProps, FieldRenderProps } from 'react-final-form';
import { showError } from './FormError';

export const TYPE_PASSWORD = 'password';
export const TYPE_TEXT = 'text';
export const TYPE_EMAIL = 'email';
export const TYPE_NUMBER = 'number';
export const TYPE_URL = 'url';
export const TYPE_TELEPHONE = 'tel';
export const TYPE_DATE = 'date';
export const TYPE_DATETIME_LOCAL = 'datetime-local';
export const TYPE_MONTH = 'month';
export const TYPE_TIME = 'time';
export const TYPE_WEEK = 'week';
export const TYPE_PHOTO = 'photo';

// Restricts the type values to 'password', 'text', 'email', 'number', and 'url'.
export type TEXT_FIELD_TYPE =
  | typeof TYPE_PASSWORD
  | typeof TYPE_TEXT
  | typeof TYPE_EMAIL
  | typeof TYPE_NUMBER
  | typeof TYPE_URL
  | typeof TYPE_TELEPHONE
  | typeof TYPE_DATE
  | typeof TYPE_DATETIME_LOCAL
  | typeof TYPE_MONTH
  | typeof TYPE_TIME
  | typeof TYPE_WEEK
  | typeof TYPE_PHOTO;

export type TextFieldProps = Partial<Omit<MuiTextFieldProps, 'type'>> & {
  name: string;
  type?: TEXT_FIELD_TYPE;
  fieldProps?: Partial<FieldProps<any, any>>;
  onInputChange?: (event: object) => void;
  InputProps?: any;
  uploadIcon?: any;
};

export default function TextField(props: TextFieldProps) {
  const { name, type, fieldProps, onInputChange: onValueChange, uploadIcon, ...rest } = props;

  return (
    <Field
      name={name}
      type={type}
      render={({ input, meta }) => (
        <TextFieldWrapper input={input} meta={meta} name={name} type={type} uploadIcon={uploadIcon} onValueChange={onValueChange} {...rest} />
      )}
      {...fieldProps}
    />
  );
}

interface TextWrapperProps extends FieldRenderProps<MuiTextFieldProps, HTMLElement> { }

export function TextFieldWrapper(props: TextWrapperProps) {
  const {
    input: { name, value, type, onChange, ...restInput },
    meta,
    required,
    fullWidth = true,
    helperText,
    onValueChange,
    uploadIcon: UploadIcon,
    ...rest
  } = props;

  const { error, submitError } = meta;
  const isError = showError({ meta });
  const onChangeFunc = onValueChange ? (event: React.ChangeEvent<HTMLInputElement>) => {
    if (type === TYPE_PHOTO) {
      const files = event.target.files;
      onValueChange(files);
      onChange(files);

      return;
    }

    onValueChange(event);
    onChange(event);
  } : onChange;

  return type === TYPE_PHOTO ? (
    <React.Fragment>
      <MuiTextField
        fullWidth={fullWidth}
        helperText={isError ? error || submitError : helperText}
        error={isError}
        onChange={onChangeFunc}
        name={name}
        id={name}
        required={required}
        inputProps={{ required, ...restInput }}
        {...rest}
        type={'file'}
        style={{ display: 'none', }}
      />
      <label htmlFor={name}>
        <InputLabel >{props.label}</InputLabel>
        <IconButton color="primary" component="span">
          <UploadIcon />
        </IconButton>

        <br />
        {isError ? (<FormHelperText error={true}>{error || submitError}</FormHelperText>) : ''}
      </label>
    </React.Fragment>
  ) : (
      <MuiTextField
        fullWidth={fullWidth}
        helperText={isError ? error || submitError : helperText}
        error={isError}
        onChange={onChangeFunc}
        name={name}
        value={value}
        type={type}
        required={required}
        inputProps={{ required, ...restInput }}
        {...rest}
      />
    );
}
