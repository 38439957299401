import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import WebNotifications from './WebNotifications';
import PushNotificationProvider from '../../providers/PushNotificationProvider';
import ProfileMenu from './ProfileMenu';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    sectionDesktop: {
      display: 'flex',
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'nont',
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
  }),
);

const ToolbarMenu = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.sectionDesktop}>
      <PushNotificationProvider>
        <WebNotifications />
      </PushNotificationProvider>

      <ProfileMenu {...props} />
    </div>
  );
}

export default ToolbarMenu;
