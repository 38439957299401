import { makeStyles, createStyles } from "@material-ui/core";

const prescriptionList = makeStyles((theme) =>
  createStyles({
    details: {
      margin: '5px',
      padding: '10px'
    },
    detailsTypography: {
      display: 'flex'
    },
    detailsLabel: {
      marginLeft: '10px',
      fontWeight: 700
    },
    detailsData: {
      marginLeft: '5px'
    },
    actionContainer: {
      marginTop: '10px'
    },
    image: {
      maxHeight: '50px'
    },
    headerActions: {
      textTransform: 'none',
      fontSize: '0.7rem',
      padding: '4px',
    },
    headerActionsContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexFlow: 'wrap',
      fontSize: '12px'
    },
  })
);

export default prescriptionList;
