import { useState } from 'react';
import React from 'react';

const useEditPatient = (selectedPatientData, dialog, tableRef) => {
  const [isEditPacientDialogOpen, toggleEditPatientDialog] = useState(false);

  const toggleEditPatient = () => {
    toggleEditPatientDialog(!isEditPacientDialogOpen);
  };

  const onCloseModal = (needsReload?: boolean) => {
    toggleEditPatient();

    if(needsReload && typeof needsReload === 'boolean') {
      tableRef.current && tableRef.current.onQueryChange()
    }
  }

  const renderModal = () => {
    if (!isEditPacientDialogOpen) {
      return null;
    }

    const DialogComponent = dialog;
    return (
      <DialogComponent
        onClose={onCloseModal}
        isOpen={true}
        patientData={selectedPatientData}
      />
    );
  }
  return {
    isEditPacientDialogOpen,
    toggleEditPatient,
    editPatientModal: renderModal()
  };
}

export default useEditPatient;
