import * as Yup from 'yup';

export default function PatientProfileEditFormValidation(t): Yup.InferType<any> {
  // We define our schema based on the same keys as our form:
  return Yup.object().shape({
    firstName: Yup.string().required(t('common.registrationFormValidations.firstName')),
    lastName: Yup.string().required(t('common.registrationFormValidations.lastName')),
    idNumber: Yup.string().matches(/^[0-9]{13}$/, {
      message: t('common.registrationFormValidations.idnumberInvalid')
    }).required(t('common.registrationFormValidations.idNumber')),
    address: Yup.string().required(t('common.registrationFormValidations.address')),
    countyId: Yup.string().required(t('common.registrationFormValidations.county')),
    cityId: Yup.string().required(t('common.registrationFormValidations.city')).nullable(),
    phone: Yup.string().matches(/^[0-9]{10}$/, {
      message: t('common.registrationFormValidations.phoneInvalid')
    }).required(t('common.registrationFormValidations.phone'))
  });
};
