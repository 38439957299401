import { PATEIENT_URLS } from '../urlPatterns';
import { get, post, patch } from './requestService';
import RequestErrorException from './genericRequestError';

export function registerPatient(patientData: any): Promise<Array<any>> {
  return post(PATEIENT_URLS.register, patientData)
    .then((response: Promise<any>) => response || [])
    .catch(err => {
      throw RequestErrorException(err);
    });
};

export function getPatientById(patientId: string): Promise<any> {
  return get(PATEIENT_URLS.getById.replace('{id}', patientId))
    .then((response: Promise<any>) => response || {})
    .catch(err => {
      throw RequestErrorException(err);
    });
}

export function updatePatientProfile(patientId: string, data: any): Promise<any> {
  return patch(PATEIENT_URLS.updateProfile.replace('{id}', patientId), data)
    .then((response: Promise<any>) => response || {})
    .catch(err => {
      throw RequestErrorException(err);
    });
}

export function getMyPatients(perPage = 5, page = 0, search = '', sortBy='firstName', sortOrder='DESC') {
  return get(PATEIENT_URLS.myPatients, {
    size: perPage,
    page,
    search,
    sortBy,
    sortOrder
  })
    .then((response: Promise<any>) => response || {})
    .catch(err => {
      throw RequestErrorException(err);
    });
}
