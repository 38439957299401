import { createSlice, createAction } from '@reduxjs/toolkit';
import { initialLoadAuthSession } from '../helpers/authSession';

export const initialState = {
  error: null,
  token: initialLoadAuthSession(),
  isSubmitting: false
};

const authenticationReducer = createSlice({
  'name': 'regions',
  initialState,
  reducers: {
    authSubmitting: (state) => {
      return { ...state, isSubmitting: true }
    },
    authSuccess: (state, { payload }) => {
      return {
        token: payload,
        isSubmitting: false,
        error: null,
      };
    },
    authFailure: (state, { payload }) => {
      return {
        token: null,
        isSubmitting: false,
        error: payload
      }
    },
    logoutSuccess: () => initialState
  }
});

export const {
  authSubmitting,
  authSuccess,
  authFailure,
  logoutSuccess
} = authenticationReducer.actions;

export default authenticationReducer.reducer;

export const logout = createAction('LOGOUT_REQUEST');
export const authorize = createAction('AUTH_REQUEST', (username: string, password: string): LoginDataPayload => {
  return {
    payload: {
      username,
      password
    }
  };
});
