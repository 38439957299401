import * as Yup from 'yup';
require('../../genericValidators/password');
require('../../genericValidators/passwordMatcher');

export default function ChangePasswordFormValidations(t) {
  return Yup.object().shape({
    oldPassword: Yup.string().required(t('changePassword.validations.oldPassword')),
    newPassword: (Yup.string().required(t('changePassword.validations.newPassword')) as any)
      .isValidPassword(t('passwordField.validation')),
    passwordConfirmation: (Yup.string()
      .required(t('changePassword.validations.passwordConfirmation')) as any)
      .arePasswordsMatching(t('changePassword.validations.passwordMatchError'))
  });
}
