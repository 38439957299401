import React from "react";
import {
  createStyles,
  makeStyles,
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer
} from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import ToolbarMenu from './ToolbarMenu';
import logo from '../../logo_small.svg';
import DrawerContent from './DrawerContent';

const drawerWidth = 240;
const useMobileStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    grow: {
      flexGrow: 1,
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    logo: {
      maxHeight: '40px'
    },
    logoContainer: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

const MobileDrawer = (props) => {
  const mobileClasses = useMobileStyles(),
    [mobileOpen, setMobileOpen] = React.useState(false),
    { drawerItems, title, toolbarMenuItems } = props;

  const handleMobileDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className={mobileClasses.root}>
      <CssBaseline />

      <AppBar position="fixed" className={mobileClasses.appBar}>
        <Toolbar variant="dense" className={mobileClasses.root}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleMobileDrawerToggle}
            className={mobileClasses.menuButton}
          >
            <MenuIcon />
          </IconButton>

          <Typography variant="h6" noWrap className={mobileClasses.logoContainer}>
            <img src={logo} className={mobileClasses.logo} alt='Logo'></img>{title}
          </Typography>

          <div className={mobileClasses.grow} />
          <ToolbarMenu menuItems={toolbarMenuItems} onMenuClose={handleMobileDrawerToggle}></ToolbarMenu>
        </Toolbar>
      </AppBar>

      <nav className={mobileClasses.drawer} aria-label="mailbox folders">
        <Drawer
          variant="temporary"
          anchor={'left'}
          open={mobileOpen}
          onClose={handleMobileDrawerToggle}
          classes={{
            paper: mobileClasses.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <DrawerContent
            onMenuItemSelect={handleMobileDrawerToggle}
            drawerItems={drawerItems}
          />
        </Drawer>
      </nav>
    </div>
  );
}

export default MobileDrawer;
