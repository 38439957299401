import { useState } from 'react';
import React from 'react';

const useAddReceipe = (dialog, tableRef) => {
  const [isAddPacientDialogOpen, setIsAddPacientDialogOpen] = useState(false);

  const toggleAddPatient = () => {
    setIsAddPacientDialogOpen(!isAddPacientDialogOpen);
  };

  const onCloseModal = (needsReload?: boolean) => {
    toggleAddPatient();

    if(needsReload && typeof needsReload === 'boolean') {
      tableRef.current && tableRef.current.onQueryChange()
    }
  }

  const renderModal = () => {
    if (!isAddPacientDialogOpen) {
      return null;
    }

    const DialogComponent = dialog;
    return (
      <DialogComponent
        onClose={onCloseModal}
        isOpen={true}
      />
    );
  }
  return {
    isAddPacientDialogOpen,
    toggleAddPatient,
    addPatientModal: renderModal()
  };
}

export default useAddReceipe;
