import * as Yup from 'yup';
require('../../genericValidators/password');

export default function registerPharmacyValidations(t) {
  return Yup.object().shape({
    name: Yup.string().required(t('common.registrationFormValidations.name')),
    address: Yup.string().required(t('common.registrationFormValidations.cabinetAddress')),
    countyId: Yup.string().required(t('common.registrationFormValidations.county')),
    cityId: Yup.string().required(t('common.registrationFormValidations.city')).nullable(),
    phone: Yup.string().matches(/^[0-9]{10}$/, {
      message: t('common.registrationFormValidations.phoneInvalid')
    }).required(t('common.registrationFormValidations.phone')),
  });
};
