import React from 'react';
import { Typography, makeStyles, Theme, createStyles } from "@material-ui/core";
import { withTranslation } from 'react-i18next';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';

const COLORS = {
  RED: red[500],
  SILVER: grey[500],
  GREEN: green[500],
  ORANGE: orange[500],
};

const mainStyles = makeStyles((theme: Theme) =>
  createStyles({
    strengthLabel: {
      display: 'flex'
    },
    colorRed: {
      color: COLORS.RED
    },
    colorSilver: {
      color: COLORS.SILVER
    },
    colorGreen: {
      color: COLORS.GREEN
    },
    colorOrange: {
      color: COLORS.ORANGE
    }
  }));

const passwordStrengthLabel = (strength, t, classes) => {
  switch (strength) {
    case 0:
    case 1:
      return <span className={classes.colorRed}>{t('passwordField.strength.low')}</span>;
    case 2:
      return <span className={classes.colorOrange}>{t('passwordField.strength.medium')}</span>;
    case 3:
      return <span className={classes.colorGreen}>{t('passwordField.strength.strong')}</span>;
    case 4:
      return <span className={classes.colorGreen}>{t('passwordField.strength.extraStrong')}</span>;
    default: 
      return null;
  }
}

const StrengthLabel = (props) => {
  const { t, strength } = props,
    classes = mainStyles();

  return (
    <Typography className={classes.strengthLabel}>
      {props.t('passwordField.strengthLabel')} &nbsp; {passwordStrengthLabel(strength, t, classes)}
    </Typography>
  );
};

export default withTranslation()(StrengthLabel);
