import { useState, useEffect, useContext } from "react";
import { RegionContext } from "../providers/RegionProvider";
import store from '../store';

const useRegions = (selectionCallbackAction?) => {
  const regionContext = useContext(RegionContext);
  const [initialLoadDone, setInitialLoadDone] = useState(false);
  const handleCountyChange = async (countyId) => {
    if(regionContext.getCities) {
      await regionContext.getCities(countyId, countyId);
    }
  }

  const handleRegionChange = async (countyId, cityId) => {
    if (selectionCallbackAction) {
      selectionCallbackAction(countyId, cityId);
    }
  }

  useEffect(() => {
    (async () => {
      if (!initialLoadDone) {
        setInitialLoadDone(true);
  
        if (regionContext.getCounties) {
          await regionContext.getCounties();
        }
      }
    })();

  }, [initialLoadDone, regionContext]);


  return {
    handleCountyChange,
    handleRegionChange,
  };
}

export default useRegions;
