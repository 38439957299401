import React from "react";
import clsx from 'clsx';
import {
  createStyles,
  makeStyles,
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  Divider
} from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ToolbarMenu from './ToolbarMenu';
import logo from '../../logo_small.svg';
import DrawerContent from "./DrawerContent";

const drawerWidth = 240;
const useStylesDesktop = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    logo: {
      maxHeight: '40px'
    },
    logoContainer: {
      display: 'flex',
      alignItems: 'center',
    }
  }));

const DesktopDrawer = (props) => {
  const classes = useStylesDesktop(),
    [desktopOpen, setDesktopOpen] = React.useState(false),
    { drawerItems } = props;

  const handleDesktopDrawerOpen = () => {
    setDesktopOpen(true);
  };

  const handleDesktopDrawerClose = () => {
    setDesktopOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: desktopOpen,
        })}
      >
        <Toolbar className={classes.root} variant="dense">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDesktopDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: desktopOpen,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap className={classes.logoContainer}>
            <img src={logo} className={classes.logo} alt='Logo'></img>{props.title}
          </Typography>
          <div className={classes.grow} />
          <ToolbarMenu menuItems={props.toolbarMenuItems}></ToolbarMenu>
        </Toolbar>
      </AppBar>

      <nav className={classes.drawer} aria-label="mailbox folders">
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: desktopOpen,
            [classes.drawerClose]: !desktopOpen,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: desktopOpen,
              [classes.drawerClose]: !desktopOpen,
            }),
          }}
        >
          <React.Fragment>
            <div className={classes.drawerClose}>
              <IconButton onClick={handleDesktopDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />

            <DrawerContent
              drawerItems={drawerItems}
              onMenuItemSelect={handleDesktopDrawerClose}
            />

          </React.Fragment>
        </Drawer>
      </nav>
    </div>
  )
}

export default DesktopDrawer;

