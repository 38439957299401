import React from "react";
import {
  Hidden,
  Paper,
} from "@material-ui/core";
import {
  Switch,
} from "react-router-dom";
import MobileDrawer from "../../components/Toolbars/MobileDrawer";
import DesktopDrawer from "../../components/Toolbars/DesktopDrawer";
import PrivateRoute from "../../hocs/PrivateRoute";
import dashboardStyles from '../../styles/dashboard';
import routes from './routes';
import toolbarMenuItems from './toolbarMenuItems';
import { withTranslation } from "react-i18next";
import getDrawerItems from "./pharmacyDrawerItems";

const PharmacyDashboard = (props) => {
  const { t } = props;
  const classes = dashboardStyles(),
    augmentedToolbarMenus = toolbarMenuItems.map(menuItem => {
      menuItem.label = t(menuItem.translationKey);

      return menuItem;
    }),
    drawerItems = getDrawerItems(t);

  return (
    <React.Fragment>
      <Hidden smUp implementation="css">
        <MobileDrawer
          toolbarMenuItems={augmentedToolbarMenus}
          title={'Pharmacy'}
          drawerItems={drawerItems}
        />
      </Hidden>

      <Hidden xsDown implementation="css">
        <DesktopDrawer
          toolbarMenuItems={augmentedToolbarMenus}
          title={'Pharmacy'}
          drawerItems={drawerItems}
        />
      </Hidden>

      <main className={classes.main}>
        <Paper elevation={0}>
          <Switch>
            {
              routes.map((route, index) => <PrivateRoute
                key={`private_route_${route.path}_index`}
                path={route.path}
                exact={route.exact ? true : undefined}
                component={route.page} />
              )}
          </Switch>
        </Paper>
      </main>
    </React.Fragment>
  );
}

export default withTranslation()(PharmacyDashboard);
