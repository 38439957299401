import { call, put, takeLatest } from 'redux-saga/effects';

import Cache from '../services/cache';
import {
  getPharmaciesRequest,
  getPharmaciesSuccess,
  getPharmaciesFailure,
  fetchPharmacies as fetchPharmaciesAction
} from '../reducers/pharmaciesReducer';
import {
  getPharmacies as getPharmaciesServiceRequest,
} from '../services/pharmaciesService';

function* fetchAvailablePharmacies({ payload }) {
  const { countyId, cityId } = payload;

  const cachedPharmacies = Cache.getsubKey('pharmacies', `${countyId}_${cityId}`);

  if (cachedPharmacies) {
    yield put({ type: getPharmaciesSuccess.type, payload: cachedPharmacies });

    return;
  }

  try {
    yield put({
      type: getPharmaciesRequest.type, payload: {
        countyId,
        cityId
      }
    });
    const pharmacies = yield call(getPharmaciesServiceRequest, countyId, cityId);

    yield put({ type: getPharmaciesSuccess.type, payload: pharmacies });
    Cache.addsubKey('pharmacies', `${countyId}_${cityId}`, pharmacies);
  } catch (err) {
    yield put({ type: getPharmaciesFailure.type, payload: err.toString() });
  }
}

function* Saga() {
  yield takeLatest(fetchPharmaciesAction.type as any, fetchAvailablePharmacies);
}

export default Saga; 
