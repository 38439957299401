const getTranslations = (t) => {
  return {
    body: {
      emptyDataSourceMessage: t('dataTable.body.emptyDataSourceMessage'),
      addTooltip: t('dataTable.body.addTooltip'),
      deleteTooltip: t('dataTable.body.deleteTooltip'),
      editTooltip: t('dataTable.body.editTooltip'),
      filterRow: {
        filterTooltip: t('dataTable.body.filterTooltip')
      },
      editRow: {
        deleteText: t('dataTable.body.editRow.deleteText'),
        cancelTooltip: t('dataTable.body.editRow.cancelTooltip'),
        saveTooltip: t('dataTable.body.saveTooltip.saveTooltip')
      }
    },
    grouping: {
      placeholder: t('dataTable.grouping.placeholder')
    },
    header: {
      actions: t('dataTable.header.actions')
    },
    pagination: {
      labelDisplayedRows: t('dataTable.pagination.labelDisplayedRows'),
      labelRowsSelect: t('dataTable.pagination.labelRowsSelect'),
      labelRowsPerPage: t('dataTable.pagination.labelRowsPerPage'),
      firstAriaLabel: t('dataTable.pagination.firstAriaLabel'),
      firstTooltip: t('dataTable.pagination.firstTooltip'),
      previousAriaLabel: t('dataTable.pagination.previousAriaLabel'),
      previousTooltip: t('dataTable.pagination.previousTooltip'),
      nextAriaLabel: t('dataTable.pagination.nextAriaLabel'),
      nextTooltip: t('dataTable.pagination.nextTooltip'),
      lastAriaLabel: t('dataTable.pagination.lastAriaLabel'),
      lastTooltip: t('dataTable.pagination.lastTooltip'),
    },
    toolbar: {
      addRemoveColumns: t('dataTable.toolbar.addRemoveColumns'),
      nRowsSelected: t('dataTable.toolbar.nRowsSelected'),
      showColumnsTitle: t('dataTable.toolbar.showColumnsTitle'),
      showColumnsAriaLabel: t('dataTable.toolbar.showColumnsAriaLabel'),
      exportTitle: t('dataTable.toolbar.exportTitle'),
      exportAriaLabel: t('dataTable.toolbar.exportAriaLabel'),
      exportName: t('dataTable.toolbar.exportName'),
      searchTooltip: t('dataTable.toolbar.searchTooltip'),
      searchPlaceholder: t('dataTable.toolbar.searchPlaceholder'),
    }
  }
};

export default getTranslations;
