import { FormField } from "../../components/FormElements/FormFieldsRenderer";

const registerPharmacy = (t, requiredFields, regionProps ) => {
  const patientFormDefinitions = [
    {
      key: 'name'
    },
    {
      key: 'email'
    },
    {
      key: 'password',
      type: 'password'
    },
    {
      key: 'address'
    }
  ];

  const formFields: FormField[] = patientFormDefinitions.map(field => {
    const { key, type } = field;

    return {
      label: t(`common.registrationForm.${key}`),
      prefix: 'pharmacyProfile',
      isRequired: requiredFields[key],
      fieldType: 'Text',
      isFullWidth: true,
      subType: type || 'text',
      name: key,
      isDisabled: field.key === 'email'
    };
  });

  formFields.push(
    {
      label: t(`regionSelector.county`),
      prefix: 'pharmacyProfile',
      isRequired: true,
      regionProps: regionProps,
      name: 'region',
      fieldType: 'Region'
    },
    {
      label: t(`common.registrationForm.phone`),
      prefix: 'pharmacyProfile',
      isRequired: requiredFields.phone,
      fieldType: 'Text',
      isFullWidth: true,
      subType: 'text',
      name: 'phone'
    },
    {
      label: t('common.registrationForm.activationCode'),
      fieldType: 'Text',
      prefix: 'doctorProfile',
      isRequired: requiredFields.activationCode,
      isFullWidth: true,
      subType: 'text',
      name: 'activationCode'
    },
    {
      label: t('common.registrationForm.terms'),
      fieldType: 'Terms',
      prefix: 'pharmacyProfile',
      isRequired: requiredFields.termsAndConditions,
      isFullWidth: true,
      subType: 'text',
      name: 'terms'
    }
  );

  return formFields;
}

export default registerPharmacy;
