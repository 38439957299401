const getQueryStringParams = query => {
  return query
      ? (/^[?#]/.test(query) ? query.slice(1) : query)
          .split('&')
          .reduce((params, param) => {
                  let [key, value] = param.split('=');
                  params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                  return params;
              }, {}
          )
      : {}
};

const copyObject = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

export {
  getQueryStringParams,
  copyObject,
};
