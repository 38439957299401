import React, { MouseEvent } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { redirect } from '../../helpers/redirect';
import { ListItemIcon, ListItemText, makeStyles, createStyles } from '@material-ui/core';

export interface ToolbatMenuItem {
  label: string,
  link?: string,
  action?: (item) => void
}

export interface DrawerMenuProps {
  items: ToolbatMenuItem[],
  onMenuClose: () => void
}

const useIconMenuStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minWidth: '30px',
    },
  }));

const renderIconMenu = (item, handleMenuClick, classes) => {
  const Icon = item.icon;

  return (
    <MenuItem
      onClick={handleMenuClick}
      value={item.link}
      key={`${item.label}_${item.link}`}
    >
      <ListItemIcon className={classes.root}>
        <Icon fontSize='small' />
      </ListItemIcon>
      <ListItemText primary={item.label} />
    </MenuItem>
  );
};

const renderMenuItem = (item, handleMenuClick, classes) => (
  <MenuItem
    onClick={handleMenuClick}
    value={item.link}
    key={`${item.label}_${item.link}`}>
    {item.label}
  </MenuItem>
);

const renderItem = (item, handleMenuClick, classes) => {
  return item.icon
    ? renderIconMenu(item, handleMenuClick, classes) : renderMenuItem(item, handleMenuClick, classes);
};

const ToolbatMenuItems = (props: DrawerMenuProps) => {
  const handleMenuClick = (item, event: MouseEvent) => {
    props.onMenuClose();

    if (item.link) {
      redirect(item.link);
    }

    if (item.action) {
      item.action();
    }
  },
    classes = useIconMenuStyles();

  return (
    <React.Fragment>
      {props.items.map(item => renderItem(item, handleMenuClick.bind(null, item), classes))}
    </React.Fragment>
  );
};

export default ToolbatMenuItems;
