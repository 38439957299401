import React, { createContext, useContext, useEffect, useState } from 'react';
import { displayLoader, clearLoader } from "../reducers/genericLoader";
import { RegionContext, RegionsState } from './RegionProvider';
import { useDispatch } from 'react-redux';
import { notifcationError } from '../reducers/notificationReducer';
import { withTranslation } from 'react-i18next';
import { getPatientById } from '../services/patientServices';
import { getPharmacyById } from '../services/pharmaciesService';
import { PharmacyContext } from './PharmacyProvider';

export interface PhysicianDataState {
  isLoading: boolean
  isError: boolean
  error: any
  prescriptionData: any;
  regionProps?: RegionsState,
  patientProfile: any;
}

export const initialState: PhysicianDataState = {
  isLoading: false,
  isError: false,
  error: null,
  regionProps: {} as any,
  prescriptionData: {
    pharmacyId: ''
  },
  patientProfile: {}
};

export const PrescriptionContext = createContext(initialState);

const PrescriptionProvider = (props): any => {
  const [currentState, setCurrentState] = useState(initialState);
  const dispatch = useDispatch();
  const { countyId, cityId, isAdd, prescriptionData, patientData, t } = props;
  const regionContext = useContext(RegionContext) as any;
  const pharmacyContext = useContext(PharmacyContext);

  useEffect(() => {
    const handleAdd = async () => {
      const prescriptionDataTmp: any = {};
      dispatch(clearLoader());

      if(cityId && countyId) {
        await regionContext.getCountiesAndCity(countyId, countyId, cityId);
        prescriptionDataTmp.countyId = countyId;
        prescriptionDataTmp.cityId = cityId;
      } else {
        await regionContext.getCounties();
      }

      setCurrentState({
        ...initialState,
        isLoading: false,
        prescriptionData: prescriptionDataTmp,
        patientProfile: patientData
      });
    }

    const handleEdit = async () => {
      const prescriptionDataTmp: any = Object.assign({}, prescriptionData|| {});
      const { patientId, pharmacyId } = prescriptionDataTmp;

      try {
        if (!patientId) {
          setCurrentState({
            ...initialState,
            isError: true,
            error: t('errors.errPatiantLoad'),
            isLoading: false
          });
  
          return;
        }
  
        dispatch(displayLoader(t('loader.loadingInitialData')));
        setCurrentState({
          ...initialState,
          isLoading: true,
          isError: false,
          error: undefined
        });
  
        const patient = await getPatientById(patientId);
        let { countyId, cityId } = patient;
  
        if (pharmacyId) {
          const pharmacy = await getPharmacyById(pharmacyId);
  
          countyId = pharmacy.countyId;
          cityId = pharmacy.cityId;
        }
  
        prescriptionDataTmp.countyId = countyId;
        prescriptionDataTmp.cityId = cityId;
        prescriptionDataTmp.file = prescriptionDataTmp.photo;
  
        await (regionContext as any).getCountiesAndCity(countyId, countyId, cityId);
        await pharmacyContext.getPharmacies(countyId, cityId);

        setCurrentState({
          ...initialState,
          isLoading: false,
          isError: false,
          error: undefined,
          prescriptionData: prescriptionDataTmp,
          regionProps: regionContext,
          patientProfile: patient,
        });
      } catch (exc) {
        dispatch(notifcationError(t('loader.dataLoadError')));
  
        setCurrentState({
          ...initialState,
          isError: true,
          error: exc,
          isLoading: false
        });
      } finally {
        dispatch(clearLoader());
      }
    }
    const loadPrescriptionData = async () => {
      try {
        dispatch(displayLoader(t('loader.loadingInitialData')));

        setCurrentState({
          ...initialState,
          isLoading: true
        });

        isAdd ? await handleAdd() : await handleEdit();
      } catch (exc) {
        dispatch(notifcationError(t('loader.dataLoadError')));

        setCurrentState({
          ...initialState,
          error: exc,
          isError: true
        });
      } finally {
        dispatch(clearLoader());
      }
    }

    loadPrescriptionData();
  }, [isAdd]);

  return (
    <PrescriptionContext.Provider value={{
      ...currentState
    }}>
      {props.children}
    </PrescriptionContext.Provider>
  )
}

export default withTranslation()(PrescriptionProvider);
