import React, { PropsWithChildren } from "react";
import { useLocation } from 'react-router-dom'
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import routes from './routes';
import { Breadcrumbs as MUIBreadcrumbs, Link, makeStyles, createStyles } from "@material-ui/core";
import { redirect } from '../../helpers/redirect';
import { BREADCRUMB_ICONS, DOCTOR_ROUTES } from './routes';
import { withTranslation } from "react-i18next";

const useStyles = makeStyles(
  createStyles({
    breadcrumb: {
      marginLeft: '10px',
    },
    link: {
      display: 'flex',
      justifyItems: 'center',
      cursor: 'pointer',
    },
    icon: {
      marginRight: '5px',
    }
  }),
);

const Breadcrumbs = ({ breadcrumbs, t }: PropsWithChildren<any>) => {
  const currentLocation = useLocation(),
    classes = useStyles();

  if (currentLocation.pathname === DOCTOR_ROUTES.home) {
    return null;
  }

  return (
    <MUIBreadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
      {breadcrumbs.filter(({
        match
      }) => {
        return match.url !== '/';
      }).map(({
        match,
        breadcrumb
      }) => {
        const Icon = BREADCRUMB_ICONS[match.url];

        const renderIcon = () => {
          if (!Icon) {
            return null;
          }

          return <Icon fontSize='small' className={classes.icon}/>;
        }

        return (
          <Link
            color="inherit"
            onClick={redirect.bind(null, match.url, '')}
            key={match.url}
            className={classes.link}
          >
            {renderIcon()}
            {t(breadcrumb.props.children)}
          </Link>
        );
      })}
    </MUIBreadcrumbs>
  );
};

export default withBreadcrumbs(routes)(withTranslation()(Breadcrumbs) as any);
