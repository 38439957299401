import { PHYSICIAN_URL } from '../urlPatterns';
import { get, post, patch } from './requestService';
import RequestErrorException from './genericRequestError';

export function registerPhysician(doctorData: any): Promise<Array<any>> {
  return post(PHYSICIAN_URL.register, doctorData)
    .then((response: Promise<any>) => response || [])
    .catch(err => {
      throw RequestErrorException(err);
    });
};

export function getPhysicianById(physicianId: string): Promise<any> {
  return get(PHYSICIAN_URL.getById.replace('{id}', physicianId))
    .then((response: Promise<any>) => response || {})
    .catch(err => {
      throw RequestErrorException(err);
    });
}

export function updatePhysicianProfile(physicianId: string, data: any): Promise<any> {
  return patch(PHYSICIAN_URL.updateProfile.replace('{id}', physicianId), data)
    .then((response: Promise<any>) => response || {})
    .catch(err => {
      throw RequestErrorException(err);
    });
}
