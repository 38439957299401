import React from 'react';
import { withTranslation } from 'react-i18next';
import AddPrescriptionModalContainer from '../../containers/modals/AddPrescriptionModalContainer';
import AddPatientModalContainer from '../../containers/modals/AddPatientModalContainer';
import EditPatientModalContainer from '../../containers/modals/EditPatientModalContainer';
import PateintListComponent from '../../components/PatientList/PateintList';
import { redirect } from '../../helpers/redirect';
import { DOCTOR_ROUTES } from './routes';
import { Grid } from '@material-ui/core';
import PrescriptionsListComponent from './PrescriptionList';

const DashboardHome = (props) => {
  const handleRedirectToPrescriptions = (patientId) => {
    redirect(DOCTOR_ROUTES.patientRecipeList.replace(':patientId', patientId))
  },
    { t } = props;

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <PrescriptionsListComponent
            pageSize={5}
            hidePatientSelector={true}
          />
        </Grid>

        <Grid item xs={12}>
          <PateintListComponent
            addRecipeDialog={AddPrescriptionModalContainer}
            editPatientDialog={EditPatientModalContainer}
            addPatientDialog={AddPatientModalContainer}
            onRedirectToPrescriptions={handleRedirectToPrescriptions}
            pageSize={5}
            title={t('doctorDashboard.lastPatients')}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default withTranslation()(DashboardHome);
