import { post, deleteRequest, get, put } from './requestService';
import { AUTHENTICATION, USERS_URL, CREDENTIALS_URL } from '../urlPatterns';
import RequestErrorException from './genericRequestError';

export interface Login {
  username: string;
  password: string;
}

export const login = (data: Login) =>
  post(AUTHENTICATION.login, data)
    .then((response: Promise<any>) => response || {})
    .catch(err => {
      throw RequestErrorException(err);
    });

export const logout = () =>
  deleteRequest(AUTHENTICATION.logout)
    .then((response: Promise<any>) => response || {})
    .catch(err => {
      throw RequestErrorException(err);
    });

export const refreshToken = () =>
  put(AUTHENTICATION.refresh, null)
    .then((response: Promise<any>) => response || {});

export const me = () =>
  get(USERS_URL.me)
    .then((response: Promise<any>) => response || {})
    .catch(err => {
      throw RequestErrorException(err);
    });

export const changePassword = (oldPassword, newPassword, confirmPassword) =>
  put(CREDENTIALS_URL.changePassword, {
    oldPassword,
    newPassword,
    confirmPassword
  })
    .then((response: Promise<any>) => response || {})
    .catch(err => {
      throw RequestErrorException(err);
    });
