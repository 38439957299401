import { useState } from 'react';
import React from 'react';

const usePrintPrescription = (selectedPrescriptionData, patientData, patientId, dialog, tableRef, t) => {
  const [isPrescriptionPrintDialogOpen, setIsPrescriptionPrintDialogOpen] = useState(false);

  const togglePrintPrescriptionDialog = () => {
    setIsPrescriptionPrintDialogOpen(!isPrescriptionPrintDialogOpen);
  };

  const renderModal = () => {
    if (!isPrescriptionPrintDialogOpen) {
      return null;
    }

    const DialogComponent = dialog;
    return (
      <DialogComponent
        onClose={togglePrintPrescriptionDialog}
        isOpen={true}
        prescriptionData={selectedPrescriptionData}
        patientData={patientData}
        patientId={patientId}
      />
    );
  }

  return {
    isPrescriptionPrintDialogOpen,
    togglePrintPrescriptionDialog,
    printPrescriptionModal: renderModal()
  };
}

export default usePrintPrescription;
