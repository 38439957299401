import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { GLOBAL_ROUTES } from '../routes';

const Main = ({ token }) => {
  if (!token) {
    return <Redirect to={GLOBAL_ROUTES.login} />;
  }


  return <Redirect to={GLOBAL_ROUTES.doctorDashboard} />;
};

const mapStateToProps = (state) => ({
  token: state.auth.token
});

export default connect(mapStateToProps)(Main);
