import React, { ReactElement, PropsWithChildren } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  MenuItem,
} from '@material-ui/core';
import Select from './Select';

export interface CountySelectorProps extends PropsWithChildren<WithTranslation> {
  counties: string[]
  isRequired?: boolean,
  isDisabled?: boolean,
  isLoading?: boolean,
  onCountyChange: (countyId: string) => void
}

const countiesToOptions = (counties: string[] = []): ReactElement[] => (
  counties.map(county => (
    <option
      value={county}
      key={county}
      >
      {county}
    </option>)
  )
);

const CountySelector = (props) => {
  const { counties, t, onCountyChange, isRequired, isDisabled } = props,
    name = 'countyId';

  const handleCountyChange = (event) => {
    onCountyChange(event.target.value);
  }

  return (<Select
    name={name}
    key={name}
    label={t('regionSelector.county')}
    displayEmpty={true}
    onChange={handleCountyChange}
    required={isRequired}
    disabled={isDisabled}
    native
    formControlProps={{ margin: 'none' }}>
      {(countiesToOptions as any)(counties) as any}
  </Select>);
};

export default withTranslation()(CountySelector);
