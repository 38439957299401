import React, { createContext, useState } from 'react';
import Cache from '../services/cache';
import {
  getCounties as fetchCountiesService,
  getCitiesByCountyId as fetchCitiesByCountyIdService
} from '../services/regionService';

export interface RegionsState {
  countyError: string | null
  cityError: string | null
  isLoadingCounties: boolean
  isLoadingCities: boolean
  counties: Array<any>
  cities: Array<String>
  selectedTempCounty: string
  selectedTempCity: string
  getCounties?: () => Promise<any>
  getCities?: (countyId: string, selectedTempCounty?: string, selectedTempCity?: string, counties?: Array<any>) => Promise<any>
}

export interface InitialStateLoadedPayload {
  counties: Array<any>
  cities: Array<String>
  selectedTempCounty: string
  selectedTempCity: string
}

export const initialState: RegionsState = {
  countyError: null,
  cityError: null,
  isLoadingCounties: false,
  isLoadingCities: false,
  counties: [],
  cities: [],
  selectedTempCounty: '',
  selectedTempCity: '',
  getCounties: () => Promise.resolve(),
  getCities: () => Promise.resolve()
};

export const RegionContext = createContext(initialState);

const RegionProvider = (props) :any => {
  const [regionState, setRegionsState] = useState(initialState);

  const getCounties = async (returnValue=false) => {
    let counties = Cache.get('counties');
    if (!counties) {
      try {
        counties = await fetchCountiesService();
        Cache.add('counties', counties);
      }
      catch(exc) {
        return setRegionsState({
          ...initialState,
          counties: [],
          countyError: exc.requestError
        })
      }
    }

    if(returnValue) {
      return counties;
    }
  
    setRegionsState({
      ...initialState,
      counties
    });

    console.log('getCounties', regionState);
  }

  const updateCityState = (cities, selectedTempCounty, counties) => {
    console.log('updateCityState', regionState);
    setRegionsState({
      ...initialState,
      counties: counties || regionState.counties,
      isLoadingCities: false,
      cities: cities || [],
      selectedTempCounty: selectedTempCounty || regionState.selectedTempCounty
    });
  }

  const getCities = async (countyId, selectedTempCounty, selectedTempCity, counties) => {
    console.log('getCities', regionState);
    if (!countyId) {
      updateCityState([], selectedTempCounty, null);
      Cache.remove('cities');

      return;
    }

    const cachedCities = Cache.getsubKey('cities', countyId);
    if (cachedCities) {
      updateCityState(cachedCities, selectedTempCounty, counties);
  
      return;
    }

    setRegionsState({
      ...regionState,
      isLoadingCities: true
    });

    try {
      const cities = await fetchCitiesByCountyIdService(countyId);
      updateCityState(cities, selectedTempCounty, counties)

      Cache.add('cities', cities);
    }
    catch(exc) {
      setRegionsState({
        ...initialState,
        counties: regionState.counties,
        isLoadingCities: false,
        cities: [],
        cityError: exc.requestError,
        selectedTempCounty,
        selectedTempCity
      });
    }
  }

  const getCountiesAndCity = async (countyId, selectedTempCounty, selectedTempCity) => {
    const counties = await getCounties(true);
    await getCities(countyId, selectedTempCounty, selectedTempCity, counties);
  }

  const state = Object.assign(regionState, {getCounties, getCities, getCountiesAndCity});
  return (
    <RegionContext.Provider value={{...state
    }}>
      {props.children}
    </RegionContext.Provider>
  )
}

export default RegionProvider;
