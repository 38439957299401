import { makeStyles, createStyles } from "@material-ui/core";

const patientList = makeStyles((theme) =>
  createStyles({
    details: {
      margin: '5px',
      padding: '10px'
    },
    detailsTypography: {
      display: 'flex'
    },
    detailsLabel: {
      marginLeft: '10px',
      fontWeight: 700
    },
    detailsData: {
      marginLeft: '5px'
    },
    actionContainer: {
      marginTop: '10px'
    }
  })
);

export default patientList;
