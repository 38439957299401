import React from 'react';
import { withTranslation } from 'react-i18next';
import PrescriptionListComponent from '../../components/PrescriptionsList/PrescriptionList';
import EditPrescriptionModal from './EditPatientPrescription';
import DeletePrescriptionModal from '../../containers/DeletePrescriptionModal';
import PrintPrescription from '../../containers/PrintPrescription';
import { getLoggedInUserId } from '../../helpers/authSession';

const PrescriptionList = (props) => {
  return (
    <PrescriptionListComponent
      pageSize={props.pageSize || 10}
      editPrescriptionDialog={EditPrescriptionModal}
      deletePrescriptionDialog={DeletePrescriptionModal}
      printPrescriptionDialog={PrintPrescription}
      patientId={getLoggedInUserId()}
      actions={[
        'edit',
        'print'
      ]}
    />
  );
};

export default withTranslation()(PrescriptionList);
