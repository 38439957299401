import React, { createContext, useContext, useEffect, useState } from 'react';
import { displayLoader, clearLoader } from "../reducers/genericLoader";
import { RegionContext, RegionsState } from './RegionProvider';
import { useDispatch } from 'react-redux';
import { getPharmacyById } from '../services/pharmaciesService';
import { notifcationError } from '../reducers/notificationReducer';
import { withTranslation } from 'react-i18next';

export interface PharmacyDataState {
  isLoading: boolean
  isError: boolean
  error: any
  profileData: any;
  regionProps?: RegionsState
}

export const initialState: PharmacyDataState = {
  isLoading: false,
  isError: false,
  error: null,
  regionProps: {} as any,
  profileData: {}
};

export const PharmacyDataContext = createContext(initialState);

const PharmacyDataProvider = (props): any => {
  const [currentState, setCurrentState] = useState(initialState);
  const dispatch = useDispatch();
  const { pharmacyId, t } = props;
  const regionContext = useContext(RegionContext);

  useEffect(() => {
    const loadPharmacyData = async () => {
      try {
        dispatch(displayLoader(t('loader.loadingInitialData')));

        setCurrentState({
          ...initialState,
          isLoading: true
        });

        const pharmacyData = await getPharmacyById(pharmacyId),
          {
            countyId,
            cityId
          } = pharmacyData;

        if (!countyId || !cityId) {
          dispatch(notifcationError(t('loader.dataLoadError')));

          return;
        }

        await (regionContext as any).getCountiesAndCity(countyId, countyId, cityId);

        setCurrentState({
          isLoading: false,
          isError: false,
          error: undefined,
          profileData: pharmacyData,
          regionProps: regionContext,
        });
      } catch (exc) {
        dispatch(notifcationError(t('loader.dataLoadError')));

        setCurrentState({
          ...initialState,
          error: exc,
          isError: true
        });
      } finally {
        dispatch(clearLoader());
      }
    }

    loadPharmacyData();
  }, [pharmacyId]);

  return (
    <PharmacyDataContext.Provider value={{
      ...currentState
    }}>
      {props.children}
    </PharmacyDataContext.Provider>
  )
}

export default withTranslation()(PharmacyDataProvider);
