import React from 'react';
import AddPatientModal from './AddPatientModal';
import RegionProvider from '../../providers/RegionProvider';
import PharmacyProvider from '../../providers/PharmacyProvider';

export default (props) => {
    return (
        <RegionProvider>
            <PharmacyProvider>
                <AddPatientModal {...props} />
            </PharmacyProvider>
        </RegionProvider>
    )
};
