import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';

export interface RegionsState {
  countyError: string | null
  cityError: string | null
  isLoadingCounties: boolean
  isLoadingCities: boolean
  counties: Array<any>
  cities: Array<String>
  selectedTempCounty: string
  selectedTempCity: string
}

export interface InitialStateLoadedPayload {
  counties: Array<any>
  cities: Array<String>
  selectedTempCounty: string
  selectedTempCity: string
}

export const initialState: RegionsState = {
  countyError: null,
  cityError: null,
  isLoadingCounties: false,
  isLoadingCities: false,
  counties: [],
  cities: [],
  selectedTempCounty: '',
  selectedTempCity: ''
};

const regionsReducer = createSlice({
  'name': 'regions',
  initialState,
  reducers: {
    getCountiesRequest(state): RegionsState {
      return initialState;
    },
    getCountiesSuccess(state, action: PayloadAction<string[]>): RegionsState {
      return {
        ...initialState,
        counties: action.payload
      };
    },
    getCountiesFailure(state, action: PayloadAction<string>): RegionsState {
      return {
        ...initialState,
        counties: [],
        countyError: action.payload
      };
   },
    getCitiesRequest(state, action): RegionsState {
      return {
        ...initialState,
        counties: state.counties,
        isLoadingCities: true,
        cities: [],
        selectedTempCounty: action.payload
      }
    },
    getCitiesSucess(state, action: PayloadAction<string[]>): RegionsState {
      return {
        ...initialState,
        counties: state.counties,
        isLoadingCities: false,
        cities: action.payload,
        selectedTempCounty: state.selectedTempCounty
      }
    },
    getCitiesFailure(state, action: PayloadAction<string>): RegionsState {
      return { 
        ...initialState,
        counties: state.counties,
        isLoadingCities: false,
        cities: [],
        selectedTempCounty: state.selectedTempCounty,
        cityError: action.payload
      };
    },
    initialDataLoaded(state, action: PayloadAction<InitialStateLoadedPayload>) {
      return { 
        ...initialState,
        ...action.payload
      };
    }
  }
});

export const {
  getCountiesRequest,
  getCountiesSuccess,
  getCountiesFailure,
  getCitiesRequest,
  getCitiesSucess,
  getCitiesFailure,
  initialDataLoaded
} = regionsReducer.actions;

export default regionsReducer.reducer;

export const fetchCounties = createAction('FETCH_COUNTIES', () => {
  return { payload: {} };
});

export const fetchCitiesByCountyId = createAction('FETCH_CITES_BY_COUNTYID', (countyId) => {
  return {
    payload: countyId
  }
});
