import { makeStyles, createStyles } from '@material-ui/core/styles';

const dashboard = makeStyles((theme) =>
  createStyles({
    main: {
      display: 'flex',
      marginLeft: '10px',
      marginRight: '20px',
      paddingBottom: '20px',
      marginTop: '60px',
      flexDirection: 'column',
      maxWidth: 'calc(100% - 40px)',
      [theme.breakpoints.up('sm')]: {
        marginLeft: '90px',
        justifyContent: 'center',
      },
      width: '100%'
    },
  })
);

export default dashboard;
