import React from 'react';
import EditPrescriptionModal from './EditPrescriptionModal';
import RegionProvider from '../../providers/RegionProvider';
import PrescriptionProvider from '../../providers/PrescriptionProvider';
import PharmacyProvider from '../../providers/PharmacyProvider';

export default (props) => {
    const { prescriptionData } = props,
    { patientId, pharmacyId } = prescriptionData;

    return (
        <RegionProvider>
            <PharmacyProvider>
                <PrescriptionProvider isAdd={false} patientId={patientId} pharmacyId={pharmacyId} prescriptionData={prescriptionData}>
                    <EditPrescriptionModal {...props} />
                </PrescriptionProvider>
            </PharmacyProvider>
        </RegionProvider>
    )
};
