import React from "react";
import { useDispatch } from 'react-redux';
import ChangePasswordForm from "../components/ChangePassword/ChangePasswordForm";
import { Container, CssBaseline, Typography } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import changeOwnPasswordStyles from "../styles/changeOwnPassword";
import { changePassword } from "../services/userService";
import { notificationSuccess, notifcationError } from "../reducers/notificationReducer";
import { FORM_ERROR } from "final-form";
import { logout } from '../reducers/authenticationReducer';

const ChangeOwnPassword = (props) => {
  const classes = changeOwnPasswordStyles(),
    dispatch = useDispatch(),
    { t } = props;

  const handleSubmit = async (formData) => {
    try {
      const {
        oldPassword,
        newPassword,
        passwordConfirmation
      } = formData;

      await changePassword(oldPassword, newPassword, passwordConfirmation);

      dispatch(logout());

      setTimeout(() => {
        dispatch(notificationSuccess(t('changePassword.changed')));
      }, 0);
    } catch (err) {
      dispatch(notifcationError(err.requestError.message));

      return {
        [FORM_ERROR]: err.requestError.message
      };
    };
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          {t('changePassword.title')}
        </Typography>

        <ChangePasswordForm
          onSubmit={handleSubmit}
        />
      </div>
    </Container>
  );
}

export default withTranslation()(ChangeOwnPassword);
