import axios, { AxiosRequestConfig } from 'axios';
import { getToken } from '../helpers/authSession';
import { SERVER_URL } from '../urlPatterns';
import requestErrorWrapper from './requestErrorWrapper';

export const axiosInstance = axios.create({
  baseURL: SERVER_URL,
  timeout: 10 * 1000,
  paramsSerializer: function (params) {
    const outputParams = Object.keys(params || [])
      .reduce((paramsArray: string[], currentParam: string) => {
        const value = currentParam !== 'search'
          ? encodeURIComponent(params[currentParam])
          : params[currentParam];

        paramsArray.push(`${currentParam}=${params[currentParam]}`);

        return paramsArray;
      }, []);

    if(outputParams.length) {
      return outputParams.join('&');
    }
    
    return '';
  }
});

// Add a response interceptor
axiosInstance.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});

export const getConfig = (requestParams = {}, isMultipart = false): AxiosRequestConfig => {
  const config: AxiosRequestConfig = {},
    headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Credentials': true,
    },
    authToken = getToken();

  headers['Authorization'] = authToken ? `Bearer ${authToken}` : undefined;

  if (Object.keys(requestParams).length > 0) {
    config.params = requestParams;
  }

  if (isMultipart) {
    headers['Content-Type'] = 'multipart/form-data';
  }

  config.headers = headers;

  return config;
}

export const get = (url, params?): Promise<any> => {
  return requestErrorWrapper(axiosInstance.get(url, getConfig(params)));
}

export const post = (url, data, isMultipart = false): Promise<any> => {
  return requestErrorWrapper(axiosInstance.post(url, data, getConfig({}, isMultipart)));
}

export const put = (url, data, isMultipart = false): Promise<any> => {
  return requestErrorWrapper(axiosInstance.put(url, data, getConfig({}, isMultipart)));
}

export const patch = (url, data, isMultipart = false): Promise<any> => {
  return requestErrorWrapper(axiosInstance.patch(url, data, getConfig({}, isMultipart)));
}

export const deleteRequest = (url): Promise<any> => {
  return requestErrorWrapper(axiosInstance.delete(url, getConfig()));
}

export const dataToFormData = (data: any, multipartKeys: Array<string>): FormData => {
  const bodyFormData = new FormData(),
    keys = Object.keys(data);

  if (!multipartKeys.length) {
    return data;
  }

  keys.forEach(key => {
    if (multipartKeys.includes(key)) {
      const files = data[key];

      if (files && files.length && typeof files !== 'string') {
        if (files.length === 1) {
          bodyFormData.append(key, files[0]);
        } else {
          files.forEach((file, index) => {
            bodyFormData.append(`${key}[${index}]`, file);
          });
        }
      }

    } else {
      bodyFormData.set(key, data[key]);
    }
  })

  return bodyFormData;
}

