import * as Yup from 'yup';

export default Yup.addMethod(Yup.string, 'arePasswordsMatching', function(message: string) {
  return this.test('arePasswordsMatchingTest', message, function(value) {
    const { path, createError } = this,
      passwordRef = this.parent && this.parent.newPassword;

    if(!passwordRef) {
      return true;
    }

    return (passwordRef === value) || createError({
      path,
      message
    });
  });
});
