import React, { useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  TextFieldProps as MuiTextFieldProps,
  InputAdornment,
  IconButton,
  Grid,
} from '@material-ui/core';

import { FieldProps } from 'react-final-form';
import TextField from './TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import zxcvbn from 'zxcvbn';
import { withTranslation, WithTranslation } from 'react-i18next';
import StrengthBar from './PasswordField/StrengthBar';
import StrengthLabel from './PasswordField/StrengthLabel';

export type PasswordFieldProps = MuiTextFieldProps & WithTranslation & {
  name: string;
  type?: 'password';
  fieldProps?: Partial<FieldProps<any, any>>;
  onInputChange?: (event: object) => void;
  minStrength?: number;
  thresholdLength?: number;
  t: any;
  isRequired?: boolean;
  withNoStrengthMetter?: boolean;
};

const mainStyles = makeStyles((theme: Theme) =>
  createStyles({
    bar: {
      marginTop: '5px',
      // marginRight: '10px',
      width: '100%'
    },
    strength: {
      display: 'flex',
      flexDirection: 'column'
    },
  }));

export const PasswordField = (props: PasswordFieldProps) => {
  const [visible, setVisible] = useState(false),
    [strength, setStrength] = useState(0),
    classes = mainStyles(),
    { t, isRequired, withNoStrengthMetter, tReady, ...rest } = props;

  const toggleVisibility = () => {
    setVisible(!visible);
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleInputChange = (event: object) => {
    const value = (event as any)?.currentTarget.value;

    setStrength(zxcvbn(value).score);
  }
  const inputProps = {
    ...props.inputProps || {},
    color: 'primary',
    endAdornment: (<InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={toggleVisibility}
        onMouseDown={handleMouseDownPassword}
      >
        {visible ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>)
  };

  const renderStrengthBar = () => {
    if (withNoStrengthMetter) {
      return null;
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.strength}>
          <StrengthBar strength={strength} barStyles={classes.bar} />
          
          <StrengthLabel strength={strength} />
        </Grid>
      </Grid>
    )
  }

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <TextField
          {...rest}
          required={isRequired}
          type={visible ? 'text' : 'password'}
          InputProps={inputProps as any}
          onInputChange={handleInputChange}
        />
      </Grid>

      {renderStrengthBar()}
    </React.Fragment>
  )
};

export default withTranslation()(PasswordField);
