import { refreshToken as refreshTokenRequest } from "./userService";
import store from "../store";
import { logout } from '../reducers/authenticationReducer';
import { getAuthSession, setAuthSession } from "../helpers/authSession";
import { redirectToLoginWithError } from "../helpers/redirect";

const DEFAULT_REFRESH_INTERVAL = 1 * 1000 * 60 * 30;

class RefreshTokenService {
  interval: NodeJS.Timeout | null;

  constructor() {
    this.interval = null;
  }

  start(refreshInterval: number = DEFAULT_REFRESH_INTERVAL) {
    this.stop();
    this.interval = setInterval(this.runRefresh, refreshInterval);
  }

  stop() {
    try {
      if (this.interval) {
        clearInterval(this.interval);
      }
    } catch (exc) {
      console.log(exc);
    }
  }

  async runRefresh() {
    try {
      const refreshToken = await refreshTokenRequest();
      const currentSession = getAuthSession();
      
      if(currentSession && currentSession.token) {
        const newSession = JSON.parse(JSON.stringify(currentSession));
        newSession.token = refreshToken && refreshToken.token;
        setAuthSession(newSession);
        
        return;
      }

      store.dispatch(logout());
    } catch(exc) {
      store.dispatch(logout());

      redirectToLoginWithError();
    }
  }
}

export default new RefreshTokenService();
