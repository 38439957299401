import React from 'react';
import { Skeleton, Alert } from '@material-ui/lab';
import { Grid, Button, makeStyles } from '@material-ui/core';
import { CustomRequestError } from '../services/genericRequestError';

const styles = makeStyles((theme) => ({
  spacer: {
    marginTop: '20px'
  }
}));

const ComponentPlaceholder = (props) => {
  const {
    isComponentLoading,
    isComponentError,
    error,
    onErrorHandler,
    errorButtonLabel,
  } = props,
    classes = styles();

  if (isComponentLoading) {
    return (
      <div>
        <Skeleton variant="text" />
        <Skeleton variant="circle" width={40} height={40} />
        <Skeleton variant="rect" width={310} height={118} />
        <Skeleton variant="rect" width={310} height={118} />
        <Skeleton variant="rect" width={310} height={118} />
      </div>
    );
  }

  if (isComponentError) {
    let errorMessage = error instanceof CustomRequestError ? error.message : error;

    if(typeof errorMessage !== 'string') {
      errorMessage = error.message || 'Uknown error';
    }

    return (
      <Grid item xs>
        <Alert severity={'error'} variant='outlined'>
          {errorMessage}
        </Alert>

        <Button
          className={classes.spacer}
          fullWidth
          variant="outlined"
          color="primary"
          onClick={onErrorHandler}
        >
          {errorButtonLabel}
        </Button>
      </Grid>
    );
  }
  return props.children;
}

export default ComponentPlaceholder;
