import React from 'react';
import { lighten, withStyles, LinearProgress } from "@material-ui/core";
import grey from '@material-ui/core/colors/grey';

const COLOR_SILVER = grey[500];

const BorderLinearProgressSilver = withStyles({
  root: {
    height: 5,
    backgroundColor: lighten(COLOR_SILVER, 0.5),
  },
  bar: {
    borderRadius: 20,
    backgroundColor: COLOR_SILVER,
  },
})(LinearProgress);

export interface ProgressBarProps {
  barStyles?: string;
  value: number;
}

const ProgressBarSilver = (props: ProgressBarProps) => (
  <BorderLinearProgressSilver
        className={props.barStyles}
        variant="determinate"
        color="primary"
        value={props.value}
      />
);

export default ProgressBarSilver;
