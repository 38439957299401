import React, { useContext } from "react";
import { useDispatch } from 'react-redux';
import { withTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";

import PrescriptionForm from "../../components/PrescriptionForm/PrescriptionForm";
import ComponentPlaceholder from "../../hocs/ComponentPlaceholder";
import RequestErrorHeader from "../../components/FormElements/RequestErrorHeader";
import { registerPrescription } from "../../services/prescriptionServices";
import { notificationSuccess, notifcationError } from "../../reducers/notificationReducer";
import { FORM_ERROR } from "final-form";
import { dataToFormData } from "../../services/requestService";
import addPrescriptionForm from '../../forms/addPrescription/addPrescription';
import addRecipeValidations from '../../forms/addPrescription/addPrescriptionValidations';
import { copyObject } from "../../helpers/utilities";
import useRegions from '../../hooks/useRegions';
import { getLoggedInUserId } from "../../helpers/authSession";
import { PharmacyContext } from '../../providers/PharmacyProvider';
import { RegionContext } from '../../providers/RegionProvider';
import { PrescriptionContext } from "../../providers/PrescriptionProvider";

const AddPrescriptionModal = (props) => {
  const {
    isOpen,
    onClose,
    patientData,
    fromConfig,
    validationConfig,
    t
  } = props,
    { id: patientId } = patientData,
    dispatch = useDispatch(),
    regionProps = useContext(RegionContext),
    pharmacyContext = useContext(PharmacyContext),
    { handleCountyChange, handleRegionChange } = useRegions(async (countyId, cityId) => {
      pharmacyContext.getPharmacies(countyId, cityId)
    }),
    prescriptionContext = useContext(PrescriptionContext);

  const handleSubmit = async (formData) => {
    try {
      const augmentedFormData = copyObject(formData);
      augmentedFormData.file = formData.file;
      augmentedFormData.patientId = patientId;
      augmentedFormData.doctorId = getLoggedInUserId();
      if(!augmentedFormData.pharmacyId) {
        delete augmentedFormData.pharmacyId;
      }
      
      await registerPrescription(dataToFormData(augmentedFormData, ['file']));

      onClose(true);

      setTimeout(() => {
        dispatch(notificationSuccess(t('recipeForm.registrationComplete')));
      }, 0);
    } catch (err) {
      dispatch(notifcationError(err.requestError.message));

      return {
        [FORM_ERROR]: err.requestError.message
      };
    };
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="add-receipe-dialog-title"
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
    >
      <DialogTitle id="add-receipe-dialog-title">{t('addRecipe.title')}</DialogTitle>
      <DialogContent>

        <ComponentPlaceholder
          isComponentLoading={prescriptionContext.isLoading}
          isComponentError={prescriptionContext.isError}
          error={prescriptionContext.error}
          onErrorHandler={onClose}
          errorButtonLabel={t('recipeForm.errorButtonLabelModal')}
        >
          <RequestErrorHeader
            severity='error'
            requestError={(pharmacyContext as any).pharmaciesError}
          />

          <PrescriptionForm
            onSubmit={handleSubmit}
            patientData={patientData}
            pharmacyProps={pharmacyContext as any}
            onCancel={onClose}
            validationSchema={(validationConfig || addRecipeValidations)(t)}
            createFormFields={fromConfig || addPrescriptionForm}
            submitButtonLabel={t('recipeForm.registerButtonLabel')}
            backButtonLabel={t('recipeForm.backLabel')}
            onCountyChange={handleCountyChange}
            onRegionChange={handleRegionChange}
            initialValues={prescriptionContext.prescriptionData}
            regionProps={regionProps as any}
          />
        </ComponentPlaceholder>
      </DialogContent>
    </Dialog>
  );
};

export default withTranslation()(AddPrescriptionModal);
