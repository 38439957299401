import React from 'react';
import {
  Grid,
} from '@material-ui/core';
import CountySelector from './CountySelector';
import CitySelector from './CitySelector';
import { AutocompleteData } from 'mui-rff';

export interface RegionSelectorProps {
  cities: AutocompleteData[]
  counties: string[]
  isLoadingCounties?: boolean
  isLoadingCities?: boolean
  onCountyChange: (county: string) => void
  onRegionChange: (countyId: string, cityId: string) => void
  selectedTempCounty?: string
  selectedTempCity?: string
  isRequired?: boolean
  isDisabled?: boolean
  isFullWidth?: boolean
}

const RegionSelector = (props: RegionSelectorProps) => {
  const {
    cities,
    counties,
    isLoadingCities,
    onCountyChange,
    onRegionChange,
    selectedTempCounty,
    isRequired,
    isDisabled,
    isFullWidth,
  } = props;

  const handleCityChange = (cityId) => {
    onRegionChange(selectedTempCounty || '', cityId);
  }

  const handleCountyChange = (countyId) => {
    if(countyId !== selectedTempCounty) {
      onCountyChange(countyId);
    }
  }

  const cityIsDisabled =
    isDisabled || (typeof (selectedTempCounty) !== 'string' || selectedTempCounty === '');

  return (< Grid item xs={12} key='grid_region_selector'>
    <Grid item xs={12} key={'grid_county'}>
      <CountySelector
        counties={counties || []}
        onCountyChange={handleCountyChange}
        isRequired={isRequired}
        isDisabled={isDisabled}
        isFullWidth={isFullWidth}
      />
    </Grid>
    <Grid item xs={12} key='grid_city'>
      <CitySelector
        cities={cities || []}
        isDisabled={cityIsDisabled}
        isLoading={isLoadingCities}
        onCityChange={handleCityChange}
        isRequired={isRequired}
        isFullWidth={isFullWidth}
      />
    </Grid>
  </Grid >);
};

export default RegionSelector;
