import { INVITATIONS_URL } from '../urlPatterns';
import { get } from './requestService';
import RequestErrorException from './genericRequestError';

export function getInvite(inviteId: string): Promise<any> {
  return get(INVITATIONS_URL.getInvitation.replace('{id}', inviteId))
    .then((response: Promise<any>) => response || {})
    .catch(err => {
      throw RequestErrorException(err);
    });
};
