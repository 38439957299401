import authenticationReducer,
  {initialState as authenticationInitialState} from './authenticationReducer';
import { combineReducers } from '@reduxjs/toolkit'
import notificationReducer from './notificationReducer';
import regionsReducer, {initialState as regionsInitialState} from './regionsReducer';
import pharmaciesReducer, {initialState as pharmaciesInitialState} from './pharmaciesReducer';
import genericLoaderReducer, {initialState as genericLoaderState} from './genericLoader';

export const initialState: any = {
  messages: [],
  authentication: authenticationInitialState,
  notification: {},
  regions: regionsInitialState,
  pharmacies: pharmaciesInitialState,
  genericLoader: genericLoaderState
};

const createRootReducer = () => combineReducers({
  auth: authenticationReducer,
  notification: notificationReducer,
  regions: regionsReducer,
  pharmacies: pharmaciesReducer,
  genericLoader: genericLoaderReducer
});

export default createRootReducer;
