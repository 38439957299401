import { call, put, takeLatest } from 'redux-saga/effects';

import Cache from '../services/cache';
import {
  getCountiesRequest,
  getCountiesSuccess,
  getCountiesFailure,
  getCitiesRequest,
  getCitiesSucess,
  getCitiesFailure,
  fetchCounties as fetchCountiesRequest,
  fetchCitiesByCountyId as fetchCitiesByCountyIdRequest
} from '../reducers/regionsReducer';
import {
  getCounties as fetchCountiesService,
  getCitiesByCountyId as fetchCitiesByCountyIdService
} from '../services/regionService';

function* fetchAllCounties() {
  const cachedCounties = Cache.get('counties');
  if (cachedCounties) {
    yield put({ type: getCountiesSuccess.type, payload: cachedCounties });

    return;
  }

  try {
    yield put({ type: getCountiesRequest.type });
    const counties = yield call(fetchCountiesService);

    yield put({ type: getCountiesSuccess.type, payload: counties });
    Cache.add('counties', counties);
  } catch (err) {
    yield put({ type: getCountiesFailure.type, payload: err.requestError });
  }
}

function* fetchCitiesByCountyId({payload}) {
  const countyId = payload;
  
  if (!countyId) {
    yield put({ type: getCitiesSucess.type, payload: [] });
    Cache.remove('cities');

    return;
  }

  const cachedCities = Cache.getsubKey('cities', countyId);
  if (cachedCities) {
    yield put({ type: getCitiesSucess.type, payload: cachedCities });

    return;
  }

  try {
    yield put({ type: getCitiesRequest.type, payload: countyId });
    const counties = yield call(fetchCitiesByCountyIdService, countyId);

    yield put({ type: getCitiesSucess.type, payload: counties });
    Cache.add('cities', counties);
  } catch (err) {
    yield put({ type: getCitiesFailure.type, payload: err.requestError });
  }
}

function* Saga() {
  yield takeLatest(fetchCountiesRequest.type as any, fetchAllCounties);
  yield takeLatest(fetchCitiesByCountyIdRequest.type as any, fetchCitiesByCountyId);
}

export default Saga;
