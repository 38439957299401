import { getPrescriptionsByPatientId } from "../services/prescriptionServices";
import SearchBuilder from "../helpers/searchBuilder";

const patientList = (patientId, perPage, page, filter='', sortBy='status', sortOrder='DESC', prescriptionStatuses=[]): Promise<any> => {
  const search = new SearchBuilder();
  const searchBy = search
    .withContains('title', filter)
    .withOr()
    .withContains('notes', filter)
    .build();

  return getPrescriptionsByPatientId(patientId, perPage, page, filter ? searchBy : '', sortBy, sortOrder, prescriptionStatuses)
    .then(result => {
      return {
        data: result.prescriptions,
        page: page,
        totalCount: result.totalElements,
        search: searchBy
      };
    })
}

export default patientList;
