import { PHARMACIES_URL_COUNTY_CITY, PHARMACIES_URL_COUNTY, PHARMACY_URL } from '../urlPatterns';
import { get, post, patch } from './requestService';
import RequestErrorException from './genericRequestError';

export function getPharmacies(countyId, cityId): Promise<Array<any>> {
  let url = '';

  if (countyId) {
    url = PHARMACIES_URL_COUNTY.replace('{countyId}', countyId)
  }

  if (countyId && cityId) {
    url = PHARMACIES_URL_COUNTY_CITY.replace('{countyId}', countyId).replace('{cityId}', cityId);
  }

  return get(url)
    .then((response) => response.pharmacies || [])
    .catch(err => {
      throw RequestErrorException(err);
    });
};

export function registerPharmacy(pharmacyData: any): Promise<Array<any>> {
  return post(PHARMACY_URL.register, pharmacyData)
    .then((response: Promise<any>) => response || [])
    .catch(err => {
      throw RequestErrorException(err);
    });
};

export function getPharmacyById(pharmacyId: string): Promise<any> {
  return get(PHARMACY_URL.getById.replace('{id}', pharmacyId))
    .then((response: Promise<any>) => response || {})
    .catch(err => {
      throw RequestErrorException(err);
    });
}

export function updatePharmacyProfile(pharmacyId: string, data: any): Promise<any> {
  return patch(PHARMACY_URL.updateProfile.replace('{id}', pharmacyId), data)
    .then((response: Promise<any>) => response || {})
    .catch(err => {
      throw RequestErrorException(err);
    });
}

