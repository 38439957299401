import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';

export interface PharmaciesState {
  pharmacies: Array<any>,
  isLoadingPharmacies: boolean,
  pharmaciesError?: string | null
}

export interface PharmaciesStateStart {
  countyId :string
  cityId: string
}

export const initialState: PharmaciesState = {
  pharmacies: [],
  isLoadingPharmacies: false,
  pharmaciesError: null
};

const pharmaciesReducer = createSlice({
  'name': 'pharmacies',
  initialState,
  reducers: {
    getPharmaciesRequest(state): PharmaciesState {
      return {
        pharmacies: [],
        isLoadingPharmacies: true,
        pharmaciesError: null
      };
    },
    getPharmaciesSuccess(state, action:PayloadAction<Array<any>>): PharmaciesState {
      return {
        pharmacies: action.payload,
        isLoadingPharmacies: false,
        pharmaciesError: null
      };
    },
    getPharmaciesFailure(state, action) {
      return {
        pharmacies: [],
        isLoadingPharmacies: false,
        pharmaciesError: action.payload
      };
    }
  }
});

export const {
  getPharmaciesRequest,
  getPharmaciesSuccess,
  getPharmaciesFailure
} = pharmaciesReducer.actions;

export default pharmaciesReducer.reducer;

export const fetchPharmacies = createAction('FETCH_PHARMAS', (countyId, cityId) => {
  return { payload: {
    countyId,
    cityId
  } };
});
