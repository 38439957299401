import React, { createContext, useState } from "react";
import Cache from '../services/cache';
import {
    getPharmacies as getPharmaciesServiceRequest,
} from '../services/pharmaciesService';

export interface PharmaciesState {
    pharmacies: Array<any>,
    isLoadingPharmacies: boolean,
    pharmaciesError?: string | null,
    getPharmacies: (countyId, cityId) => void;
}

export interface PharmaciesStateStart {
    countyId: string
    cityId: string
}

export const initialState: PharmaciesState = {
    pharmacies: [],
    isLoadingPharmacies: false,
    pharmaciesError: null,
    getPharmacies: () => {}
};

export const PharmacyContext = createContext(initialState);

const PharmacyProvider = (props) => {
    const [pharmaciesState, setPharmaciesState] = useState(initialState);

    const getPharmacies = async (countyId, cityId) => {
        setPharmaciesState({
            ...initialState,
            pharmacies: [],
            isLoadingPharmacies: true,
            pharmaciesError: null
        });

        const cachedPharmacies = Cache.getsubKey('pharmacies', `${countyId}_${cityId}`);

        if (cachedPharmacies) {
            setPharmaciesState({
                ...initialState,
                pharmacies: cachedPharmacies,
                isLoadingPharmacies: false,
                pharmaciesError: null
            });
            return;
        }

        try {
            const pharmacies = await getPharmaciesServiceRequest(countyId, cityId);
            setPharmaciesState({
                ...initialState,
                pharmacies: pharmacies,
                isLoadingPharmacies: false,
                pharmaciesError: null
            });

            Cache.addsubKey('pharmacies', `${countyId}_${cityId}`, pharmacies);
        } catch (err) {
            setPharmaciesState({
                ...initialState,
                pharmacies: cachedPharmacies,
                isLoadingPharmacies: false,
                pharmaciesError: err.toString()
            });
        }
    }

    const state = Object.assign(pharmaciesState, { getPharmacies });
    return (
        <PharmacyContext.Provider value={{
            ...state
        }}>
            {props.children}
        </PharmacyContext.Provider>
    )
}

export default PharmacyProvider;
