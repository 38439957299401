import React, { createContext, useContext, useEffect, useState } from 'react';
import { displayLoader, clearLoader } from "../reducers/genericLoader";
import { RegionContext, RegionsState } from './RegionProvider';
import { useDispatch } from 'react-redux';
import { notifcationError } from '../reducers/notificationReducer';
import { withTranslation } from 'react-i18next';
import { getPatientById } from '../services/patientServices';
import { PharmacyContext } from './PharmacyProvider';

export interface PatientDataState {
  isLoading: boolean
  isError: boolean
  error: any
  profileData: any;
  regionProps?: RegionsState,
  countyId?: string;
  cityId?: string;
}

export const initialState: PatientDataState = {
  isLoading: false,
  isError: false,
  error: null,
  regionProps: {} as any,
  profileData: {
    cityId: '',
    pharmacyId: ''
  },
  cityId: '',
  countyId: ''
};

export const PatientnDataContext = createContext(initialState);

const PatientDataProvider = (props): any => {
  const [currentState, setCurrentState] = useState(initialState);
  const dispatch = useDispatch();
  const { patientId, t } = props;
  const regionContext = useContext(RegionContext);
  const pharmacyContext = useContext(PharmacyContext);

  useEffect(() => {
    const physicianData = async () => {
      if(!patientId) {
        return;
      }

      try {
        dispatch(displayLoader(t('loader.loadingInitialData')));

        setCurrentState({
          ...initialState,
          isLoading: true
        });

        const patientData = await getPatientById(patientId),
          {
            countyId,
            cityId
          } = patientData;

        if (!countyId || !cityId) {
          dispatch(notifcationError(t('loader.dataLoadError')));

          return;
        }

        await (regionContext as any).getCountiesAndCity(countyId, countyId, cityId);
        await pharmacyContext.getPharmacies(countyId, cityId);

        setCurrentState({
          isLoading: false,
          isError: false,
          error: undefined,
          profileData: patientData,
          regionProps: regionContext,
          countyId,
          cityId,
        });
      } catch (exc) {
        dispatch(notifcationError(t('loader.dataLoadError')));

        setCurrentState({
          ...initialState,
          error: exc,
          isError: true
        });
      } finally {
        dispatch(clearLoader());
      }
    }

    physicianData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId]);

  return (
    <PatientnDataContext.Provider value={{
      ...currentState
    }}>
      {props.children}
    </PatientnDataContext.Provider>
  )
}

export default withTranslation()(PatientDataProvider);
