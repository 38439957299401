import { redirectToLoginWithError } from '../helpers/redirect';
import { AUTHENTICATION } from '../urlPatterns';

const redirectOnAuthFail = (response) => {
  if (
    response
    && response.status === 401
    && response.config
    && response.config.url
    && (
      response.config.url.indexOf(AUTHENTICATION.login) === -1
      || (
        response.config.url.indexOf(AUTHENTICATION.login) > -1
        && response.config.method !== 'post'
        && response.config.method !== 'put'
      )
    )
  ) {
    redirectToLoginWithError();

    return true;
  }

  return false;
}

const requestErrorWrapper = async (request) => {
  return new Promise((resolve, reject) => {
    request
      .then(response => {
        if(redirectOnAuthFail(response)) {
          return;
        }

        return response.status >= 200 && response.status < 300 ? response : reject(response);
      })
      .then(response => {
        return response.data;
      })
      .then(response => {
        if (response.exceptions) {
          return reject(response.exceptions);
        }

        resolve(response)
      })
      .catch(err => {
        if(redirectOnAuthFail(err.response)) {
          return;
        }

        if (err.response) {
          return reject(err.response);
        }

        reject(err);
      });
  });
}

export default requestErrorWrapper;
