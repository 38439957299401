import React from 'react';
import ReactDOM from 'react-dom';
// import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux';
import './index.css';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import store from './store';
import { ThemeProvider } from '@material-ui/core';
import theme from './themes/main';
import RefreshTokenService from './services/refreshTokenService';
import { getToken } from './helpers/authSession';
require('./helpers/history');

if(getToken()) {
  RefreshTokenService.start();
}

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        {/* <ThemeProvider theme={theme}> */}
          <App />
        {/* </ThemeProvider> */}
      </Provider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: () => {
    console.log('SW updated!');
    window.location.reload();
  },
  onSuccess: () => {
    console.log('SW registration success');
  }
});
