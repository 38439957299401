import UserRole from './userRole';

let authSession: AUTH_INTERFACE | null = null;
const TOKEN_KEY = 'authUser';

const removeAuthSession = () => {
  authSession = null;

  localStorage.removeItem(TOKEN_KEY);
}

const setAuthSession = (session) => {
  authSession = session;

  if (!session) {
    removeAuthSession();
  }

  localStorage.setItem(TOKEN_KEY, JSON.stringify(session));
};

const getAuthSession = () => {
  return authSession;
}

const getToken = () => {
  return authSession && authSession.token;
}

const getUserRole = () => {
  const userRole = authSession && authSession.type;

  return new UserRole(userRole);
}

const getLoggedInUserId = () => {
  return authSession && authSession.id;
}

const initialLoadAuthSession = () => {
  let authSessionTmp = localStorage.getItem(TOKEN_KEY);

  try {
    if (authSessionTmp) {
      authSessionTmp = JSON.parse(authSessionTmp);
    }
  } catch (exc) {
    authSessionTmp = null;
  }

  authSessionTmp ? setAuthSession(authSessionTmp) : removeAuthSession();

  return authSessionTmp;
};

const isLoggedIn = (): boolean => {
  if (!authSession) {
    return false;
  }

  if (authSession !== null && !authSession.token) {
    return false;
  }

  return true;
}

export {
  setAuthSession,
  removeAuthSession,
  getAuthSession,
  initialLoadAuthSession,
  isLoggedIn,
  getLoggedInUserId,
  getToken,
  getUserRole,
};
