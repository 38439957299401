import React, { PropsWithChildren } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import FormFieldsRenderer, { FormField } from '../FormElements/FormFieldsRenderer';
import { PharmacySelectorProps } from '../FormElements/PharmacySelector';
import SaveIcon from '@material-ui/icons/Save';
import recipeFormStyles from './PrescriptionFormStyles';

import {
  Grid,
  Button,
  Paper,
} from '@material-ui/core';

import {
  makeValidate,
  makeRequired,
  Debug
} from 'mui-rff';
import { Form } from 'react-final-form';
import { FormSubscription } from 'final-form';
import { Alert } from '@material-ui/lab';
import { RegionSelectorProps } from '../FormElements/RegionSelector';

const renderDebug = (userDebug) => {
  return userDebug ? <Debug /> : null;
}

export interface RecipeFormPatientData {
  firstName: string;
  lastName: string;
  idNumber: string;
}

export interface RecipeFormProps extends PropsWithChildren<WithTranslation> {
  onSubmit: (formData: any) => void;
  onCancel?: () => void;
  isLoading?: boolean;
  useDebug?: boolean;
  t: any;
  pharmacyProps: PharmacySelectorProps;
  patientData: RecipeFormPatientData;
  initialValues?: any;
  createFormFields: (t, requiredFields, pharmacyProps, regionProps) => FormField[];
  validationSchema: any;
  submitButtonLabel: string;
  backButtonLabel: string;
  onCountyChange: (countyId: string) => void;
  onRegionChange?: (countyId: string, cityId: string) => void;
  regionProps: RegionSelectorProps;
}

const renderPatientData = (patientData, classes, t) =>
  (<Paper className={classes.paperPatient}>
    <Grid container spacing={1}>
      {t('recipeForm.patientLabel')} {patientData.firstName} {patientData.lastName}, {patientData.idNumber}
    </Grid>
  </Paper>)

const PrescriptionForm = (props: RecipeFormProps) => {
  const classes = recipeFormStyles(),
    {
      onSubmit,
      onCancel,
      t,
      useDebug,
      pharmacyProps,
      validationSchema,
      submitButtonLabel,
      backButtonLabel,
      createFormFields,
      initialValues,
      patientData,
      onCountyChange,
      onRegionChange,
      regionProps,
    } = props,
    validate = makeValidate(validationSchema),
    required = makeRequired(validationSchema),
    regionPropClone = { ...regionProps };

  const subscription: FormSubscription = {
    submitting: true,
    submitError: true,
    submitFailed: true,
    submitSucceeded: true
  };

  const setCustomRegionHandlers = (form) => {
    if (regionPropClone) {
      if (onRegionChange) {
        const customRegionChangeHandler = (countyId, cityId) => {
          if (form && form.change) {
            form.change('pharmacyId', '');
          }

          onRegionChange(countyId, cityId);
        }

        regionPropClone.onRegionChange = customRegionChangeHandler;
      }

      const customCountyHandler = (countyId) => {
        if (form && form.change) {
          form.change('cityId', '');
          form.change('pharmacyId', '');
        }

        onCountyChange(countyId);
      }

      regionPropClone.onCountyChange = customCountyHandler;
    }
  };

  const doRenderForm = (formProps) => {
    const {
        form,
        handleSubmit,
        submitError,
      } = formProps;

    setCustomRegionHandlers(form);

    const fields = createFormFields(t, required, pharmacyProps, regionPropClone);
    return (
      <Grid item xs={12}>
        {submitError && (
          <Alert severity={'error'} variant='outlined'>
            {submitError}
          </Alert>
        )}

        {renderPatientData(patientData, classes, t)}

        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <Grid container spacing={1}>
            <FormFieldsRenderer
              fields={fields}
              initialValues={initialValues}
            />
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <Button
                type="submit"
                fullWidth
                variant="outlined"
                color="primary"
                className={classes.submit}
                startIcon={<SaveIcon />}
              >
                {submitButtonLabel}
              </Button>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                variant="outlined"
                className={classes.submit}
                onClick={onCancel}
              >
                {backButtonLabel}
              </Button>
            </Grid>
          </Grid>
          {renderDebug(useDebug)}
        </form>
      </Grid>);
  }

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      subscription={subscription}
      key={subscription as any}
      initialValues={initialValues || {}}
      validateOnBlur={false}
      render={formProps => doRenderForm(formProps)}
    />
  );
}

export default withTranslation()(PrescriptionForm);
