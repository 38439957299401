import React, { PropsWithChildren } from 'react';
import {
  Typography,
  Button,
  makeStyles,
  Card,
  CardContent,
  CardActions
} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import PhoneIcon from '@material-ui/icons/Phone';
import Fingerprint from '@material-ui/icons/Fingerprint';

import { withTranslation, WithTranslation } from 'react-i18next';

export interface PatientListDetailsProps extends PropsWithChildren<WithTranslation> {
  classes: any,
  rowData: Patient,
  onAddRecipe: (patientData: Patient) => void;
  onEditPatient: (patientData: Patient) => void;
  onViewRecipies: (patientData: Patient) => void;
}

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const PatientListDetails = (props: PatientListDetailsProps) => {
  const { classes, rowData, onAddRecipe, onEditPatient, onViewRecipies, t } = props,
    cardClasses = useStyles();

  return (
    <Card className={cardClasses.root}>
      <CardContent>
        <Typography variant={'subtitle2'} className={classes.detailsTypography}>
          <Fingerprint />
          <span className={classes.detailsLabel}>
            {t('patientList.idNumber')}
          </span>
          <span className={classes.detailsData}>
            {rowData.idNumber}
          </span>
        </Typography>

        <Typography variant={'subtitle2'} className={classes.detailsTypography}>
          <HomeIcon />
          <span className={classes.detailsLabel}>
            {t('patientList.address')}
          </span>
          <span className={classes.detailsData}>
            {rowData.address}
          </span>
        </Typography>

        <Typography variant={'subtitle2'} className={classes.detailsTypography}>
          <PhoneIcon />
          <span className={classes.detailsLabel}>
            {t('patientList.phone')}
          </span>
          <span className={classes.detailsData}>
            {rowData.phone}
          </span>
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" variant="contained" color="primary" onClick={onAddRecipe.bind(null, rowData)}>
          {t('patientList.addRecipe')}
        </Button>
        <Button size="small" variant="contained" color="primary" onClick={onEditPatient.bind(null, rowData)}>
          {t('patientList.editPatient')}
        </Button>
        <Button size="small" variant="contained" color="primary" onClick={onViewRecipies.bind(null, rowData)}>
          {t('patientList.viewRecipies')}
        </Button>
      </CardActions>
    </Card>
  );
  // return (
  //   <Paper elevation={2} className={classes.details}>
  //     <Typography variant={'subtitle2'} className={classes.detailsTypography}>
  //       <HomeIcon />
  //       <span className={classes.detailsLabel}>
  //         {t('patientList.address')}
  //       </span>
  //       <span className={classes.detailsData}>
  //         {rowData.address}
  //       </span>
  //     </Typography>

  //     <Typography variant={'subtitle2'} className={classes.detailsTypography}>
  //       <PhoneIcon />
  //       <span className={classes.detailsLabel}>
  //         {t('patientList.phone')}
  //       </span>
  //       <span className={classes.detailsData}>
  //         {rowData.phone}
  //       </span>
  //     </Typography>

  //     <Typography className={classes.actionContainer}>
  //       <Button size="small" variant="contained" color="primary" onClick={onAddRecipe.bind(null, rowData)}>
  //         {t('patientList.addRecipe')}
  //       </Button>
  //       <Button size="small" variant="contained" color="primary" onClick={onEditPatient.bind(null, rowData)}>
  //         {t('patientList.editPatient')}
  //       </Button>
  //       <Button size="small" variant="contained" color="primary" onClick={onViewRecipies.bind(null, rowData)}>
  //         {t('patientList.viewRecipies')}
  //       </Button>
  //     </Typography>
  //   </Paper>
  // );
};

export default withTranslation()(PatientListDetails);
