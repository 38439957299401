import React from 'react';
import {
  Grid,
} from '@material-ui/core';
import RegionSelector, { RegionSelectorProps } from './RegionSelector';
import PharmacySelector, { PharmacySelectorProps } from './PharmacySelector';
import TextField from './TextField';
import PhotoUpload from './PhotoUpload';
import PasswordField from './PasswordField';
import TermsAndConditions from './TermsAndConditions';

export const TYPE_TEXT = 'Text';
export const TYPE_TEXTAREA = 'Text';
export const TYPE_REGION = 'Region';
export const TYPE_PHARMACY = 'Pharmacy';
export const TYPE_TERMS = 'Terms';

export type FIELD_TYPE =
  | typeof TYPE_TEXT
  | typeof TYPE_REGION
  | typeof TYPE_PHARMACY
  | typeof TYPE_TERMS;

export interface FormField {
  name: string;
  label: string;
  prefix: string;
  size?: 'small' | 'medium' | undefined;
  subType?: string;
  isRequired?: boolean;
  isFullWidth?: boolean;
  isDisabled?: boolean;
  regionProps?: RegionSelectorProps;
  pharmacyProps?: PharmacySelectorProps;
  fieldType?: FIELD_TYPE;
  onInputChange?: (event: object) => void;
  withNoStrengthMetter?: false,
  photoField?: any;
  disabled?: boolean;
}

export interface FormFieldsRenderer {
  fields: FormField[];
  initialValues?: any;
}

const renderTextField = (field, initialValues) => {
  const {
    isRequired,
    isDisabled,
    isFullWidth,
    subType,
    name,
    label,
    prefix,
    size,
  } = field;

  switch (field.subType) {
    case 'photo':
      return (
        <PhotoUpload
          required={isRequired}
          fullWidth={isFullWidth}
          name={name}
          label={label}
          id={name}
          autoComplete={`${prefix}.${name}`}
          size={size || 'small'}
          key={`textfield_${name}_${label}`}
          type={(subType || 'text') as any}
          disabled={isDisabled}
          onInputChange={field.onInputChange}
          file={initialValues ? initialValues[field.photoField] : null}
        />);
    case 'password':
      return (
        <PasswordField
          isRequired={isRequired}
          fullWidth={isFullWidth}
          name={name}
          label={label}
          id={name}
          autoComplete={`${prefix}.${name}`}
          size={size || 'small'}
          key={`textfield_${name}_${label}`}
          type={(subType || 'text') as any}
          disabled={isDisabled}
          onInputChange={field.onInputChange}
          withNoStrengthMetter={field.withNoStrengthMetter}
        />);
    case 'textarea':
      return (<TextField
          required={isRequired}
          fullWidth={isFullWidth}
          name={name}
          label={label}
          id={name}
          autoComplete={`${prefix}.${name}`}
          size={size || 'small'}
          key={`textfield_${name}_${label}`}
          type={(subType || 'text') as any}
          disabled={isDisabled}
          onInputChange={field.onInputChange}
          multiline={true}
          rows={4}
        />);
    default:
      return (
        <TextField
          required={isRequired}
          fullWidth={isFullWidth}
          name={name}
          label={label}
          id={name}
          autoComplete={`${prefix}.${name}`}
          size={size || 'small'}
          key={`textfield_${name}_${label}`}
          type={(subType || 'text') as any}
          disabled={isDisabled}
          onInputChange={field.onInputChange}
        />);
  };
};

const renderField = (field, initialValues) => {
  const {
    isRequired,
    isDisabled,
    isFullWidth,
    name,
    label,
    regionProps,
    pharmacyProps,
  } = field;

  if (!field.fieldType) {
    field.fieldType = 'Text';
  }

  switch (field.fieldType) {
    case 'Text':
      return (
        <Grid item xs={12} key={`grid_${name}`}>
          {renderTextField(field, initialValues)}
        </Grid>
      );
    case 'Region':
      return (
        <Grid item xs={12} key={`grid_${name}`}>
          <RegionSelector
            {...(regionProps as any)}
            isDisabled={isDisabled}
            isRequired={isRequired}
            isFullWidth={isFullWidth}
            key={`RegionSelector_${name}`}
          />
        </Grid>
      );
    case 'Pharmacy':
      return (
        <Grid item xs={12} key={`grid_${name}`}>
          <PharmacySelector
            {...(pharmacyProps as any)}
            value={initialValues[name] || ''}
            disabled={isDisabled}
            isRequired={isRequired}
            isFullWidth={isFullWidth}
            key={`RegionSelector_${name}`}
            label={label}
            name={name}
          />
        </Grid>
      );
    case 'Terms':
      return (
        <Grid item xs={12} key={`grid_${name}`}>
          <TermsAndConditions
            isRequired={isRequired}
            key={`TermsAndConditions_${name}`}
          />
        </Grid>
      );
    default:
      return null;
  }
}

export const FormFieldsRenderer = (props: FormFieldsRenderer) => {
  const { fields, initialValues } = props;

  return (
    <Grid container spacing={1}>
      {(fields || []).map(field => renderField(field, initialValues || {}))}
    </Grid>
  );
};

export default FormFieldsRenderer;
