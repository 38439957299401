import { makeStyles, createStyles } from '@material-ui/core/styles';

const changeOwnPassword = makeStyles((theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  })
);

export default changeOwnPassword;
