import React, { PropsWithChildren } from 'react';
import registrationFormStyles from './RegistrationFormStyles';
import { withTranslation, WithTranslation } from 'react-i18next';
import FormFieldsRenderer, { FormField } from '../FormElements/FormFieldsRenderer';
import { RegionSelectorProps } from '../FormElements/RegionSelector';
import SaveIcon from '@material-ui/icons/Save';

import {
  Grid,
  Button
} from '@material-ui/core';

import {
  makeValidate,
  makeRequired,
  Debug
} from 'mui-rff';
import { Form } from 'react-final-form';
import { FormSubscription } from 'final-form';
import { PharmacySelectorProps } from '../FormElements/PharmacySelector';
import { Alert } from '@material-ui/lab';

const renderDebug = (userDebug) => {
  return <Debug></Debug>
  // return userDebug ? <Debug /> : null;
}

export interface RegistrationFormProps extends PropsWithChildren<WithTranslation> {
  onSubmit: (formData: any) => Promise<any>;
  onCancel?: () => void;
  onCountyChange: (countyId: string) => void;
  onRegionChange?: (countyId: string, cityId: string) => void;
  isLoading?: boolean;
  useDebug?: boolean;
  t: any;
  initialValues: any;
  invitationData?: any;
  selectedTempCounty?: string;
  regionProps: RegionSelectorProps;
  pharmacyProps?: PharmacySelectorProps;
  createFormFields: (t, requiredFields, regionProps, pharmacyProps) => FormField[];
  validationSchema: any;
  submitButtonLabel: string;
}

const RegistrationForm = (props: RegistrationFormProps) => {
  const classes = registrationFormStyles(),
    {
      onSubmit,
      onCancel,
      t,
      useDebug,
      invitationData,
      regionProps,
      pharmacyProps,
      onCountyChange,
      onRegionChange,
      validationSchema,
      submitButtonLabel,
      createFormFields,
    } = props,
    validate = makeValidate(validationSchema),
    required = makeRequired(validationSchema);

  let initialValues = props.initialValues;
  if(!initialValues && invitationData) {
    initialValues = {
      email: invitationData.email
    }
  }

  const subscription: FormSubscription = {
    submitting: true,
    submitError: true,
    submitFailed: true,
    submitSucceeded: true
  },
    regionPropClone = { ...regionProps };



  const renderBackButton = () => {
    if (!onCancel) {
      return null;
    }

    return (
      <Grid item xs={12} sm={6}>
        <Button
          fullWidth
          variant="outlined"
          className={classes.submit}
          onClick={onCancel}
        >
          {t('patientProfile.backLabel')}
        </Button>
      </Grid>
    );
  }

  const doRenderForm = (formProps, initialValues) => {
    const {
      form,
      handleSubmit,
      submitError,
    } = formProps;

    if (regionPropClone) {
      if (onRegionChange) {
        const customRegionChangeHandler = (countyId, cityId) => {
          if (form && form.change) {
            form.change('pharmacyId', '');
          }

          onRegionChange(countyId, cityId);
        }

        regionPropClone.onRegionChange = customRegionChangeHandler;
      }

      const customCountyHandler = (countyId) => {
        if (form && form.change) {
          form.change('cityId', '');
          form.change('pharmacyId', '');
        }

        onCountyChange(countyId);
      }

      regionPropClone.onCountyChange = customCountyHandler;
    }

    const fields = createFormFields(t, required, regionPropClone, pharmacyProps);
    return (
      <Grid item xs={12}>
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          {submitError && (
            <Grid container spacing={1}>
              <Grid item xs>
                <Alert severity={'error'} variant='outlined'>
                  {submitError}
                </Alert>
              </Grid>
            </Grid>
          )}
          <Grid container spacing={1}>
            <FormFieldsRenderer
              fields={fields}
              initialValues={initialValues}
            />
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <Button
                type="submit"
                fullWidth
                variant="outlined"
                color="primary"
                className={classes.submit}
                startIcon={<SaveIcon />}
              >
                {submitButtonLabel}
              </Button>
            </Grid>

            {renderBackButton()}
          </Grid>
          {renderDebug(useDebug)}
        </form>
      </Grid>);
  }
  return (
    <Form<any>
      onSubmit={onSubmit}
      validate={validate}
      subscription={subscription}
      key={subscription as any}
      initialValues={initialValues || {}}
      validateOnBlur={false}
      render={formProps => doRenderForm(formProps, initialValues)} />
  );
}

export default withTranslation()(RegistrationForm);
