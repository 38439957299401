import ChangeOwnPassword from "../../containers/ChangeOwnPassword";
import EditPhysicianPage from '../../features/EditPhysicianProfile';
import DashboardHome from "./DashboardHome";
import AddPrescription from "../../containers/AddPrescription";
import PrescriptionList from "./PrescriptionList";
import PatientList from './PatientsList';
import PatientPrescriptions from './PatientPrescriptions';
import { redirect } from "../../helpers/redirect";
import PermIdentity from '@material-ui/icons/PermIdentity';
import Lock from '@material-ui/icons/Lock';
import Home from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import AssignmentIcon from '@material-ui/icons/Assignment';

export const DOCTOR_ROUTES = {
  changePassword: '/doctor/profile/changePassword',
  profile: '/doctor/profile',
  editPatient: '/doctor/patient/:patientId',
  home: '/doctor',
  patientList: '/doctor/patients',
  patientRecipeList: '/doctor/patients/:patientId',
  recipeList: '/doctor/prescriptions',
  addPrescription: '/doctor/prescriptions/add/:patientId',
  patientRecipeListFromPrescriptions: '/doctor/prescriptions/:patientId',
};

export const BREADCRUMB_ICONS = {
  [DOCTOR_ROUTES.changePassword]: Lock,
  [DOCTOR_ROUTES.profile]: PermIdentity,
  [DOCTOR_ROUTES.home]: Home,
  [DOCTOR_ROUTES.addPrescription]: AssignmentIcon,
  [DOCTOR_ROUTES.patientList]: PeopleIcon,
  [DOCTOR_ROUTES.recipeList]: AssignmentIcon,
  [DOCTOR_ROUTES.patientRecipeList]: AssignmentIcon,
  [DOCTOR_ROUTES.patientRecipeListFromPrescriptions]: AssignmentIcon,
};

export const redirectToPatientRecipies = (patientId) => {
  redirect(DOCTOR_ROUTES.patientRecipeList.replace(':patientId', patientId))
};

export const redirectToPatientRecipiesFromPrescriptions = (patientId) => {
  redirect(DOCTOR_ROUTES.patientRecipeListFromPrescriptions.replace(':patientId', patientId))
};

const routes = [
  {
    path: DOCTOR_ROUTES.changePassword,
    exact: true,
    page: ChangeOwnPassword,
    breadcrumb: 'doctorDashboard.changePassword',
  },
  {
    path: DOCTOR_ROUTES.profile,
    exact: true,
    page: EditPhysicianPage,
    breadcrumb: 'doctorDashboard.profile',
  },
  {
    path: DOCTOR_ROUTES.home,
    exact: true,
    page: DashboardHome,
    breadcrumb: 'doctorDashboard.mainPage',
  },
  {
    path: DOCTOR_ROUTES.addPrescription,
    exact: true,
    page: AddPrescription,
    breadcrumb: 'doctorDashboard.addPrescription',
  },
  {
    path: DOCTOR_ROUTES.patientList,
    exact: true,
    page: PatientList,
    breadcrumb: 'doctorDashboard.patientList',
  },
  {
    path: DOCTOR_ROUTES.recipeList,
    exact: true,
    page: PrescriptionList,
    breadcrumb: 'doctorDashboard.prescriptionList',
  },
  {
    path: DOCTOR_ROUTES.patientRecipeList,
    exact: true,
    page: PatientPrescriptions,
    breadcrumb: 'doctorDashboard.patientRecipeList',
  },
  {
    path: DOCTOR_ROUTES.patientRecipeListFromPrescriptions,
    exact: true,
    page: PatientPrescriptions,
    breadcrumb: 'doctorDashboard.patientRecipeList',
  },
];

export default routes;
