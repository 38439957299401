import * as Yup from 'yup';
require('../../genericValidators/password');
require('../../genericValidators/passwordMatcher');

export default function ChangePasswordFormValidations(t) {
  return Yup.object().shape({
    email: Yup.string()
      .email(t('recoverPassword.validations.email'))
      .required(t('recoverPassword.validations.emptyEmail')),
  });
}
