import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getAuthSession } from '../helpers/authSession';
import { GLOBAL_ROUTES } from '../routes';
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary';
import RegionProvider from "../providers/RegionProvider";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    getAuthSession()
      ? <ErrorBoundary><RegionProvider><Component {...props} /></RegionProvider></ErrorBoundary>
      : <Redirect to={{ pathname: GLOBAL_ROUTES.login, state: { from: props.location } }} />
  )} />
);

export default PrivateRoute;
