import { makeStyles, createStyles } from "@material-ui/core";

const addRecipe = makeStyles((theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }
  }));

  export default addRecipe;
