import ChangeOwnPassword from "../../containers/ChangeOwnPassword";
import EditPharmacyProfileContainer from "../../containers/standalone/EditPharmacyProfileContainer";
import DashboardHome from "./DashboardHome";
import PrescriptionList from "./PrescriptionList";

export const PHARMACY_ROUTES = {
  changePassword: '/pharmacy/profile/changePassword',
  profile: '/pharmacy/profile/edit',
  home: '/pharmacy',
  recipeList: '/pharmacy/recipies/:patientId',
}

const routes = [
  {
    path: PHARMACY_ROUTES.changePassword,
    exact: true,
    page: ChangeOwnPassword
  },
  {
    path: PHARMACY_ROUTES.profile,
    exact: true,
    page: EditPharmacyProfileContainer
  },
  {
    path: PHARMACY_ROUTES.home,
    exact: true,
    page: DashboardHome
  },
  {
    path: PHARMACY_ROUTES.recipeList,
    exact: true,
    page: PrescriptionList
  },
];

export default routes;
