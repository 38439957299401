import * as Yup from 'yup';
require('../../genericValidators/password');

export default function PatientProfileFormValidation(t) {
  // We define our schema based on the same keys as our form:
  return Yup.object().shape({
    firstName: Yup.string().required(t('common.registrationFormValidations.firstName')),
    lastName: Yup.string().required(t('common.registrationFormValidations.lastName')),
    email: Yup.string().email(t('common.registrationFormValidations.email'))
      .required(t('common.registrationFormValidations.emailEmpty')),
    password: (Yup.string().required(t('login.validations.password')) as any)
      .isValidPassword(t('passwordField.validation')),
    code: Yup.string().required(t('common.registrationFormValidations.code')),
    address: Yup.string().required(t('common.registrationFormValidations.cabinetAddress')),
    countyId: Yup.string().required(t('common.registrationFormValidations.county')),
    cityId: Yup.string().required(t('common.registrationFormValidations.city')).nullable(),
    phone: Yup.string().matches(/^[0-9]{10}$/, {
      message: t('common.registrationFormValidations.phoneInvalid')
    }).required(t('common.registrationFormValidations.phone')),
    activationCode: Yup.string().required(t('common.registrationFormValidations.activationCode')),
    termsAndConditions: Yup.boolean()
      .oneOf([true], t('common.registrationFormValidations.termsRequired'))
      .required(t('common.registrationFormValidations.termsRequired'))
  });
};
