import React from 'react';
import { withTranslation } from 'react-i18next';
import PrescriptionListComponent from '../../components/PrescriptionsList/PrescriptionList';
import EditPrescriptionModalContainer from '../../containers/modals/EditPrescriptionModalContainer';
import DeletePrescriptionModal from '../../containers/DeletePrescriptionModal';
import AddPrescriptionModalContainer from '../../containers/modals/AddPrescriptionModalContainer';
import { redirectToPatientRecipiesFromPrescriptions } from './routes';
import ChoosePatient from '../../containers/ChoosePatientContainer';
import PrintPrescription from '../../containers/PrintPrescription';

import {
  Grid,
  makeStyles,
  createStyles,
  Card,
  CardContent,
  Divider
} from '@material-ui/core';

const useStyles = makeStyles(
  createStyles({
    patientSelector: {
      display: 'flex',
      justifyItems: 'center'
    },
    card: {},
  }),
);

const PrescriptionList = (props) => {
  const { hidePatientSelector, pageSize } = props,
    actions: string[] = [
      'edit',
      'delete',
      'print'
    ],
    classes = useStyles();

  const handlePatientSelect = (patient) => {
    if (patient) {
      redirectToPatientRecipiesFromPrescriptions(patient.id);
    }
  }

  const renderPatientSelector = () => (
    <React.Fragment>
      <Card className={classes.card}>
        <CardContent>
          <Grid container spacing={1}>
            <Grid xs={12} className={classes.patientSelector}>
              <ChoosePatient onSelect={handlePatientSelect} style={{width: '300px'}}/>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      
      <Divider />
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {!hidePatientSelector ? renderPatientSelector() : null}
      <PrescriptionListComponent
        pageSize={pageSize || 10}
        editPrescriptionDialog={EditPrescriptionModalContainer}
        deletePrescriptionDialog={DeletePrescriptionModal}
        printPrescriptionDialog={PrintPrescription}
        AddReceipeModal={AddPrescriptionModalContainer}
        actions={actions}
      />
    </React.Fragment>
  );
};

export default withTranslation()(PrescriptionList);
