import * as Yup from 'yup';

export default Yup.addMethod(Yup.string, 'isValidPassword', function(message: string) {
  return this.test('passwordValidTest', message, (value, context): any => {
    const { path, createError } = context,
      matcher = /(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-zd@$!%*#?&\d]{7,}$/s;

    if(!value) {
      return true;
    }

    return value.match(matcher) || createError({
      path,
      message
    })
  });
});
