import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import createRootReducer from './reducers';
import createSagaMiddleware from 'redux-saga';
import Saga from './sagas/index';
import { enableES5 } from 'immer';
enableES5();

const sagaMiddleware = createSagaMiddleware(),
  rootReducer = createRootReducer();

export type RootState = ReturnType<typeof rootReducer>;

function configureStore(preloadedState = {}) {
  const store = createStore(
    rootReducer, // root reducer with router state
    applyMiddleware(
      thunkMiddleware,
      sagaMiddleware
    ),
  )

  sagaMiddleware.run(Saga);
  return store;
}

const store = configureStore();

export default store;

export type AppDispatch = typeof store.dispatch;
