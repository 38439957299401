import Login from './features/Login';
import NoMatch from './features/NoMatch';
import Main from './features/Main';
import DoctorDashboard from './dashboards/doctor/DoctorDashboard';
import RecoverPassword from './features/RecoverPassword';
import PatientDashboard from './dashboards/patient/PatientDashboard';
import PharmacyDashboard from './dashboards/pharmacy/PharmacyDashboard';
import RegisterWithInvitation from './features/RegisterWithInvitation';
import RegistrationComplete from './features/RegistrationComplete';

export const GLOBAL_ROUTES = {
  login: '/login',
  register: '/invitation/:invitationId',
  forgotPassword: '/forgotPassword',
  doctorDashboard: '/doctor',
  patientDashboard: '/patient',
  pharmacyDashboard: '/pharmacy',
  registerComplete: '/registrationComplete',
  main: '',
}

export default [
  {
    path: GLOBAL_ROUTES.login,
    exact: false,
    isPublic: true,
    page: Login
  },
  {
    path: GLOBAL_ROUTES.register,
    exact: false,
    isPublic: true,
    page: RegisterWithInvitation
  },
  {
    path: GLOBAL_ROUTES.registerComplete,
    exact: false,
    isPublic: true,
    page: RegistrationComplete
  },
  {
    path: GLOBAL_ROUTES.forgotPassword,
    exact: false,
    isPublic: true,
    page: RecoverPassword
  },
  {
    path: GLOBAL_ROUTES.doctorDashboard,
    exact: false,
    page: DoctorDashboard
  },
  {
    path: GLOBAL_ROUTES.patientDashboard,
    exact: false,
    page: PatientDashboard
  },
  {
    path: GLOBAL_ROUTES.pharmacyDashboard,
    exact: false,
    page: PharmacyDashboard
  },
  {
    path: '/',
    exact: true,
    page: Main
  },
  {
    path: '*',
    page: NoMatch
  }
];
