import React from 'react';
import { connect } from 'react-redux';
import {
  Container, Grid, Link, Avatar, Typography
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LoginForm from '../components/LoginForm/LoginForm';
import { authorize } from '../reducers/authenticationReducer';
import { withTranslation } from 'react-i18next';
import loginStyles from '../styles/login';
import PublicAppHeader from '../components/PublicAppHeader/PublicAppHeader';

const Login = props => {
  const { t } = props,
    classes = loginStyles();

  return (
    <Container component="div" maxWidth='xs'>
      <PublicAppHeader />
      <Container component="main" className={classes.paper}>
        <Typography component="h1" variant="h5" className={classes.title}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon color='primary' />
          </Avatar>
          {t('login.signInTitle')}
        </Typography>
        <Grid container className={classes.formContainer}>
          <LoginForm onSubmit={props.handleLogin} {...props}>
            <Grid container>
              <Grid item xs>
                <Link href="/forgotPassword" variant="body2">
                  {t('login.forgotPassword')}
                </Link>
              </Grid>
            </Grid>
          </LoginForm>
        </Grid>
      </Container>
    </Container>
  )
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  error: state.auth.error,
  isSubmitting: state.auth.isSubmitting
});

const mapDispatchToProps = dispatch => ({
  handleLogin: (loginData) => {
    const { username, password } = loginData;

    dispatch(authorize(username, password));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Login));
