import { makeStyles } from '@material-ui/core/styles';

const requestErrorHeader = makeStyles((theme) => ({
  paragraph: {
    marginTop: 0,
    marginBottom: 0
  }
}));

export default requestErrorHeader;
