import { useState } from 'react';
import React from 'react';

const useEditPrescription = (selectedPrescriptionData, dialog, tableRef) => {
  const [isPrescriptionEditDialogOpen, setIsPrescriptionEditDialogOpen] = useState(false);

  const togglePrescriptionDialog = () => {
    setIsPrescriptionEditDialogOpen(!isPrescriptionEditDialogOpen);
  };

  const onCloseModal = (needsReload?: boolean) => {
    togglePrescriptionDialog();

    if(needsReload && typeof needsReload === 'boolean') {
      tableRef.current && tableRef.current.onQueryChange()
    }
  };

  const renderModal = () => {
    if (!isPrescriptionEditDialogOpen) {
      return null;
    }

    const DialogComponent = dialog;
    return (
      <DialogComponent
        onClose={onCloseModal}
        isOpen={true}
        prescriptionData={selectedPrescriptionData}
      />
    );
  }
  return {
    isPrescriptionEditDialogOpen,
    togglePrescriptionDialog,
    editPrescriptionModal: renderModal()
  };
}

export default useEditPrescription;
