import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  registerPhysician,
} from '../services/physicianServices';
import { notifcationError, notificationSuccess } from '../reducers/notificationReducer';
import registerPhysicianForm from '../forms/registerPhysician/registerPhysician';
import registerPhysicianValidations from '../forms/registerPhysician/registerPhysicianValidations';
import RegistrationForm from '../components/RegistrationForm/RegistrationForm';
import PublicFormWrapper from '../components/PublicFormWrapper/PublicFormWrapper';
import AccountCircle from '@material-ui/icons/AccountCircle';
import RequestErrorHeader from '../components/FormElements/RequestErrorHeader';
import { FORM_ERROR } from 'final-form';
import { redirectToRegistrationComplete } from '../helpers/redirect';
import useRegions from '../hooks/useRegions';
import { RegionContext } from '../providers/RegionProvider';

const RegisterPhysician = props => {
  const dispatch = useDispatch(),
    { t } = props,
    { handleCountyChange, handleRegionChange } = useRegions(),
    regionContext = useContext(RegionContext);

  const handleSubmit = async (formData) => {
    formData.pharmacyId = formData.pharmacy && formData.pharmacy.id;
    delete formData.pharmacy;

    try {
      await registerPhysician(formData);
      redirectToRegistrationComplete();

      setTimeout(() => {
        dispatch(notificationSuccess(t('pharmacyProfile.registrationComplete')));
      }, 0);
    } catch (err) {
      dispatch(notifcationError(err.requestError.message));

      return {
        [FORM_ERROR]: err.requestError.message
      };
    };
  };

  return (
    <PublicFormWrapper
      icons={AccountCircle}
      title={t('doctorProfile.createProfile')}
    >
      <RequestErrorHeader
        severity='error'
        requestError={regionContext.countyError as any}
      />
      <RequestErrorHeader
        severity='error'
        requestError={regionContext.cityError as any}
      />

      <RegistrationForm
        {...props}
        regionProps={{...regionContext}}
        onSubmit={handleSubmit}
        pharmacyProps={props.pharmacyProps}
        onCountyChange={handleCountyChange}
        onRegionChange={handleRegionChange}
        validationSchema={registerPhysicianValidations(t)}
        createFormFields={registerPhysicianForm}
        submitButtonLabel={t('doctorProfile.registerButtonLabel')}
        useDebug={true}
      />
    </PublicFormWrapper>
  )
};

export default withTranslation()(RegisterPhysician);
