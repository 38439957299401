import React from "react";
import { withTranslation } from "react-i18next";
import { Alert } from "@material-ui/lab";
import { Button, Grid } from "@material-ui/core";
import { redirectHome } from "../../helpers/redirect";

const ErrorBoundaryNotice = (props) => (
  <React.Fragment>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Alert severity={'error'} variant='outlined'>
          {props.t('errors.generalRouteError')}
        </Alert>
      </Grid>

      <Grid item xs={12}>
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          onClick={redirectHome}
        >
          {props.t('common.redirectToHomepage')}
        </Button>
      </Grid>
    </Grid>
  </React.Fragment>
);

export default withTranslation()(ErrorBoundaryNotice);
