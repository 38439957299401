import { useState, useEffect } from "react";
import store from "../store";
import { displayLoader, clearLoader } from "../reducers/genericLoader";
import { getPharmacies } from "../services/pharmaciesService";
import { getPharmaciesSuccess } from "../reducers/pharmaciesReducer";
import { notifcationError } from "../reducers/notificationReducer";
import { getPatientById } from "../services/patientServices";

const usePrintPrescription = (patientData, patientId, pharmacyId, t): editDataInterface => {
  const [initialValues, setInitialValues] = useState({
    isLoading: false,
    isError: false,
    error: undefined
  }),
    [profileData, setProfileData] = useState(patientData),
    [pharmacy, setPharmacy] = useState({}),
    { dispatch } = store;

  const loadInitialData = async () => {

    if (profileData) {
      setInitialValues({
        isLoading: false,
        isError: false,
        error: undefined
      });

      return;
    }

    try {
      if (!profileData && !patientId) {
        setInitialValues({
          isError: true,
          error: t('errors.errPatiantLoad'),
          isLoading: false
        });

        return;
      }

      dispatch(displayLoader(t('loader.loadingInitialData')));
      setInitialValues({
        isLoading: true,
        isError: false,
        error: undefined
      });

      const patient = await getPatientById(patientId);
      //   { countyId, cityId } = patient,
      //   pharmacies = await getPharmacyById(pharmacyId);

      // dispatch(getPharmaciesSuccess(pharmacies));

      setProfileData(patient);

      setInitialValues({
        isLoading: false,
        isError: false,
        error: undefined
      });
    } catch (exc) {
      dispatch(notifcationError(t('loader.dataLoadError')));

      setInitialValues({
        isError: true,
        error: exc,
        isLoading: false
      });
    } finally {
      dispatch(clearLoader());
    }
  }

  useEffect(() => {
    loadInitialData();
  }, []);

  return {
    initialValues,
    profileData
  };
}

export default usePrintPrescription;
