import React from 'react';
import {
  Container,
  CssBaseline,
  Typography
} from '@material-ui/core';
import PrescriptionForm from '../components/PrescriptionForm/PrescriptionForm';
import { connect, useDispatch } from 'react-redux';
import {
  useParams
} from "react-router-dom";
import { withTranslation } from 'react-i18next';
import {
  useLocation
} from "react-router-dom";
import { getQueryStringParams } from '../helpers/utilities';
import { redirect, redirectToDashboard, redirectHome } from '../helpers/redirect';
import addRecipeStyles from '../styles/addPrescription';
import useAddRecipe from '../hooks/useAddPrescription';
import ComponentPlaceholder from '../hocs/ComponentPlaceholder';
import RequestErrorHeader from '../components/FormElements/RequestErrorHeader';
import adddRecipeForm from '../forms/addPrescription/addPrescription';
import addRecipeValidations from '../forms/addPrescription/addPrescriptionValidations';
import { fetchCitiesByCountyId } from '../reducers/regionsReducer';
import { fetchPharmacies } from '../reducers/pharmaciesReducer';

const handleSubmit = () => { };

const AddPrescription = props => {
  const { patientId } = useParams(),
    { t, regionProps, pharmacyProps } = props,
    classes = addRecipeStyles(),
    location = useLocation(),
    initialValues = useAddRecipe(patientId, t),
    dispatch = useDispatch();

  const handleCancel = () => {
    const searchParams = getQueryStringParams(location.search),
      hasValues = Object.keys(searchParams).length > 0 && searchParams.returnUrl;

    hasValues ? redirect(decodeURIComponent(searchParams.returnUrl)) : redirectToDashboard();
  }

  const handleCountyChange = async (countyId) => {
    dispatch(fetchCitiesByCountyId(countyId));
  }
  
  const handleRegionChange = async (countyId, cityId) => {
    dispatch(fetchPharmacies(countyId, cityId));
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          {t('addRecipe.title')}
        </Typography>

        <ComponentPlaceholder
          isComponentLoading={initialValues.isLoading}
          isComponentError={initialValues.error !== undefined}
          error={initialValues.error}
          onErrorHandler={redirectHome}
          errorButtonLabel={t('recipeForm.errorButtonLabelModal')}
        >
          <RequestErrorHeader
            severity='error'
            requestError={(pharmacyProps as any).pharmaciesError}
          />

          <PrescriptionForm
            onSubmit={handleSubmit}
            patientData={initialValues as any}
            pharmacyProps={pharmacyProps}
            onCancel={handleCancel}
            validationSchema={addRecipeValidations(t)}
            createFormFields={adddRecipeForm}
            submitButtonLabel={t('recipeForm.registerButtonLabel')}
            backButtonLabel={t('recipeForm.backLabel')}
            onCountyChange={handleCountyChange}
            onRegionChange={handleRegionChange}
            regionProps={regionProps}
          />
        </ComponentPlaceholder>
      </div>
    </Container>
  )
};

const mapStateToProps = (state) => ({
  pharmacyProps: state.pharmacies,
  regionProps: state.regions,
});

export default connect(mapStateToProps)(withTranslation()(AddPrescription));
