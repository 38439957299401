import React, { PropsWithChildren } from 'react';
import { Checkboxes } from "mui-rff";
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { Link } from '@material-ui/core';

export interface TermsAndConditionsProps  extends PropsWithChildren<WithTranslation> {
  name?: string;
  isRequired?: boolean;
  t: any
}
const TermsAndConditions = (props) => (
  <Checkboxes
    name={props.name || "termsAndConditions"}
    data={{ label: 'termsAndConditions', value: true }}
    color="primary"
    required={props.isRequired}
    formControlLabelProps={{
      labelPlacement: "end",
      label: (
        <div>
          <Trans
            i18nKey="common.registrationForm.termsAndConditions"
          >
            I accept <Link href={'/terms'} target="_blank" rel="noreferrer">{props.t('common.registrationForm.terms')}</Link> and <Link href={'/privacy'} target="_blank" rel="noreferrer">{props.t('common.registrationForm.privacy')}</Link>
          </Trans>
        </div>
      )
    }}
  />
);

export default withTranslation()(TermsAndConditions);
