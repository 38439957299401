import * as Yup from 'yup';

export default function addPrescriptionValidations(t) {
  // We define our schema based on the same keys as our form:
  return Yup.object().shape({
    file: Yup.string().required(t('recipeForm.validations.recipe')),
    title: Yup.string().required(t('recipeForm.validations.title')),
    // pharmacyId: Yup.string().required(t('recipeForm.validations.pharmacyId')),
  });
};
