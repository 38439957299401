import React, { Suspense } from 'react';
import { Notifications } from 'react-push-notification';
import { connect } from 'react-redux';
import './App.css';
import { useTranslation } from 'react-i18next';

import {
  Switch,
  Route,
  Router
} from "react-router-dom";
import routes from './routes';
import NotificationComponent from './components/NotificationComponent/NotificationComponent';
import GenericLoader from './components/GenericLoader/GenericLoader';
import { notificationClear } from './reducers/notificationReducer';
import { history } from './helpers/history';
import PrivateRoute from './hocs/PrivateRoute';
import { Container, CssBaseline, Grid, makeStyles } from '@material-ui/core';
import logo from './logo.svg';
import AppFooter from './components/AppFooter/AppFooter';

// loading component for suspense fallback
const Loader = () => (
  <div className="App">
    <div className="App__logo-container">
      <img src={logo} className="App-logo" alt="logo" />
      <div>loading...</div>
    </div>
  </div>
);

const addRoute = (route, index) => {
  const {
    path,
    exact,
    page
  } = route;

  if (route.isPublic) {
    return (
      <Route
        path={path}
        exact={exact ? true : undefined}
        key={`route_${path}_index`}
        component={page}
      />
    );
  }

  return (
    <PrivateRoute
      key={`private_route_${path}_index`}
      path={path}
      exact={exact ? true : undefined}
      component={page} />
  );
}

const appClasses = makeStyles((theme) => ({
  root: {
    padding: 0
  }
}));

const Page = (props) => {
  const classes = appClasses();

  useTranslation();

  history.listen((location, action) => {
    // clear alert on location change
    props.clearNotifications();
  });

  return (
    <div className="App">
      <Notifications />
      <NotificationComponent {...props.notification} onClose={() => {
        props.clearNotifications();
      }} />
      <GenericLoader {...props.genericLoader} />

      <Container component="div" maxWidth={false} className={classes.root}>
        <CssBaseline />
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className="App__container"
        >
          <Router history={history}>
            <Switch>
              {routes.map((route, index) => addRoute(route, index)
              )}
            </Switch>
          </Router>
          <AppFooter />
        </Grid>
      </Container>

    </div>
  );
}

const App = (props) => {
  return (
    <Suspense fallback={<Loader />}>
      <Page {...props} />
    </Suspense>
  );
}

function mapState(state) {
  const { notification, genericLoader } = state;
  return { notification, genericLoader };
}

const actionCreators = {
  clearNotifications: notificationClear
};

const connectedApp = connect(mapState, actionCreators)(App);

export { connectedApp as App };
