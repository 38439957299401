const CHARACTERS = {
  ':': '%3A',
  '!' : '%21',
  '>' : '%3E',
  '<' : '%3C',
  '~' : '%7E',
  '*' : '%2A'
}

export default class SearchBuilder {
  searchTerms: string[] = [];

  constructor() {
    this.searchTerms = [];
  }

  withEqaulity(searchTerm, searchValue) {
    this.searchTerms.push(`${searchTerm}:${searchValue}`);

    return this;
  }

  withNegation(searchTerm, searchValue) {
    this.searchTerms.push(`${searchTerm}!${searchValue}`);

    return this;
  }

  withGreateThan(searchTerm, searchValue) {
    this.searchTerms.push(`${searchTerm}>${searchValue}`);

    return this;
  }

  withLeassThen(searchTerm, searchValue) {
    this.searchTerms.push(`${searchTerm}<${searchValue}`);

    return this;
  }

  withLikeValue(searchTerm, searchValue) {
    this.searchTerms.push(`${searchTerm}~${searchValue}`);

    return this;
  }

   withEndsWith(searchTerm, searchValue) {
    this.searchTerms.push(`${searchTerm}:*${searchValue}`);

    return this;
  }

  withStartstWith(searchTerm, searchValue) {
    this.searchTerms.push(`${searchTerm}:${searchValue}*`);

    return this;
  }

  withContains(searchTerm, searchValue) {
    this.searchTerms.push(`${searchTerm}:*${searchValue}*`);

    return this;
  }

  withAnd() {
    this.searchTerms.push('and');

    return this;
  }

  withOr() {
    this.searchTerms.push('or');

    return this;
  }

  withGroupStart() {
    this.searchTerms.push('(');

    return this;
  }

  withGroupEnd() {
    this.searchTerms.push(')');

    return this;
  }

  build() {
    let output = this.searchTerms.join(' ');
    output = encodeURIComponent(output);
    output = output.replace(/~/igm, CHARACTERS['~'])
      .replace(/!/igm, CHARACTERS['!']);

    return output;
  }
};
