import React from 'react';
import { Card, CardContent, makeStyles, createStyles, Typography, Grid } from "@material-ui/core";
import { withTranslation } from 'react-i18next';

const useStyles = makeStyles(
  createStyles({
    card: {
      minWidth: 275,
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  }),
);

const PatientDetailsShort = (props) => {
  const { profileData, t, children } = props,
    classes = useStyles();

  const renderPatientDetails = () => (
    <React.Fragment>
      <Typography className={classes.title} color="textSecondary" gutterBottom>
        {t('recipeForm.patientLabel')}
      </Typography>
      {profileData.firstName} {profileData.lastName}, { profileData.idNumber}
    </React.Fragment>
  );

  const renderDetailsOnly = () => (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {renderPatientDetails()}
      </Grid>
    </Grid>
  );

  const renderDetailsAndChildren = () => (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        {renderPatientDetails()}
      </Grid>
      <Grid item xs={12} sm={6}>
        {children}
      </Grid>
    </Grid>
  );

  return (
    <Card className={classes.card}>
      <CardContent>
        {children ? renderDetailsAndChildren() : renderDetailsOnly()}
      </CardContent>
    </Card>
  )
};

export default withTranslation()(PatientDetailsShort);
