import { useState } from 'react';
import React from 'react';

const useAddReceipe = (selectedPatientData, dialog, tableRef) => {
  const [isAddRecipeDialogOpen, setIsAddRecipeDialogOpen] = useState(false);

  const toggleAddRecipe = () => {
    setIsAddRecipeDialogOpen(!isAddRecipeDialogOpen);
  };

  const onCloseModal = (needsReload?: boolean) => {
    toggleAddRecipe();

    if(needsReload && typeof needsReload === 'boolean') {
      tableRef.current && tableRef.current.onQueryChange()
    }
  }

  const renderModal = () => {
    if (!isAddRecipeDialogOpen) {
      return null;
    }

    const DialogComponent = dialog;
    return (
      <DialogComponent
        onClose={onCloseModal}
        isOpen={true}
        patientData={selectedPatientData}
      />
    );
  }
  return {
    isAddRecipeDialogOpen,
    toggleAddRecipe,
    addRecipeModal: renderModal()
  };
}

export default useAddReceipe;
