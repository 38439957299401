import { PHARMACY_ROUTES } from './routes';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PermIdentity from '@material-ui/icons/PermIdentity';
import Lock from '@material-ui/icons/Lock';
import ExitToApp from '@material-ui/icons/ExitToApp';
import store from '../../store';
import { logout } from '../../reducers/authenticationReducer';

const getDrawerItems = (t) => {
  return [
    {
      type: 'list',
      title: t('doctorDashboard.titleFunctions'),
      items: [
        {
          href: PHARMACY_ROUTES.home,
          title: t('doctorDashboard.mainPage'),
          icon: DashboardIcon
        },
        {
          href: PHARMACY_ROUTES.recipeList,
          title: t('doctorDashboard.prescriptionList'),
          icon: AssignmentIcon
        },
      ]
    },
    {
      type: 'divider',
      title: 'Divider',
      items: []
    },
    {
      type: 'list',
      title: t('doctorDashboard.titleMyAccount'),
      items: [
        {
          href: PHARMACY_ROUTES.profile,
          title: t('doctorDashboard.profile'),
          icon: PermIdentity
        },
        {
          href: PHARMACY_ROUTES.changePassword,
          title: t('doctorDashboard.changePassword'),
          icon: Lock
        },
        {
          href: '',
          action: () => store.dispatch(logout()),
          title: t('doctorDashboard.logout'),
          icon: ExitToApp
        },
      ]
    },
  ];
};

export default getDrawerItems;
