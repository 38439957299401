import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';

export interface NotificationState {
  type: string,
  message: string,
  duration?: number
};

export const initialState: NotificationState = {
  type: '',
  message: ''
};

const notificationReducer = createSlice({
  'name': 'notifications',
  initialState,
  reducers: {
    notificationSuccess: {
      reducer: (state, action: PayloadAction<NotificationState>): NotificationState => {
        return {
          type: 'success',
          message: action.payload.message,
          duration: action.payload.duration
        };
      },
      prepare: (message: string, duration?: number) => ({
        payload: {
          message,
          duration,
          type: 'success'
        }
      })
    },
    notifcationError: {
      reducer: (state, action: PayloadAction<NotificationState>): NotificationState => {
        return {
          type: 'error',
          message: action.payload.message,
          duration: action.payload.duration
        };
      },
      prepare: (message: string, duration?: number) => ({
        payload: {
          message,
          duration,
          type: 'error'
        }
      })
    },
    notifcationWarning: {
      reducer: (state, action: PayloadAction<NotificationState>): NotificationState => {
        return {
          type: 'warning',
          message: action.payload.message,
          duration: action.payload.duration
        };
      },
      prepare: (message: string, duration?: number) => ({
        payload: {
          message,
          duration,
          type: 'warning'
        }
      })
    },
    notificationClear: (): NotificationState => {
      return initialState;
    }
  }
});

export const {
  notificationSuccess,
  notifcationError,
  notifcationWarning,
  notificationClear
} = notificationReducer.actions;

export default notificationReducer.reducer;

export const displayError = createAction('DISPLAY_ERROR', (message: string, duration?: number) => {
  return {
    type: notificationSuccess.type,
    payload: {
      message,
      duration: duration
    }
  };
});

export const displaySuccess = createAction('DISPLAY_SUCCESS', (message: string, duration?: number) => {
  return {
    type: notifcationError.type,
    payload: {
      message,
      duration: duration
    }
  };
});

export const displayWarning = createAction('DISPLAY_WARNING', (message: string, duration?: number) => {
  return {
    type: notifcationWarning.type,
    payload: {
      message,
      duration
    }
  };
});

export const clear = createAction('DISPLAY_CLEAR', () => {
  return { payload: {} };
});

