import { makeStyles } from '@material-ui/core/styles';

const login = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: 'transparent'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formContainer: {
    marginTop: theme.spacing(3)
  }
}));

export default login;
