import { all } from 'redux-saga/effects'

import authenticationSaga from './authenticationSaga';
import regionsSaga from './regionsSaga';
import pharmaciesSaga from './pharmaciesSaga';

export default function* rootSaga() {
  yield all([
    authenticationSaga(),
    regionsSaga(),
    pharmaciesSaga()
  ]);
};
