import { useState, useEffect } from "react";
import store from "../store";
import { displayLoader, clearLoader } from "../reducers/genericLoader";
import { getPharmacies } from "../services/pharmaciesService";
import { getPharmaciesSuccess } from "../reducers/pharmaciesReducer";
import { notifcationError } from "../reducers/notificationReducer";
import { getPatientById } from "../services/patientServices";

const useAddRecipe = (patientId, t): InitialDataDTO => {
  const [initialValues, setInitialValues] = useState({
    isLoading: false,
    isError: false,
    error: undefined
  }),
    { dispatch } = store;

  const loadInitialData = async () => {
    try {
      dispatch(displayLoader(t('loader.loadingInitialData')));
      setInitialValues({ 'loading': true } as any);
      const patientData = await getPatientById(patientId),
        {
          countyId,
          cityId
        } = patientData;

      if (!countyId || !cityId) {
        dispatch(notifcationError(t('loader.dataLoadError')));

        return;
      }

      const pharmacies = await getPharmacies(countyId, cityId);

      dispatch(getPharmaciesSuccess(pharmacies));

      setInitialValues(patientData);
    } catch (exc) {
      dispatch(notifcationError(t('loader.dataLoadError')));

      setInitialValues({ 'error': exc } as any);
    } finally {
      dispatch(clearLoader());
    }
  }

  useEffect(() => {
    loadInitialData();
  }, []);

  return initialValues;
}

export default useAddRecipe;
