import React, { useContext, Fragment } from 'react';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import { PushNotificationContext } from '../../providers/PushNotificationProvider';
import ToolbatMenuItems from './ToolbatMenuItems';
import { Fade } from '@material-ui/core';

const WebNotifications = () => {
  const notificationContext = useContext(PushNotificationContext);
  const { notifications } = notificationContext as any;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = 'primary-notifications-menu';

  const mappedNotification = notifications.map(notification => ({
    link: '',
    label: notification.content,
  }))
  const renderMenu = (
    mappedNotification.length > 0 ? (<Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      id={menuId}
      transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      TransitionComponent={Fade}
    >
      <ToolbatMenuItems items={mappedNotification} onMenuClose={handleMenuClose} />
    </Menu>) : null
  );

  return (
    <Fragment>
      <IconButton aria-label="show 17 new notifications" color="inherit" aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}>
        <Badge badgeContent={notifications.length} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton>

      {renderMenu}
    </Fragment>
  )

}

export default WebNotifications;
