import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import TextField from "./TextField";
import { makeStyles } from '@material-ui/core/styles';
import PhotoCamera from '@material-ui/icons/PhotoCamera';

const filelUploadStyles = makeStyles((theme) => ({
  image: {
    maxHeight: '100px'
  },
  fileInput: {
    display: 'none'
  }
}));

const PhotoUpload = (props) => {
  const { file, disabled } = props,
    [uploadedImage, setUploadedImage] = useState(
      file ? `data:image/png;base64,${props.file}` : '/no-image.png'
    ),
    classes = filelUploadStyles();

  const handleInputChange = (files: FileList) => {
    try {
      setUploadedImage(URL.createObjectURL(files[0]));
    } catch (exc) { }
  }

  const imgPreview = uploadedImage ? (
    <img
      src={uploadedImage as any}
      alt=''
      className={classes.image}
    />) : null;

  const renderUploadField = () => (
    <Grid container spacing={1}>
      <Grid item xs={12} key={`grid1_${props.name}`} sm={6}>
        <TextField
          {...props}
          inputProps={{ accept: "image/*", className: classes.fileInput, capture: true }}
          onInputChange={handleInputChange}
          id={props.name}
          uploadIcon={PhotoCamera}
        />
      </Grid>
      <Grid item xs={12} key={`grid2_${props.name}`} sm={6}>
        {imgPreview}
      </Grid>
    </Grid>
  );

  const renderPhotoOnly = () => (
    <Grid container spacing={1}>
      <Grid item xs={12} key={`grid2_${props.name}`}>
        {imgPreview}
      </Grid>
    </Grid>
  );

  return disabled ? renderPhotoOnly() : renderUploadField();
}

export default PhotoUpload;
