const hasServiceWorkerEnabled = 'serviceWorker' in navigator;

export default class Cache {
  static cacheObj = {};

  static add(key, value) {
    if(hasServiceWorkerEnabled) {
      return;
    }

    this.cacheObj[key] = value;
  }

  static get(key) {
    return this.cacheObj[key];
  }

  static remove(key) {
    delete this.cacheObj[key];
  }

  static addsubKey(key, subKey, value) {
    if(hasServiceWorkerEnabled) {
      return;
    }

    if(!this.cacheObj[key]) {
      this.cacheObj[key] = {};
    }

    this.cacheObj[key][subKey] = value;
  }

  static getsubKey(key, subKey) {
    return this.cacheObj[key] ? this.cacheObj[key][subKey] : null;
  }

  static removesubKey(key, subKey) {
    if(!this.cacheObj[key]) {
      return;
    }

    delete this.cacheObj[key][subKey];
  }
}
