import { call, put, takeLatest } from 'redux-saga/effects';
import {
  authorize as AuthorizeRequest,
  logout as LogoutRequest,
  authSuccess,
  authFailure,
  authSubmitting,
  logoutSuccess
} from '../reducers/authenticationReducer';

import { login, logout, me } from '../services/userService';
import { redirectToLogin, redirectToDashboard } from '../helpers/redirect';
import { setAuthSession, removeAuthSession } from '../helpers/authSession';
import RefreshTokenService from '../services/refreshTokenService';

function* authorize({ payload: { username, password } }) {
  try {
    yield put({ type: authSubmitting.type, payload: null });
    const token = yield call(login, { username, password });

    setAuthSession(token);
    const loggedInUserData: AUTH_INTERFACE = yield call(me);

    loggedInUserData.token = token && token.token;
    yield put({ type: authSuccess.type, payload: loggedInUserData });
    setAuthSession(loggedInUserData);

    RefreshTokenService.start();
    redirectToDashboard();
  } catch (error) {
    let errorCode, severity;

    const errorStatus = error && error.requestError && error.requestError.status;

    switch (errorStatus) {
      case 500:
        errorCode = 'err500';
        severity = 'error';
        break;
      case 401:
      case 403:
        errorCode = 'errLogin';
        severity = 'warning';
        break;
      default:
        errorCode = 'errUnknown';
        severity = 'error';
    }
    yield put(
      {
        type: authFailure.type,
        payload: {
          errorCode,
          severity
        }
      }
    );

    removeAuthSession();
  }
}

function* unauthorize() {
  RefreshTokenService.stop();

  try {
    yield put({
      type: authSubmitting.type,
      payload: null
    });
    yield call(logout);
  } catch(err) {
    yield put({
      type: logoutSuccess.type,
      payload: null
    });
  } finally {
    yield put({
      type: logoutSuccess.type,
      payload: null
    });
    removeAuthSession();

    redirectToLogin();
  }
}

function* Saga() {
  yield takeLatest(AuthorizeRequest.type as any, authorize);
  yield takeLatest(LogoutRequest.type as any, unauthorize);
}

export default Saga;
