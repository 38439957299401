import React, { useState, PropsWithChildren, useRef } from 'react';
import MaterialTable from 'material-table';
import getTranslations from '../../helpers/tableTranslations';
import { withTranslation, WithTranslation } from 'react-i18next';
import patientList from '../../hooks/patientList';
import tableIcons from '../../helpers/tableIcons';
import patientListStyles from '../../styles/patientList';
import PatientListDetails from './PatientListDetails';
import useAddReceipe from './useAddReceipe';
import useAddPatient from './useAddPatient';
import useEditPatient from './useEditPatient';

export interface PatientListProps {
  addRecipeDialog: (patientData) => void;
  editPatientDialog: (patientData) => void;
  addPatientDialog: (patientData) => void;
  onRedirectToPrescriptions: (patientId) => void;
  pageSize: number;
  loadList?: (pageSize: number, page: number, search: string) => any;
  title?: string;
}

const PatientList = (props: PropsWithChildren<WithTranslation & PatientListProps>) => {
  const classes = patientListStyles(),
    {
      addRecipeDialog,
      editPatientDialog,
      addPatientDialog,
      pageSize,
      t,
      onRedirectToPrescriptions,
      title
    } = props,
    [selectedPatientData, setSelectedPatientData] = useState({}),
    tableRef = useRef(null),
    { toggleAddRecipe, addRecipeModal } = useAddReceipe(
      selectedPatientData,
      addRecipeDialog,
      tableRef
    ),
    { toggleAddPatient, addPatientModal } =useAddPatient(
      addPatientDialog,
      tableRef
    ),
    { toggleEditPatient, editPatientModal } = useEditPatient(
      selectedPatientData,
      editPatientDialog,
      tableRef
    );

  const handleAddRecipe = (patientData) => {
    setSelectedPatientData(patientData);
    toggleAddRecipe();
  };

  const handleEditPatientOpen = (patientData) => {
    setSelectedPatientData(patientData);
    toggleEditPatient();
  }

  const handleViewRecipe = (patientData) => {
    onRedirectToPrescriptions(patientData.id);
  };

  return (
    <React.Fragment>
      <MaterialTable
        tableRef={tableRef}
        title={title || t('patientList.title')}
        icons={tableIcons}
        localization={getTranslations(t)}
        options={{
          draggable: false,
          sorting: false,
          padding: 'dense',
          debounceInterval: 400,
          pageSize: pageSize,
        }}
        columns={[
          { title: t('patientList.firstName'), field: 'firstName', defaultSort: 'asc' },
          { title: t('patientList.lastName'), field: 'lastName' },
          { title: t('patientList.cityId'), field: 'cityId' },
          { title: t('patientList.countyId'), field: 'countyId' },
        ]}
        data={query => {
          const loader = props.loadList || patientList;

          return new Promise((resolve) => {
            loader(query.pageSize, query.page, query.search)
              .then(data => resolve(data))
              .catch(err => {
                console.log('Error loading data', err);

                resolve({
                  data: [],
                  page: query.page,
                  totalCount: 0
                });
              });
          });
        }}
        actions={[
          {
            icon: 'add',
            tooltip: t('patientList.addPatient'),
            isFreeAction: true,
            onClick: () => toggleAddPatient()
          }
        ]}
        onRowClick={(_event, _rowData, togglePanel) => {
          if (togglePanel) {
            togglePanel();
          }
        }}
        detailPanel={(rowData: any) => <PatientListDetails
          classes={classes}
          rowData={rowData}
          onAddRecipe={handleAddRecipe}
          onEditPatient={handleEditPatientOpen}
          onViewRecipies={handleViewRecipe}
        />
        }
      />

      {addPatientModal}
      {addRecipeModal}
      {editPatientModal}
    </React.Fragment>
  )
};

export default withTranslation()(PatientList);
