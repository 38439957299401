import * as Yup from 'yup';

export default function editPhysicianValidations(t) {
  // We define our schema based on the same keys as our form:
  return Yup.object().shape({
    firstName: Yup.string().required(t('common.registrationFormValidations.firstName')),
    lastName: Yup.string().required(t('common.registrationFormValidations.lastName')),
    code: Yup.string().required(t('common.registrationFormValidations.code')),
    address: Yup.string().required(t('common.registrationFormValidations.cabinetAddress')),
    countyId: Yup.string().required(t('common.registrationFormValidations.county')),
    cityId: Yup.string().required(t('common.registrationFormValidations.city')).nullable(),
    phone: Yup.string().matches(/^[0-9]{10}$/, {
      message: t('common.registrationFormValidations.phoneInvalid')
    }).required(t('common.registrationFormValidations.phone'))
  });
};
