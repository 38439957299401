import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  registerPharmacy,
} from '../services/pharmaciesService';
import { notifcationError, notificationSuccess } from '../reducers/notificationReducer';
import registerPharmacyForm from '../forms/registerPharmacy/registerPharmacy';
import registerPharmacyValidations from '../forms/registerPharmacy/registerPharmacyValidations';
import RegistrationForm from '../components/RegistrationForm/RegistrationForm';
import PublicFormWrapper from '../components/PublicFormWrapper/PublicFormWrapper';
import AccountCircle from '@material-ui/icons/AccountCircle';
import RequestErrorHeader from '../components/FormElements/RequestErrorHeader';
import { FORM_ERROR } from 'final-form';
import useRegions from '../hooks/useRegions';
import { RegionContext } from '../providers/RegionProvider';
import { redirectToRegistrationComplete } from '../helpers/redirect';


const RegisterPharmacy = props => {
  const dispatch = useDispatch(),
    { t } = props,
    { handleCountyChange, handleRegionChange } = useRegions(),
    regionContext = useContext(RegionContext);

  const handleSubmit = async (formData) => {
    try {
      await registerPharmacy(formData);
      redirectToRegistrationComplete();

      setTimeout(() => {
        dispatch(notificationSuccess(t('pharmacyProfile.registrationComplete')));
      }, 0);
    } catch(err) {
      dispatch(notifcationError(err.requestError.message));

      return {
        [FORM_ERROR]: err.requestError.message
      };
    };
  };

  return (
    <PublicFormWrapper
      icons={AccountCircle}
      title={t('pharmacyProfile.createProfile')}
    >
      <RequestErrorHeader
        severity='error'
        requestError={regionContext.countyError as any}
      />
      <RequestErrorHeader
        severity='error'
        requestError={regionContext.cityError as any}
      />
      <RegistrationForm
        {...props}
        regionProps={{...regionContext}}
        onSubmit={handleSubmit}
        pharmacyProps={props.pharmacyProps}
        onCountyChange={handleCountyChange}
        onRegionChange={handleRegionChange}
        validationSchema={registerPharmacyValidations(t)}
        createFormFields={registerPharmacyForm}
        submitButtonLabel={t('pharmacyProfile.registerButtonLabel')}
      />
    </PublicFormWrapper>
  )
};

export default withTranslation()(RegisterPharmacy);

