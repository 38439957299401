export const ROLE_PHYSICIAN = 'ROLE_PHYSICIAN';
export const ROLE_PATIENT = 'ROLE_PATIENT';
export const ROLE_PHARMACY = 'ROLE_PHARMACY';

export default class UserRole {
  role: string;

  constructor(role) {
    this.role = role;
  }

  isPhysician() {
    return this.role === ROLE_PHYSICIAN;
  }

  isPatient() {
    return this.role === ROLE_PATIENT;
  }

  isPharmacy() {
    return this.role === ROLE_PHARMACY;
  }
}
