import { FormField } from "../../components/FormElements/FormFieldsRenderer";

const registerPatient = (t, requiredFields, regionProps, pharmacyProps) => {
  const patientFormDefinitions = [
    {
      key: 'firstName'
    },
    {
      key: 'lastName'
    },
    {
      key: 'email'
    },
    {
      key: 'idNumber'
    },
    {
      key: 'address'
    }
  ];

  const formFields: FormField[] = patientFormDefinitions.map(field => {
    const { key } = field;

    return {
      label: t(`common.registrationForm.${key}`),
      prefix: 'addPatientModal',
      isRequired: requiredFields[key],
      fieldType: 'Text',
      isFullWidth: true,
      subType: 'text',
      name: key
    };
  });

  formFields.push(
    {
      label: t(`regionSelector.county`),
      prefix: 'addPatientModal',
      isRequired: true,
      regionProps: regionProps,
      name: 'region',
      fieldType: 'Region'
    },
    {
      label: t(`common.registrationForm.phone`),
      prefix: 'addPatientModal',
      isRequired: requiredFields.phone,
      fieldType: 'Text',
      isFullWidth: true,
      subType: 'text',
      name: 'phone'
    },
    {
      label: t(`common.registrationForm.preferredPharmacy`),
      prefix: 'addPatientModal',
      isRequired: requiredFields.pharmacy,
      fieldType: 'Pharmacy',
      isFullWidth: true,
      name: 'pharmacyId',
      pharmacyProps: pharmacyProps
    }
  );

  return formFields;
}

export default registerPatient;
