import { PRESCRIPTION_URL, PHYSICIAN_URL, PHARMACY_URL } from '../urlPatterns';
import { get, post, patch, deleteRequest } from './requestService';
import RequestErrorException from './genericRequestError';
import { getUserRole } from '../helpers/authSession';

export function registerPrescription(prescriptionData: any): Promise<Array<any>> {
  return post(PRESCRIPTION_URL.register, prescriptionData, true)
    .then((response: Promise<any>) => response || [])
    .catch(err => {
      throw RequestErrorException(err);
    });
};

export function getPrescriptionById(prescriptionId: string): Promise<any> {
  return get(PRESCRIPTION_URL.getById.replace('{id}', prescriptionId))
    .then((response: Promise<any>) => response || {})
    .catch(err => {
      throw RequestErrorException(err);
    });
}

export function updatePrescription(prescriptionId: string, data: any): Promise<any> {
  return patch(PRESCRIPTION_URL.updatePrescription.replace('{id}', prescriptionId), data, true)
    .then((response: Promise<any>) => response || {})
    .catch(err => {
      throw RequestErrorException(err);
    });
}

export function deletePrescription(presscriptionId: string): Promise<any> {
  return deleteRequest(PRESCRIPTION_URL.updatePrescription.replace('{id}', presscriptionId))
    .then((response: Promise<any>) => response || {})
    .catch(err => {
      throw RequestErrorException(err);
    });
}

function getPrescriptionLinkBasedOnTole(patientId) {
  const userRole = getUserRole();

  if (userRole.isPhysician()) {
    return patientId ? PHYSICIAN_URL.patientPrescriptions : PHYSICIAN_URL.pescriptions;
  }

  if (userRole.isPharmacy()) {
    return PHARMACY_URL.pescriptions;
  }

  return PRESCRIPTION_URL.prescriptions;
}

export function getPrescriptionsByPatientId(patientId: string, perPage = 5, page = 0, search = '', sortBy = 'status', sortOrder = 'DESC', prescriptionStatuses = []): Promise<any> {
  const endpoint = getPrescriptionLinkBasedOnTole(patientId);
  const configObj = {
    size: perPage,
    sortOrder: sortOrder ? sortOrder.toUpperCase() : 'DESC',
    page,
    search,
    sortBy,
  };

  if(!search) {
    delete (configObj as any).search;
  }

  // const prescriptionStatuses = prescriptionStatuses.map(status => '')
  return get(endpoint.replace('{id}', patientId), configObj)
    .then((response: Promise<any>) => response || {})
    .catch(err => {
      throw RequestErrorException(err);
    });
}
