import { FormField } from "../../components/FormElements/FormFieldsRenderer";

const registerPhysician = (t, requiredFields, regionProps ) => {
  const patientFormDefinitions = [
    {
      key: 'code'
    },
    {
      key: 'firstName'
    },
    {
      key: 'lastName'
    },
    {
      key: 'profession'
    },
    {
      key: 'email'
    },
    {
      key: 'password',
      type: 'password'
    },
    {
      key: 'address',
      label:  t('common.registrationForm.cabinetAddress')
    }
  ];

  const formFields: FormField[] = patientFormDefinitions.map(field => {
    const { key, type, label } = field;

    return {
      label: label || t(`common.registrationForm.${key}`),
      prefix: 'doctorProfile',
      isRequired: field.key !== 'email' ? requiredFields[key] : false,
      fieldType: 'Text',
      isFullWidth: true,
      subType: type || 'text',
      name: key,
      isDisabled: field.key === 'email'
    };
  });

  formFields.push(
    {
      label: t(`regionSelector.county`),
      prefix: 'doctorProfile',
      isRequired: true,
      regionProps: regionProps,
      name: 'region',
      fieldType: 'Region' 
    },
    {
      label: t(`common.registrationForm.phone`),
      prefix: 'doctorProfile',
      isRequired: requiredFields.phone,
      fieldType: 'Text',
      isFullWidth: true,
      subType: 'text',
      name: 'phone'
    },
    {
      label: t('common.registrationForm.activationCode'),
      fieldType: 'Text',
      prefix: 'doctorProfile',
      isRequired: requiredFields.activationCode,
      isFullWidth: true,
      subType: 'text',
      name: 'activationCode'
    },
    {
      label: t('common.registrationForm.terms'),
      fieldType: 'Terms',
      prefix: 'doctorProfile',
      isRequired: requiredFields.termsAndConditions,
      isFullWidth: true,
      subType: 'text',
      name: 'terms'
    },
  );

  return formFields;
}

export default registerPhysician;
