import React from 'react';
import { withTranslation } from 'react-i18next';
import AddPatientModalContainer from '../../containers/modals/AddPatientModalContainer';
import AddPrescriptionModalContainer from '../../containers/modals/AddPrescriptionModalContainer';
import EditPatientModalContainer from '../../containers/modals/EditPatientModalContainer';
import PateintListComponent from '../../components/PatientList/PateintList';
import { redirect } from '../../helpers/redirect';
import { DOCTOR_ROUTES } from './routes';

const PatientList = (props) => {
  const handleRedirectToPrescriptions = (patientId) => {
    redirect(DOCTOR_ROUTES.patientRecipeList.replace(':patientId', patientId))
  }

  return (
    <PateintListComponent
      addRecipeDialog={AddPrescriptionModalContainer}
      editPatientDialog={EditPatientModalContainer}
      addPatientDialog={AddPatientModalContainer}
      onRedirectToPrescriptions={handleRedirectToPrescriptions}
      pageSize={10}
    />
  );
};

export default withTranslation()(PatientList);
