import React from 'react';
import {
    useParams
} from "react-router-dom";
import EditPharmacyProfile from './EditPharmacyProfile';
import RegionProvider from '../../providers/RegionProvider';
import PharmacyDataProvider from '../../providers/PharmacyDataProvider';
import PharmacyProvider from '../../providers/PharmacyProvider';
import { getLoggedInUserId } from '../../helpers/authSession';

export default (props) => {
    const getPharmacyId = (params) => {
        const { pharmacyId } = params,
            loggedInId = getLoggedInUserId();

        return pharmacyId || loggedInId;
    };

    return (
        <RegionProvider>
            <PharmacyProvider>
                <PharmacyDataProvider pharmacyId={getPharmacyId(useParams())}>
                    <EditPharmacyProfile {...props} />
                </PharmacyDataProvider>
            </PharmacyProvider>
        </RegionProvider>
    )
};
