import ExitToApp from '@material-ui/icons/ExitToApp';
import PermIdentity from '@material-ui/icons/PermIdentity';
import Lock from '@material-ui/icons/Lock';
import store from '../../store';
import { PATIENT_ROUTES } from './routes';
import { logout } from '../../reducers/authenticationReducer';
import { redirect } from '../../helpers/redirect';

const toolbarMenuItems = [
  {
    translationKey: 'patientDashboard.profile',
    label: '',
    icon: PermIdentity,
    action: () => redirect(PATIENT_ROUTES.profile),
  },
  {
    translationKey: 'patientDashboard.changePassword',
    label: '',
    icon: Lock,
    action: () => redirect(PATIENT_ROUTES.changePassword),
  },
  {
    translationKey: 'patientDashboard.logout',
    label: '',
    icon: ExitToApp,
    action: () => store.dispatch(logout())
  }
];

export default toolbarMenuItems;
