import React from 'react';
import { Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { RequestError } from '../../services/genericRequestError';
import requestErrorHeaderStyles from '../../styles/requestErrorHeader';

export const ERR_TYPE_ERROR = 'error';
export const  ERR_TYPE_WARNING = 'warning';
export const ERR_TYPE_SUCCESS = 'success'
export const ERR_TYPE_INFO = 'info';

export type SEVERITY_TYPES = 
  | typeof ERR_TYPE_ERROR
  | typeof ERR_TYPE_WARNING
  | typeof ERR_TYPE_SUCCESS
  | typeof ERR_TYPE_INFO;

export interface RequestErrorHeaderProps {
  severity?: SEVERITY_TYPES;
  requestError?: RequestError;
}

const renderErrorRow = (errors, classes) => {
  return errors.map((err, index) => (<p key={`err_message_${index}`} className={classes.paragraph}>{err.translatedMessage}</p>));
};

const RequestErrorHeader = (props: RequestErrorHeaderProps) => {
  const {
    severity,
    requestError
  } = props,
  classes= requestErrorHeaderStyles();

  if(!requestError) {
    return null;
  }

  const { message, errors } = requestError;

  if (!message) {
    return null;
  }

  return (
    <Grid item xs>
      <Alert severity={severity || 'warning'} variant='outlined'>
        {renderErrorRow(errors, classes)}
      </Alert>
    </Grid>
  );
}

export default RequestErrorHeader;
