import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Grid,
} from '@material-ui/core';
import {
  Alert,
} from '@material-ui/lab/';
import {
  TextField,
  Checkboxes,
  makeValidate,
  makeRequired,
} from 'mui-rff';

import LoginFormStyle from './LoginFormStyle';
import LoginFormValidation from './LoginFormValidation';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import PasswordField from '../FormElements/PasswordField';

const renderForm = (classes, requiredFields, t, isSubmitting) => ({ handleSubmit }) => {
  return (
    <form className={classes.form} onSubmit={handleSubmit} noValidate>
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        id="username"
        label={t('login.username')}
        name="username"
        autoComplete="login-username"
        required={requiredFields.username}
        size="small"
        color="secondary"
      />

      <PasswordField
        variant="outlined"
        margin="normal"
        fullWidth
        name="password"
        label={t('login.password')}
        type="password"
        id="password"
        autoComplete="login-password"
        required={requiredFields.password}
        size="small"
        color="secondary"
        withNoStrengthMetter={true}
      />

      <Checkboxes name="remember"
        data={{ label: t('login.rememberMe'), value: true }}
        value="remember"
        color="primary" />

      <Button
        type="submit"
        fullWidth
        variant="outlined"
        color="primary"
        className={classes.submit}
        disabled={isSubmitting}
      >
        {t('login.submitButtonLabel')}
      </Button>
    </form>

  );
}

const renderFormError = (error, t) => {
  if (!error || !error.errorCode) {
    return null;
  }

  return (
    <Grid item xs>
      <Alert severity={error.severity || 'warning'} variant='outlined'>
        {t(`errors.${error.errorCode}`)}
      </Alert>
    </Grid>
  );
}

const LoginForm = props => {
  const classes = LoginFormStyle(),
    { error, onSubmit, t, isSubmitting } = props,
    validationSchema = LoginFormValidation(t),
    validate = makeValidate(validationSchema),
    required = makeRequired(validationSchema);

  return (
    <React.Fragment>
      {renderFormError(error, t)}

      <Form
        onSubmit={onSubmit}
        validate={validate}
        validateOnBlur={false}
        render={renderForm(classes, required, t, isSubmitting)}
      />

      {props.children}
    </React.Fragment>
  );
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func,
  error: PropTypes.object,
  isSubmitting: PropTypes.bool
};

export default withTranslation()(LoginForm);
