import React from 'react';
import {
    useParams
} from "react-router-dom";
import EditPatientContainer from '../containers/EditPatientContainer';
import PatientDataProvider from '../providers/PatientDataProvider';
import RegionProvider from '../providers/RegionProvider';
import { getLoggedInUserId } from '../helpers/authSession';
import PharmacyProvider from '../providers/PharmacyProvider';

export default (props) => {
    const getPatientId = (params) => {
        const { patientId } = params,
            loggedInId = getLoggedInUserId();

        return patientId || loggedInId;
    },
        patientId = getPatientId(useParams());

    return (<RegionProvider>
        <PharmacyProvider>
            <PatientDataProvider patientId={patientId}>
                <EditPatientContainer {...props} patientId={patientId} />
            </PatientDataProvider>
        </PharmacyProvider>
    </RegionProvider>
    )
};
