import React, { PropsWithChildren } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import {
  CircularProgress,
} from '@material-ui/core';
import {
  TextField,
  Autocomplete,
  AutocompleteData
} from 'mui-rff';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

const filter = createFilterOptions<string>();

export interface CitySelectorProps extends PropsWithChildren<WithTranslation> {
  cities: AutocompleteData[]
  isRequired?: boolean,
  isDisabled?: boolean,
  isLoading?: boolean,
  isFullWidth?: boolean
  onCityChange: (cityId: string) => void
}

const CitySelector = (props: CitySelectorProps) => {
  const { cities, isRequired, isDisabled, isLoading, t, onCityChange } = props,
    name = 'cityId';

  const handleInputChange = debounce((event, value) => {
    onCityChange(value);
  }, 400);

  return (<Autocomplete
    freeSolo
    id={name}
    options={cities}
    name={name}
    label={t('regionSelector.city')}
    key={name}
    forcePopupIcon={true}
    disableClearable={true}
    required={isRequired}
    onChange={handleInputChange}
    filterOptions={(options: any, params: any) => {
      const autocompleteInput = name ? document.getElementById(name) : null,
        autocompleteInputValue = autocompleteInput ? (autocompleteInput as any).value : '',
        inputParams = { ...params, inputValue: autocompleteInputValue },
        filteredCities = autocompleteInputValue !== ''
          ? filter(options, inputParams) as string[] : options;

      if (autocompleteInputValue !== '') {
        const hasSelection = filteredCities.find(city => city === autocompleteInputValue);

        if (!hasSelection) {
          filteredCities.push(inputParams.inputValue);
        }
      }

      return filteredCities;
    }}
    renderOption={(option) => (
      <React.Fragment>
        <span>{option}</span>
      </React.Fragment>
    )}
    // textFieldProps={{
    //   InputProps: {
    //     disabled: isDisabled,
    //     startAdornment: (
    //       <React.Fragment>
    //         {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
    //       </React.Fragment>
    //     ),
    //   }
    // }}
  />);
};

export default withTranslation()(CitySelector);
