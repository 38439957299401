import React, { createContext, useState } from 'react';
import Notification from "react-web-notification";
import SockJsClient from 'react-stomp';
import { getToken } from '../helpers/authSession';

export interface SocketNotification {
  message: string
}

export const initialState: String[] = [];

export const PushNotificationContext = createContext(initialState);

const PushNotificationProvider = (props: any) => {
  const [notifications, setNotifications] = useState(initialState);
  const [ignore, setIgnore] = useState(true);
  const [webNotification, setWebNotification] = useState({
    title: '',
    options: {}
  });

  const handlePermissionGranted = () => {
    console.log('Permission Granted');
    setIgnore(false);
  }
  const handlePermissionDenied = () => {
    console.log('Permission Denied');
    setIgnore(true);
  }
  const handleNotSupported = () => {
    console.log('Web Notification not Supported');
    setIgnore(true);
  }

  const handleNotificationOnClick = (e, tag) => {
    console.log(e, 'Notification clicked tag:' + tag);
  }

  const handleNotificationOnError = (e, tag) => {
    console.log(e, 'Notification error tag:' + tag);
  }

  const handleNotificationOnClose = (e, tag) => {
    console.log(e, 'Notification closed tag:' + tag);
  }

  const handleNotificationOnShow = (e, tag) => {
    console.log(e, 'Notification shown tag:' + tag);
  }


  let clientRef = null;

  const value = {
    notifications
  };

  const authToken = getToken();
  const headers = {
    // Authorization: `Bearer ${authToken}`,
    // 'X-Authorization': `Bearer ${authToken}`
  };

  const sendMessage = () => {
    (clientRef as any).sendMessage('/app/userInput', JSON.stringify({
      msg: 'test message',
      type: 'user'
    }), headers);
  }

  const showNotificaton = (title, body) => {
    const now = Date.now();
    const tag = now;
    const icon =
      "http://mobilusoss.github.io/react-web-notification/example/Notifications_button_24.png";
    const options = {
      tag,
      body,
      icon,
      lang: "en",
      dir: "ltr",
    };

    setWebNotification({
      title,
      options,
    })
  }

  const handleMessage = msg => {
    const newNotifications = [...notifications];
    newNotifications.push(msg);

    showNotificaton('Farma Online Delivery', msg.content);
    setNotifications(newNotifications);
  }


  return !clientRef && (
    <>
      <Notification
        ignore={ignore && webNotification.title !== ""}
        notSupported={handleNotSupported}
        onPermissionGranted={handlePermissionGranted}
        onPermissionDenied={handlePermissionDenied}
        onShow={handleNotificationOnShow}
        onClick={handleNotificationOnClick}
        onClose={handleNotificationOnClose}
        onError={handleNotificationOnError}
        timeout={5000}
        title={webNotification.title}
        options={webNotification.options}
      />
      <PushNotificationContext.Provider value={value as any}>
        {authToken && <><SockJsClient url={`https://api.farmaonline-delivery.ro/userEndpoint?token=${getToken()}`} topics={['/user/topic/userOutput']}
          onMessage={(msg) => handleMessage(msg)}
          ref={(client) => { clientRef = client }}
          headers={headers}
          subscribeHeaders={headers} debug />

          <button onClick={sendMessage} /></>}
        {props.children}
      </PushNotificationContext.Provider>
    </>
  )
}

export default PushNotificationProvider;
