import React, { PropsWithChildren, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import {
  updatePatientProfile,
} from '../../services/patientServices';
import {
  notifcationError,
  notificationSuccess
} from '../../reducers/notificationReducer';
import editPatientProfileForm from '../../forms/editPatientOwnProfile/editPatientProfile';
import editPatienrProfileValidations from '../../forms/editPatientOwnProfile/editPatienrProfileValidations';
import ComponentPlaceholder from '../../hocs/ComponentPlaceholder';
import RegistrationForm from '../../components/RegistrationForm/RegistrationForm';
import { FORM_ERROR } from 'final-form';
import useRegions from '../../hooks/useRegions';
import RequestErrorHeader from '../../components/FormElements/RequestErrorHeader';
import filterFields from '../../forms/editPatientModal/filterFields';
import { RegionContext } from '../../providers/RegionProvider';
import { PatientnDataContext } from '../../providers/PatientDataProvider';
import { PharmacyContext } from '../../providers/PharmacyProvider';

export interface EditPatientModalProps {
  isOpen: boolean;
  onClose: (reload?: boolean) => {};
  patientData: EditPatientDTO;
  t?: any;
};

const EditPatientModal = (props: PropsWithChildren<WithTranslation & EditPatientModalProps>) => {
  const dispatch = useDispatch(),
    {
      isOpen,
      onClose,
      patientData,
      t,
    } = props,
    { id: patientId } = patientData,
    regionProps = useContext(RegionContext),
    fullPatientData = useContext(PatientnDataContext),
    pharmacyContext = useContext(PharmacyContext),
    { handleCountyChange, handleRegionChange } = useRegions(async (countyId, cityId) => {
      pharmacyContext.getPharmacies(countyId, cityId)
    });

  const handleSubmit = async (formData) => {
    // formData.pharmacyId = formData.pharmacy && formData.pharmacy.id;
    delete formData.pharmacy;
    
    if(!formData.pharmacyId) {
      delete formData.pharmacyId;
    }
    
    const filteredFormData = filterFields(formData);

    try {
      await updatePatientProfile(patientId, filteredFormData);

      onClose(true);
      
      setTimeout(() => {
        dispatch(notificationSuccess(t('patientProfile.updateComplete')));
      }, 0);
    } catch(err) {
      dispatch(notifcationError(err.requestError.message));

      return {
        [FORM_ERROR]: err.requestError.message
      };
    };
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose as any}
      aria-labelledby="edit-patient-dialog-title"
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
    >
      <DialogTitle id="edit-patient-dialog-title">{t('patientProfile.editPatientTitle')}</DialogTitle>
      <DialogContent>
        <ComponentPlaceholder
          isComponentLoading={fullPatientData.isLoading}
          isComponentError={fullPatientData.isError}
          error={fullPatientData.error}
          onErrorHandler={onClose}
          errorButtonLabel={t('patientProfile.errorButtonLabel')}
        >
          <RequestErrorHeader
            severity='error'
            requestError={(regionProps as any).countyError}
          />
          <RequestErrorHeader
            severity='error'
            requestError={(regionProps as any).cityError}
          />
          <RequestErrorHeader
            severity='error'
            requestError={(pharmacyContext as any).pharmaciesError}
          />

          <RegistrationForm
            initialValues={fullPatientData.profileData}
            onSubmit={handleSubmit}
            regionProps={regionProps as any}
            pharmacyProps={pharmacyContext as any}
            validationSchema={editPatienrProfileValidations(t)}
            createFormFields={editPatientProfileForm}
            submitButtonLabel={t('patientProfile.registerButtonLabel')}
            onCancel={onClose}
            onCountyChange={handleCountyChange}
            onRegionChange={handleRegionChange}
          />
        </ComponentPlaceholder>
      </DialogContent>
    </Dialog>
  )
};


export default withTranslation()(EditPatientModal);
