import React from 'react';
import { Container } from '@material-ui/core';
import publicAppHeader from '../../styles/publicAppHeader';
import logo from '../../logo_borderless.svg';

const PublicAppHeader = (props) => {
  const classes = publicAppHeader();
  return (
    <Container component="header" className={classes.main}>
      <img src={logo} className={classes.logo} alt='logo'/>
    </Container>
  );
};

export default PublicAppHeader;
