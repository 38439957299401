export default (fields = {}) => {
  return Object.keys(fields).filter(field => {
    return field !== 'password'
      && field !== 'enabled'
      && field !== 'prescriptionIds'
      && field !== 'userId'
      && field !=='physicianId'
      && field !== 'email'
      && field !== 'id'
  }).reduce((res, key) => {
    res[key] = fields[key];

    return res;
  }, {});
}
