import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  useParams
} from "react-router-dom";
import {
  Container,
  CssBaseline,
  Typography,
} from '@material-ui/core';
import {
  updatePhysicianProfile,
} from '../services/physicianServices';
import {
  notifcationError,
  notificationSuccess
} from '../reducers/notificationReducer';
import editPhysicianStyles from '../styles/editPhysician';
import RegistrationForm from '../components/RegistrationForm/RegistrationForm';
import editPhysician from '../forms/editPhysician/editPhysician';
import editPhysicianValidations from '../forms/editPhysician/editPhysicianValidations';
import { FORM_ERROR } from 'final-form';
import { redirectToDashboard } from '../helpers/redirect';
import useRegions from '../hooks/useRegions';
import ComponentPlaceholder from '../hocs/ComponentPlaceholder';
import RequestErrorHeader from '../components/FormElements/RequestErrorHeader';
import { getLoggedInUserId } from '../helpers/authSession';
import { RegionContext } from '../providers/RegionProvider';
import { PhysicianDataContext } from '../providers/PhysicianDataProvider';

const EditPhysicianContainer = props => {
  const getPhysicianId = (params) => {
    const { doctorId } = params,
      loggedInId = getLoggedInUserId();

    return doctorId || loggedInId;
  }

  const dispatch = useDispatch(),
    { t } = props,
    regionProps = useContext(RegionContext),
    physicianDataContext = useContext(PhysicianDataContext),
    doctorId = getPhysicianId(useParams()),
    classes = editPhysicianStyles(),
    { handleCountyChange, handleRegionChange } = useRegions();

  const handleSubmit = async (formData) => {
    try {
      await updatePhysicianProfile(doctorId, formData);
      redirectToDashboard();

      setTimeout(() => {
        dispatch(notificationSuccess(t('doctorProfile.updateComplete')));
      }, 0);
    } catch (err) {
      dispatch(notifcationError(err.requestError.message));

      return {
        [FORM_ERROR]: err.requestError.message
      };
    };
  };

  const handleCancel = () => {
    redirectToDashboard();
  }
  return (
    <ComponentPlaceholder
      isComponentLoading={physicianDataContext.isLoading}
      isComponentError={physicianDataContext.isError}
      error={physicianDataContext.error}
      onErrorHandler={redirectToDashboard}
      errorButtonLabel={t('doctorProfile.errorButtonLabel')}
    >
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            {t('doctorProfile.editProfile')}
          </Typography>

          <RequestErrorHeader
            severity='error'
            requestError={(regionProps as any).countyError}
          />
          <RequestErrorHeader
            severity='error'
            requestError={(regionProps as any).cityError}
          />

          <RegistrationForm
            {...props}
            initialValues={physicianDataContext.profileData}
            onSubmit={handleSubmit}
            regionProps={regionProps}
            validationSchema={editPhysicianValidations(t)}
            createFormFields={editPhysician}
            submitButtonLabel={t('patientProfile.registerButtonLabel')}
            onCancel={handleCancel}
            onCountyChange={handleCountyChange}
            onRegionChange={handleRegionChange}
          />
        </div>
      </Container>
    </ComponentPlaceholder>
  )
};

export default withTranslation()(EditPhysicianContainer);
