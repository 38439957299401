import React from 'react';
import EditPatientModal from './EditPatientModal';
import RegionProvider from '../../providers/RegionProvider';
import PatientDataProvider from '../../providers/PatientDataProvider';
import PharmacyProvider from '../../providers/PharmacyProvider';

export default (props) => {
    const {
        patientData,
    } = props,
        { id: patientId } = patientData;

    return (
        <RegionProvider>
            <PharmacyProvider>
                <PatientDataProvider patientId={patientId}>
                    <EditPatientModal {...props} />
                </PatientDataProvider>
            </PharmacyProvider>
        </RegionProvider>
    )
};
