import { makeStyles, createStyles } from '@material-ui/core/styles';

const editPatient = makeStyles((theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }
  })
);

export default editPatient;
