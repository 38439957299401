import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import patientListRequester from '../hooks/patientList';
import { withTranslation } from 'react-i18next';

const ChoosePatient = (props) => {
  const [open, setOpen] = useState(false),
    [filter, setFilter] = useState(''),
    [options, setOptions] = useState<Patient[]>([]),
    [isLoading, setIsLoading] = useState(false),
    { onSelect, t, style } = props;


  React.useEffect(() => {
    let active = true;

    if (!open) {
      return undefined;
    }

    (async () => {
      setIsLoading(true);
      const patients = await patientListRequester(10, 0, filter);

      if (active) {
        setOptions(patients.data);
      }

      setIsLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [open, filter]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      inputValue={filter}
      style={style}
      id="choose-patient"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setFilter('');
        setOpen(false);
      }}
      autoComplete
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => `${option.firstName} ${option.lastName} ${option.idNumber}`}
      options={options}
      loading={isLoading}
      onChange={(event: any, newValue: Patient | null) => {
        if (onSelect) {
          onSelect(newValue);
        }
      }}
      onInputChange={(_event, newInputValue) => {
        if (newInputValue !== filter) {
          setFilter(newInputValue);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('common.choosePatient')}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

export default withTranslation()(ChoosePatient);
