import React from "react";
import { ListItemProps, ListItem, ListItemIcon, ListItemText, List, ListSubheader } from "@material-ui/core";
import { redirect } from "../../helpers/redirect";

const doRedirect = (href) => {
  redirect(href);
}

const DrawerContentList = (props) => {
  const { items, onMenuItemSelect } = props;

  const onItemClick = (item) => {
    const {
      href,
      action
    } = item;

    if (onMenuItemSelect) {
      onMenuItemSelect();
    }

    if (href) {
      doRedirect(href);
    } else {
      action();
    }
  }

  const ListItemLink = (
    props: ListItemProps<'span', { button?: true, item: any }>
  ) => {
    const {
      button,
      item,
      ...rest
    } = props;

    return <ListItem button component="span" {...rest} onClick={onItemClick.bind(null, item)} />;
  }

  const renderItems = () => {
    return items.map(item => {
      const {
        title,
        icon: Icon,
      } = item;

      return (
        <ListItemLink
          item={item}
          key={item.title}
        >
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
          <ListItemText primary={title} />
        </ListItemLink>
      );
    });
  }

  return (
    <List>
      <ListSubheader inset>Functii</ListSubheader>
      {renderItems()}
    </List>
  );
}

export default DrawerContentList;
