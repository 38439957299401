import React, { useState, useRef } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import getTranslations from '../../helpers/tableTranslations';
import { withTranslation } from 'react-i18next';
import prescriptionList from '../../hooks/prescriptionList';
import tableIcons from '../../helpers/tableIcons';
import prescriptionListStyles from '../../styles/prescriptionList';
import useEditPrescription from './useEditPrescription';
import useDeletePrescription from './useDeletePrescription';
import useAddPrescription from './useAddPrescription';
import usePrintPrescription from './usePrintPrescription';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { PRESCRIPTION_FILTERS } from '../../urlPatterns';

const PrescriptionList = (props) => {
  const [selectedPrescriptionData, setSelectedPrescriptionData] = useState({}),
    [prescriptionStatuses, setPrescriptionStatuses] = useState([PRESCRIPTION_FILTERS[0]]),
    classes = prescriptionListStyles(),
    {
      t,
      pageSize,
      title,
      patientId,
      patientData,
      editPrescriptionDialog,
      deletePrescriptionDialog,
      addPrescriptionDialog,
      printPrescriptionDialog,
      actions
    } = props,
    tableRef = useRef(null),
    {
      togglePrescriptionDialog,
      editPrescriptionModal
    } = useEditPrescription(selectedPrescriptionData, editPrescriptionDialog, tableRef),
    {
      toggleDeleteDialog,
      deletePrescriptionModal
    } = useDeletePrescription(selectedPrescriptionData, deletePrescriptionDialog, tableRef),
    {
      toggleAddPrescription,
      addPrescriptionModal
    } = useAddPrescription(patientData, addPrescriptionDialog, tableRef),
    {
      togglePrintPrescriptionDialog,
      printPrescriptionModal
    } = usePrintPrescription(
      selectedPrescriptionData,
      patientData,
      patientId,
      printPrescriptionDialog,
      tableRef,
      t
    ),
    actionMapper = (actions: string[] = []) => {
      const results: any = [];

      actions.forEach(action => {
        switch (action) {
          case 'add':
            results.push({
              icon: 'add',
              tooltip: t('patientList.addPatient'),
              isFreeAction: true,
              onClick: () => {
                toggleAddPrescription();
              }
            });
            break;
          case 'delete': {
            results.push(_rowData => {
              return {
                icon: 'delete',
                tooltip: 'Delete prescription',
                onClick: (_event: any, rowData: any) => {
                  setSelectedPrescriptionData(rowData);
                  toggleDeleteDialog();
                },
              };
            });
            break;
          }
          case 'edit': {
            results.push(_rowData => {
              return {
                icon: 'edit',
                tooltip: 'Edit prescription',
                onClick: (_event: any, rowData: any) => {
                  setSelectedPrescriptionData(rowData);
                  togglePrescriptionDialog();
                }
              };
            });
            break;
          }
          case 'print': {
            results.push(_rowData => {
              return {
                icon: 'print',
                tooltip: 'Print prescription',
                onClick: (_event: any, rowData: any) => {
                  setSelectedPrescriptionData(rowData);
                  togglePrintPrescriptionDialog();
                }
              };
            });
            break;
          }
        }
      });

      return results;
    },
    augmentedActions = actionMapper(actions);

  const handleFilterSelection = (event, newFormats) => {
    setPrescriptionStatuses(newFormats);
  };

  return (
    <React.Fragment>
      <MaterialTable
        tableRef={tableRef}
        title={title || t('prescriptionList.title')}
        icons={tableIcons}
        localization={getTranslations(t)}
        options={{
          draggable: false,
          sorting: true,
          padding: 'dense',
          debounceInterval: 400,
          pageSize: pageSize,
          actionsColumnIndex: -1,
        }}
        columns={[
          { title: t('prescriptionList.recordTitle'), field: 'title' },
          { title: t('prescriptionList.notes'), field: 'notes' },
          {
            title: t('prescriptionList.photo'), field: 'photo', render: (rowData) => {
              return <img
                src={`data:image/png;base64,${rowData.photo}`}
                alt={rowData.title}
                className={classes.image}
              />;
            }
          },
          { title: t('prescriptionList.status'), field: 'status', defaultSort: 'desc' },
        ]}
        components={{
          Toolbar: props => (
            <div>
              <MTableToolbar {...props} />
              <div className={classes.headerActionsContainer}>
              <ToggleButtonGroup value={prescriptionStatuses} onChange={handleFilterSelection} aria-label="text formatting">
                {
                  PRESCRIPTION_FILTERS.map((prescriptionStatus) => (
                    <ToggleButton value={prescriptionStatus} aria-label={prescriptionStatus} key={prescriptionStatus} className={classes.headerActions}>
                      <span>{t(`prescriptionList.header.statuses.${prescriptionStatus}`)}</span>
                    </ToggleButton>
                  ))
                }
              </ToggleButtonGroup>
              </div>
            </div>
          ),
        }}
        data={query => {
          const loader = props.loadList || prescriptionList;

          return new Promise((resolve) => {
            const {
              page,
              pageSize,
              search,
              orderBy,
              orderDirection,
            } = query;

            loader(patientId, pageSize, page, search, orderBy && orderBy.field, orderDirection, prescriptionStatuses)
              .then(data => resolve(data))
              .catch(err => {
                console.log('Error loading data', err);

                resolve({
                  data: [],
                  page: query.page,
                  totalCount: 0
                });
              });
          });
        }}
        actions={augmentedActions}
      />

      {editPrescriptionModal}
      {deletePrescriptionModal}
      {addPrescriptionModal}
      {printPrescriptionModal}
    </React.Fragment>
  )
};

export default withTranslation()(PrescriptionList);
