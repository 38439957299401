import { history } from '../helpers/history';
import { removeAuthSession } from './authSession';
import { GLOBAL_ROUTES } from '../routes';
import { getUserRole } from '../helpers/authSession';
import store from '../store';
import { clearLoader } from '../reducers/genericLoader';
import { notifcationError } from '../reducers/notificationReducer';
import i18n from '../i18n';

const redirect = (pathname, queryParams?) => {
  if (queryParams) {
    return history.push({
      pathname: pathname,
      search: queryParams
    });
  }

  history.push(pathname);
}

const redirectHome = () => {
  redirect(GLOBAL_ROUTES.main);
}

const redirectToDashboard = () => {
  const userRole = getUserRole();

  if (userRole.isPatient()) {
    return redirect(GLOBAL_ROUTES.patientDashboard);
  };

  if (userRole.isPharmacy()) {
    return redirect(GLOBAL_ROUTES.pharmacyDashboard);
  }

  if (userRole.isPhysician()) {
    return redirect(GLOBAL_ROUTES.doctorDashboard);
  }

  return redirectHome();
}

const redirectToLogin = () => {
  removeAuthSession();
  redirect(GLOBAL_ROUTES.login);
}

const redirectToRegistrationComplete = () => {
  redirect(GLOBAL_ROUTES.registerComplete);
}

const redirectToLoginWithError = () => {
  store.dispatch(clearLoader());

  redirectToLogin();

  setTimeout(() => {
    store.dispatch(notifcationError(i18n.t('login.signoutNotice'), 10 * 1000));
  }, 0);
}

export {
  redirect,
  redirectHome,
  redirectToDashboard,
  redirectToLogin,
  redirectToLoginWithError,
  redirectToRegistrationComplete,
};
