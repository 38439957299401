import React, { PropsWithChildren } from "react";
import ErrorBoundaryNotice from './ErrorBoundaryNotice';

class ErrorBoundary extends React.Component {
  state = {
    hasError: false
  };

  constructor(props: PropsWithChildren<any>) {
    super(props);

    this.state = {
      hasError: false
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true
    };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    const { hasError } = this.state;

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorBoundaryNotice />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
