import { FormField } from "../../components/FormElements/FormFieldsRenderer";

const editPrescriptionPatient = (t, requiredFields, pharmacyProps, regionProps) => {
  const formFields: FormField[] = [
    {
      label: t('recipeForm.file'),
      prefix: 'PrescriptionForm',
      isRequired: requiredFields.file,
      fieldType: 'Text',
      isFullWidth: true,
      subType: 'photo',
      name: 'file',
      photoField: 'photo',
      isDisabled: true,
    },
    {
      label: t('recipeForm.title'),
      prefix: 'PrescriptionForm',
      isRequired: requiredFields.title,
      fieldType: 'Text',
      isFullWidth: true,
      name: 'title',
      isDisabled: true,
    },
    {
      label: t('recipeForm.notes'),
      prefix: 'PrescriptionForm',
      isRequired: requiredFields.notes,
      fieldType: 'Text',
      isFullWidth: true,
      subType: 'textarea',
      name: 'notes',
      isDisabled: true,
    },
    {
      label: t(`regionSelector.county`),
      prefix: 'addPatientModal',
      isRequired: true,
      regionProps: regionProps,
      name: 'region',
      fieldType: 'Region'
    },
    {
      label: t('recipeForm.pharmacyList'),
      prefix: 'PrescriptionForm',
      isRequired: requiredFields.pharmacyId,
      fieldType: 'Pharmacy',
      isFullWidth: true,
      name: 'pharmacyId',
      pharmacyProps: pharmacyProps
    }
  ];

  return formFields;
}

export default editPrescriptionPatient;
