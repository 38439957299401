import { COUNTIES_URL, CITIES_URL } from '../urlPatterns';
import { get } from './requestService';
import RequestErrorException from './genericRequestError';

export function getCounties(): Promise<string[]> {
  return get(COUNTIES_URL, {
    size: 100,
    page: 0,
  })
    .then(response => response.districtsDTO || {})
    .then(response => response.map(resp => resp.name).sort())
    .catch(err => {
      throw RequestErrorException(err);
    });
};

export function getCitiesByCountyId(countyId): Promise<string[]> {
  return get(CITIES_URL.replace("{id}", countyId))
    .then(response => response.localities || [])
    .then(response => response.map(resp => resp.name).sort())
    .catch(err => {
      throw RequestErrorException(err);
    });
}
