import React from 'react';
import { withTranslation } from 'react-i18next';
import PrescriptionsListComponent from './PrescriptionList';

const DashboardHome = (props) => {
  const {t} = props;

  return (
    <PrescriptionsListComponent
      title={t('pharmacytDashboard.lastPrescriptions')}
      pageSize={5}
    />
  );
};

export default withTranslation()(DashboardHome);
