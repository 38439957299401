import { getMyPatients } from "../services/patientServices";
import SearchBuilder from '../helpers/searchBuilder';

const patientList = (perPage, page, filter, sortBy='firstName', sortOrder='DESC'): Promise<any> => {
  const search = new SearchBuilder();
  const searchBy = search
    .withStartstWith('lastName', filter)
    .withOr()
    .withStartstWith('userDetails_name', filter)
    .withOr()
    .withStartstWith('idNumber', filter)
    .build();

  return getMyPatients(perPage, page, filter !== '' ? searchBy : '', sortBy, sortOrder)
    .then(result => {
      return {
        data: result.patients,
        page: result.currentPage,
        totalCount: result.totalElements,
      }
    })
}

export default patientList;
