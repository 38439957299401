import { useState } from 'react';
import React from 'react';

const useDeletePrescription = (selectedPrescriptionData, dialog, tableRef) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const toggleDeleteDialog = () => {
    setIsDeleteDialogOpen(!isDeleteDialogOpen);
  };

  const onCloseModal = (needsReload?: boolean) => {
    toggleDeleteDialog();

    if(needsReload && typeof needsReload === 'boolean') {
      tableRef.current && tableRef.current.onQueryChange()
    }
  };

  const renderModal = () => {
    if (!isDeleteDialogOpen) {
      return null;
    }

    const DialogComponent = dialog;
    return (
      <DialogComponent
        onClose={onCloseModal}
        isOpen={true}
        patientData={selectedPrescriptionData}
        subscriptionData={selectedPrescriptionData}
      />
    );
  }
  return {
    isDeleteDialogOpen,
    toggleDeleteDialog,
    deletePrescriptionModal: renderModal()
  };
}

export default useDeletePrescription;
