import React from 'react';
import ProgressBarSilver from './ProgressBarSilver';
import ProgressBarOrange from './ProgressBarOrange';
import ProgressBarRed from './ProgressBarRed';
import ProgressBarGreenish from './ProgressBarGreenish';

export const TYPE_SILVER = 'silver';
export const TYPE_ORANGE = 'orange';
export const TYPE_RED = 'red';
export const TYPE_GREEN = 'green';
export type COLOR_TYPE =
  | typeof TYPE_SILVER
  | typeof TYPE_ORANGE
  | typeof TYPE_RED
  | typeof TYPE_GREEN;

export interface ProgressBarProps {
  strength: number;
  barStyles?: string;
}

const StrengthBar = (props: ProgressBarProps) => {
  const { strength } = props;

  switch (strength) {
    case 0:
      return (
        <ProgressBarSilver value={0} barStyles={props.barStyles} />
      );
    case 1:
      return (
        <ProgressBarRed value={25} barStyles={props.barStyles} />
      );
    case 2:
      return (
        <ProgressBarOrange value={50} barStyles={props.barStyles} />
      );

    default:
      return (
        <ProgressBarGreenish value={100} barStyles={props.barStyles} />
      );
  }
}

export default StrengthBar;
