import React, { PropsWithChildren, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  Container,
  CssBaseline,
  Typography,
} from '@material-ui/core';
import {
  updatePatientProfile,
} from '../services/patientServices';
import {
  notifcationError,
  notificationSuccess
} from '../reducers/notificationReducer';
import editPatientStyles from '../styles/editPatient';
import editPatientProfileForm from '../forms/editPatientOwnProfile/editPatientProfile';
import editPatienrProfileValidations from '../forms/editPatientOwnProfile/editPatienrProfileValidations';
import { redirectToDashboard } from '../helpers/redirect';
import ComponentPlaceholder from '../hocs/ComponentPlaceholder';
import RegistrationForm from '../components/RegistrationForm/RegistrationForm';
import RequestErrorHeader from '../components/FormElements/RequestErrorHeader';
import { FORM_ERROR } from 'final-form';
import useRegions from '../hooks/useRegions';
import { RegionContext } from '../providers/RegionProvider';
import { PatientnDataContext } from '../providers/PatientDataProvider';
import { PharmacyContext } from '../providers/PharmacyProvider';

export interface EditPatientProps {
  patientData: EditPatientDTO;
  t?: any;
  patientId: string;
};

const EditPatient = (props: PropsWithChildren<WithTranslation & EditPatientProps>) => {
  const dispatch = useDispatch(),
    {
      t,
      patientId,
    } = props,

    classes = editPatientStyles(),
    regionProps = useContext(RegionContext),
    patientData = useContext(PatientnDataContext),
    pharmacyContext = useContext(PharmacyContext),
    { handleCountyChange, handleRegionChange } = useRegions(async (countyId, cityId) => {
      pharmacyContext.getPharmacies(countyId, cityId)
    });

  const handleSubmit = async (formData) => {
    formData.pharmacyId = formData.pharmacyId ? formData.pharmacyId : formData.pharmacy && formData.pharmacy.id;
    delete formData.pharmacy;

    try {
      await updatePatientProfile(patientId, formData);
      redirectToDashboard();

      setTimeout(() => {
        dispatch(notificationSuccess(t('patientProfile.updateComplete')));
      }, 0);
    } catch (err) {
      dispatch(notifcationError(err.requestError.message));

      return {
        [FORM_ERROR]: err.requestError.message
      };
    };
  };

  const handleCancel = () => {
    redirectToDashboard();
  }

  return (
    <ComponentPlaceholder
      isComponentLoading={patientData.isLoading}
      isComponentError={patientData.isError}
      error={patientData.error}
      onErrorHandler={handleCancel}
      errorButtonLabel={t('patientProfile.errorButtonLabel')}
    >
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            {t('patientProfile.editProfile')}
          </Typography>

          <RequestErrorHeader
            severity='error'
            requestError={(regionProps as any).countyError}
          />
          <RequestErrorHeader
            severity='error'
            requestError={(regionProps as any).cityError}
          />
          <RequestErrorHeader
            severity='error'
            requestError={(pharmacyContext as any).pharmaciesError}
          />

          <RegistrationForm
            {...props}
            initialValues={patientData.profileData}
            onSubmit={handleSubmit}
            regionProps={regionProps as any}
            pharmacyProps={pharmacyContext as any}
            validationSchema={editPatienrProfileValidations(t)}
            createFormFields={editPatientProfileForm}
            submitButtonLabel={t('patientProfile.registerButtonLabel')}
            onCancel={handleCancel}
            onCountyChange={handleCountyChange}
            onRegionChange={handleRegionChange}
          />
        </div>
      </Container>
    </ComponentPlaceholder>
  )
};


export default withTranslation()(EditPatient);
