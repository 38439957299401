import React from 'react';
import {
  Grid,
  Typography
} from '@material-ui/core';
import {
  Autocomplete,
  AutocompleteData
} from 'mui-rff';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

const filter = createFilterOptions<string>();

export interface PharmacySelectorProps {
  pharmacies: AutocompleteData[]
  isRequired?: boolean,
  isDisabled?: boolean
  isLoadingPharmacies?: boolean,
  name?: string,
  label: string
  inputLabel: string
}

const PharmacySelector = (props) => {
  const {pharmacies, isRequired, isDisabled, isLoadingPharmacies, label} = props,
    name = props.name || 'pharmacyId';

  return (
    <Grid item xs={12} key='grid_city'>
      <Autocomplete
        id={name}
        options={pharmacies || []}
        name={name}
        label={label}
        key={name}
        forcePopupIcon={true}
        disableClearable={true}
        required={isRequired}
        filterOptions={(options: any, params: any) => {
          const autocompleteInput = name ? document.getElementById(name) : null,
            autocompleteInputValue = autocompleteInput ? (autocompleteInput as any).value : '',
            inputParams = { ...params, inputValue: autocompleteInputValue },
            filteredCities = autocompleteInputValue !== ''
              ? filter(options, inputParams) as string[] : options;

          return filteredCities;
        }}
        getOptionSelected={
          (option: any, value: any) => {
            return value ? option.id === value.id : false;
          }
        }
        getOptionValue={
          (option: any) => {
            return option.id;
          }
        }
        getOptionLabel={(option: any) => {
          if(typeof(option) === "string") {
            return option;
          }

          return `${option.name} ${option.address}`;
        }}
        renderOption={(option: any) => (
          <Typography noWrap>{(`${option.name} ${option.address}`)}</Typography>
        )}
        groupBy={(option) => {
          return `${option.cityId} ${option.countyId}`;
        }}
    />
  </Grid>);
};

export default PharmacySelector;
