import i18n from '../i18n';

export interface RequestErrorMessage {
  code: string;
  message: string;
  translatedMessage?: string;
  parameters?: string;
}

export interface RequestError {
  errors: RequestErrorMessage[];
  message: string;
  status?: number;
};

export class GenericErrorMessage {
  errors: RequestError = {
    errors: [],
    message: '',
    status: 500
  };
  status: number = 500;

  constructor(serverErrors, status) {
    this.status = status;
    
    if (Array.isArray(serverErrors)) {
      this.errors = this.fromArray(serverErrors);

      return;
    }

    if(serverErrors.exceptions && Array.isArray(serverErrors.exceptions)) {
      this.errors = this.fromArray(serverErrors.exceptions);

      return;
    }
    
    this.errors = this.fromException(serverErrors);
  }

  getTranslation(code, message) {
    const translationKey = `errors.err${code}`;

    return i18n.exists(translationKey) ? i18n.t(translationKey) : message;
  }

  fromArray(errors: RequestErrorMessage[] = []): RequestError {
    const agumentedErrors = errors.map(err => {
      return {
        ...err,
        translatedMessage: this.getTranslation(err.code, err.message)
      };
    });

    const errMessage = agumentedErrors.map(err => err.translatedMessage).join('\n');

    return {
      errors: agumentedErrors,
      message: errMessage,
      status: this.status
    };
  }

  fromException(err): RequestError {
    const error = err.toJSON ? err.toJSON() : {
      message: err.message,
      translatedMessage: err.message,
      code: err.name || err.status
    };

    error.translatedMessage = this.getTranslation(err.name || err.status, err.message);

    return {
      errors: [error],
      message: error.translatedMessage,
      status: this.status
    };
  }

  getErrors(): RequestError {
    return this.errors;
  }
}

export class CustomRequestError extends Error {
  requestError: RequestError;

  constructor(message, requestError) {
    super(message);

    this.message = message;
    this.name = "CustomRequestError";
    this.requestError = requestError;
  }
}

const RequestErrorException = (errors) => {
  const extractedErrors = errors.data ? errors.data : errors,
    status = errors.status || 500;

  const augmentedErrors = new GenericErrorMessage(extractedErrors, status).getErrors();

  return new CustomRequestError(augmentedErrors.message, augmentedErrors);

}

export default RequestErrorException;
