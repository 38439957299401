import React from 'react';
import { Container, Typography, Grid, makeStyles, Button } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import PublicAppHeader from '../components/PublicAppHeader/PublicAppHeader';
import Backspace from '@material-ui/icons/Backspace';
import { redirectToLogin } from '../helpers/redirect';
import AppFooter from '../components/AppFooter/AppFooter';

const publicFormWrapperStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '50px',
    marginBottom: '50px'
  },
  avatar: {
    margin: theme.spacing(2),
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: 'transparent'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  formContainer: {
    marginTop: theme.spacing(0)
  }
}));

const RegistrationComplete = (props) => {
  const classes = publicFormWrapperStyles(),
    { t } = props;

  return (
    <Container component="div" maxWidth='sm'>
      <PublicAppHeader />
      <Container component="main" className={classes.paper}>
        <Typography component="h1" variant="h5" className={classes.title}>
          {t('registrationComplete.title')}
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <Button
              type="button"
              fullWidth
              variant="outlined"
              color="primary"
              startIcon={<Backspace />}
              onClick={redirectToLogin}
            >
              {t('registrationComplete.back')}
            </Button>
          </Grid>
        </Grid>

      </Container>
    </Container>
  )
};

export default withTranslation()(RegistrationComplete);
