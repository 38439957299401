import React from 'react';
import EditPrescriptionModalContainer from '../../containers/modals/EditPrescriptionModalContainer';
import editPrescriptionForm from '../../forms/editPrescriptionPharmacy/editPrescription';
import editPrescriptionValidations from '../../forms/editPrescriptionPharmacy/editPrescriptionValidations';

const EditPharmacyPrescription = (props) => (
    <EditPrescriptionModalContainer
      {...props}
      fromConfig={editPrescriptionForm}
      validationConfig={editPrescriptionValidations} />
);

export default EditPharmacyPrescription;
