import React, { useContext } from "react";
import { connect, useDispatch } from 'react-redux';
import { withTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";

import PrescriptionForm from "../../components/PrescriptionForm/PrescriptionForm";
import ComponentPlaceholder from "../../hocs/ComponentPlaceholder";
import RequestErrorHeader from "../../components/FormElements/RequestErrorHeader";
import { updatePrescription } from "../../services/prescriptionServices";
import { notificationSuccess, notifcationError } from "../../reducers/notificationReducer";
import { FORM_ERROR } from "final-form";
import { dataToFormData } from "../../services/requestService";
import editPrescriptionForm from '../../forms/editPrescriptionPhysician/editPrescription';
import editPrescriptionValidations from '../../forms/editPrescriptionPhysician/editPrescriptionValidations';
import filterFields from '../../forms/addPatientModal/filterFields';
import useRegions from "../../hooks/useRegions";
import { PharmacyContext } from "../../providers/PharmacyProvider";
import { PrescriptionContext } from "../../providers/PrescriptionProvider";
import { RegionContext } from "../../providers/RegionProvider";

const EditPrescriptionModal = (props) => {
  const {
    isOpen,
    onClose,
    prescriptionData,
    fromConfig,
    validationConfig,
    t
  } = props,
    { id: prescriptionId } = prescriptionData,
    dispatch = useDispatch(),
    regionProps = useContext(RegionContext),
    pharmacyContext = useContext(PharmacyContext),
    { handleCountyChange, handleRegionChange } = useRegions(async (countyId, cityId) => {
      pharmacyContext.getPharmacies(countyId, cityId)
    }),
    prescriptionContext = useContext(PrescriptionContext);
  
  const handleSubmit = async (formData) => {
      try {
        const filteredFormData = filterFields(formData);
        await updatePrescription(prescriptionId, dataToFormData(filteredFormData, ['file']));

        onClose(true);

        setTimeout(() => {
          dispatch(notificationSuccess(t('recipeForm.updateComplete')));
        }, 0);
      } catch (err) {
        dispatch(notifcationError(err.requestError.message));

        return {
          [FORM_ERROR]: err.requestError.message
        };
      };
    };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="add-receipe-dialog-title"
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
    >
      <DialogTitle id="add-receipe-dialog-title">{t('addRecipe.edit')}</DialogTitle>
      <DialogContent>

        <ComponentPlaceholder
          isComponentLoading={prescriptionContext.isLoading}
          isComponentError={prescriptionContext.isError}
          error={prescriptionContext.error}
          onErrorHandler={onClose}
          errorButtonLabel={t('recipeForm.errorButtonLabelModal')}
        >
          <RequestErrorHeader
            severity='error'
            requestError={(pharmacyContext as any).pharmaciesError}
          />

          <PrescriptionForm
            onSubmit={handleSubmit}
            patientData={prescriptionContext.patientProfile as PateintInterface}
            initialValues={prescriptionContext.prescriptionData}
            pharmacyProps={pharmacyContext as any}
            onCancel={onClose}
            validationSchema={(validationConfig || editPrescriptionValidations)(t)}
            createFormFields={fromConfig || editPrescriptionForm}
            submitButtonLabel={t('recipeForm.registerButtonLabel')}
            backButtonLabel={t('recipeForm.backLabel')}
            onCountyChange={handleCountyChange}
            onRegionChange={handleRegionChange}
            regionProps={regionProps as any}
          />
        </ComponentPlaceholder>
      </DialogContent>
    </Dialog>
  );
};

export default withTranslation()(EditPrescriptionModal);
