import React from 'react';
import {
  Backdrop,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
      display: 'flex',
      flexDirection: 'column'
    },
  }),
);

const GenericLoader = (props) => {
  const classes = useStyles();

  return props.showLoader ? (
    <div>
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress color="inherit" />

        <div>
          <Typography component="h2" variant="h5">
            {props.message}
          </Typography>
        </div>
      </Backdrop>
    </div>
  ) : null;
}

export default GenericLoader;
