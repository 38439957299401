import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  Container,
  CssBaseline,
  Typography,
} from '@material-ui/core';
import RecoverPasswordForm from '../components/RecoverPassword/RecoverPasswordForm';
import recoverPasswordStyles from '../styles/recoverPassword';

const RecoverPassword = props => {
  const { t } = props,
    classes = recoverPasswordStyles();

  const handleSubmit = () => {
    console.log('Submitting ....');
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          {t('recoverPassword.title')}
        </Typography>

        <RecoverPasswordForm
          {...props}
          onSubmit={handleSubmit}
        />
      </div>
    </Container>
  )
};


export default withTranslation()(RecoverPassword);
