import { makeStyles } from '@material-ui/core/styles';

const recoverPassword = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
}));

export default recoverPassword;
