import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';

export interface genericLoaderState {
  showLoader: boolean,
  message: string
};

export const initialState: genericLoaderState = {
  showLoader: false,
  message: ''
};

const genericLoaderReducer = createSlice({
  'name': 'notifications',
  initialState,
  reducers: {
    displayLoader: (state, action: PayloadAction<string>): genericLoaderState => {
      return {
        showLoader: true,
        message: action.payload
      };
    },
    clearLoader: (state): genericLoaderState => {
      return {
        showLoader: false,
        message: ''
      };
    }
  }
});

export const {
  displayLoader,
  clearLoader,
} = genericLoaderReducer.actions;

export default genericLoaderReducer.reducer;

export const showLoader = createAction('SHOW_GENERIC_LOADER', (message) => {
  return {
    type: displayLoader.type,
    payload: message
  };
});
export const hideLoader = createAction('HIDE_GENERIC_LOADER', () => {
  return {
    type: clearLoader.type,
    payload: {}
  };
});
