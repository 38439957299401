import React from 'react';
import {
    useParams
} from "react-router-dom";
import EditPhysicianContainer from '../containers/EditPhysicianContainer';
import PhysicianDataProvider from '../providers/PhysicianDataProvider';
import RegionProvider from '../providers/RegionProvider';
import { getLoggedInUserId } from '../helpers/authSession';

export default (props) => {
    const getPhysicianId = (params) => {
        const { doctorId } = params,
            loggedInId = getLoggedInUserId();

        return doctorId || loggedInId;
    },
        physicianId = getPhysicianId(useParams());

    return (<RegionProvider>
        <PhysicianDataProvider physicianId={physicianId}>
            <EditPhysicianContainer {...props} physicianId={physicianId} />
        </PhysicianDataProvider>
    </RegionProvider>
    )
};
