import { makeStyles } from '@material-ui/core/styles';

const publicAppHeader = makeStyles((theme) => ({
  main: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    height: "50px"
  }
}));

export default publicAppHeader;
