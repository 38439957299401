import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  registerPatient,
} from '../services/patientServices';
import { notifcationError, notificationSuccess } from '../reducers/notificationReducer';
import registerPatientForm from '../forms/registerPatient/registerPatient';
import registerPatientValidations from '../forms/registerPatient/registerPatientValidations';
import RegistrationForm from '../components/RegistrationForm/RegistrationForm';
import AccountCircle from '@material-ui/icons/AccountCircle';
import PublicFormWrapper from '../components/PublicFormWrapper/PublicFormWrapper';
import RequestErrorHeader from '../components/FormElements/RequestErrorHeader';
import { FORM_ERROR } from 'final-form';
import { redirectToRegistrationComplete } from '../helpers/redirect';
import useRegions from '../hooks/useRegions';
import { RegionContext } from '../providers/RegionProvider';
import { PharmacyContext } from '../providers/PharmacyProvider';

const RegisterPatient = props => {
  const dispatch = useDispatch(),
    { t } = props,
    regionProps = useContext(RegionContext),
    pharmacyContext = useContext(PharmacyContext),
    { handleCountyChange, handleRegionChange } = useRegions(async (countyId, cityId) => {
      pharmacyContext.getPharmacies(countyId, cityId)
    });

  const handleSubmit = async (formData) => {
    formData.pharmacyId = formData.pharmacy && formData.pharmacy.id;
    delete formData.pharmacy;

    try {
      await registerPatient(formData);
      redirectToRegistrationComplete();

      setTimeout(() => {
        dispatch(notificationSuccess(t('patientProfile.registrationComplete')));
      }, 0);
    } catch (err) {
      dispatch(notifcationError(err.requestError.message));

      return {
        [FORM_ERROR]: err.requestError.message
      };
    };
  };

  return (
    <PublicFormWrapper
      icons={AccountCircle}
      title={t('registerPatient.title')}
    >
      <RequestErrorHeader
        severity='error'
        requestError={regionProps.countyError as any}
      />
      <RequestErrorHeader
        severity='error'
        requestError={regionProps.cityError as any}
      />
      <RequestErrorHeader
        severity='error'
        requestError={(pharmacyContext as any).pharmaciesError}
      />
      <RegistrationForm
        {...props}
        onSubmit={handleSubmit}
        regionProps={regionProps}
        pharmacyProps={pharmacyContext as any}
        onCountyChange={handleCountyChange}
        onRegionChange={handleRegionChange}
        validationSchema={registerPatientValidations(t)}
        createFormFields={registerPatientForm}
        submitButtonLabel={t('patientProfile.registerButtonLabel')}
      />
    </PublicFormWrapper>
  )
};

export default withTranslation()(RegisterPatient);
