import { withTranslation } from "react-i18next";
import ConfirmDialog from '../components/ConfirmDialog/ConfirmDialog';
import React from "react";
import { useDispatch } from 'react-redux';
import { deletePrescription } from "../services/prescriptionServices";
import { notificationSuccess, notifcationError } from "../reducers/notificationReducer";

const DeletePrescriptionModal = (props) => {
  const { isOpen, subscriptionData, onClose, t } = props,
    dispatch = useDispatch();

  const handleConfirm = async () => {
    try {
      await deletePrescription(subscriptionData.id);
      onClose(true);

      setTimeout(() => {
        dispatch(notificationSuccess(t('prescriptionList.deleteSuccess')));
      }, 0);
    } catch (err) {
      dispatch(notifcationError(err.requestError.message));
    };
  }

  return (
    <ConfirmDialog
      title={'Sterge reteta?'}
      open={isOpen}
      setOpen={onClose}
      okLabel={'Sterge'}
      cancelLabel={'Renunta'}
      onConfirm={handleConfirm}
    >
      Sunteti sigur ca doriti sa stergeti {subscriptionData.title} {subscriptionData.notes}

      <img
        src={`data:image/png;base64,${subscriptionData.photo}`}
        alt={subscriptionData.title}
      />
    </ConfirmDialog>
  )
}

export default withTranslation()(DeletePrescriptionModal);
