export default (fields = {}) => {
  return Object.keys(fields).filter(field => {
    return field === 'pharmacyId'
      || field === 'patientId'
      || field === 'title'
      || field === 'notes'
      || field === 'file'
  }).reduce((res, key) => {
    res[key] = fields[key];

    return res;
  }, {});
}
