export const SERVER_URL = 'https://api.farmaonline-delivery.ro/api/';
export const LOGIN_URL = `/v1/access-token`;
export const LOGOUT_URL = `/v1/access-token`;
export const REFRESH_TOKEN_URL = `/v1/access-token`;
export const COUNTIES_URL = `/v1/districts/`;
export const CITIES_URL = `/v1/districts/{id}`;
export const PHARMACIES_URL_COUNTY = `/v1/pharmacies/location/{countyId}`;
export const PHARMACIES_URL_COUNTY_CITY = `/v1/pharmacies/location/{countyId}/{cityId}`;
export const PATEIENT_URLS = {
  register: `/v1/patients`,
  getAll: `/v1/patients`,
  getById: `/v1/patients/{id}`,
  updateProfile: `/v1/patients/{id}`,
  myPatients: `/v1/patients/`,
};

export const PHYSICIAN_URL = {
  register: `/v1/physicians`,
  getById: `/v1/physicians/{id}`,
  updateProfile: `/v1/physicians/{id}`,
  pescriptions: `/v1/physicians/prescriptions`,
  patientPrescriptions: `/v1/physicians/prescriptions/{id}`,
};

export const PHARMACY_URL = {
  register: `/v1/pharmacies`,
  getById: `/v1/pharmacies/{id}`,
  updateProfile: `/v1/pharmacies/{id}`,
  pescriptions: `/v1/pharmacies/prescriptions`,
};

export const PRESCRIPTION_URL = {
  register: `/v1/prescriptions`,
  getById: `/v1/prescriptions/{id}`,
  updatePrescription: `/v1/prescriptions/{id}`,
  prescriptions: `/v1/prescriptions/`,
  getByPatientId: `/v1/prescriptions/`,
};

export const AUTHENTICATION = {
  login: '/v1/access-token',
  logout: '/v1/access-token',
  refresh: '/v1/access-token',
};

export const USERS_URL = {
  me: '/v1/users/me',
};

export const CREDENTIALS_URL = {
  requirements: '/v1/credential/',
  changePassword: '/v1/credential/'
}

export const INVITATIONS_URL = {
  getInvitation: '/v1/invitations/{id}'
}

export const PRESCRIPTION_FILTERS = [
  'NEW',
  'IN_PROGRESS',
  'PREPARED',
  'DELIVERED',
  'UNAVAILABLE',
  'ARCHIVED'
]
