import { makeStyles } from '@material-ui/core/styles';

const PatientProfileFormStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paperPatient: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px'
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  inline: {
    display: 'inline',
  },
  preview: {
    width: '50px',
    height: '50px'
  }
}));

export default PatientProfileFormStyles;
