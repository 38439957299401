import React, { useEffect, useState } from 'react';
import {
  useParams
} from "react-router-dom";
import { withTranslation } from 'react-i18next';
import ComponentPlaceholder from '../hocs/ComponentPlaceholder';
import UserRole from '../helpers/userRole';
import RegisterPhysician from './RegisterPhysician';
import RegisterPatient from './RegisterPatient';
import RegisterPharmacy from './RegisterPharmacy';
import { getInvite } from '../services/invitationService';
import RegionProvider from '../providers/RegionProvider';
import PharmacyProvider from '../providers/PharmacyProvider';

const RegisterWithInvitation = props => {
  const params = useParams(),
    invitationId = params.invitationId,
    [invitationData, setInvitationData] = useState({}),
    [isLoading, setIsLoading] = useState(true),
    [hasError, setHasError] = useState(false);

  const getComponentByRole = function () {
    const role = new UserRole((invitationData as any).userRole);

    if (role.isPhysician()) {
      return <RegisterPhysician invitationData={invitationData}></RegisterPhysician>
    }

    if (role.isPatient()) {
      return <RegisterPatient invitationData={invitationData}></RegisterPatient>
    }

    if (role.isPharmacy()) {
      return <RegisterPharmacy invitationData={invitationData}></RegisterPharmacy>
    }

    return <div>Cannot get component by current role!</div>
  }

  useEffect(() => {
    (async () => {
      try {
        const invitation = await getInvite(invitationId);
        setInvitationData(invitation);
      }
      catch (exc) {
        setHasError(true);
      }
      finally {
        setIsLoading(false);
      }
    })();
  }, []);

  if (!invitationId) {
    return <div>No valid invitation id!</div>;
  }

  return (
    <ComponentPlaceholder
      isComponentLoading={isLoading}
      isComponentError={hasError}
      error={hasError}
    >
      <RegionProvider>
        <PharmacyProvider>
          {getComponentByRole()}
        </PharmacyProvider>
      </RegionProvider>
    </ComponentPlaceholder>
  )
};


export default withTranslation()(RegisterWithInvitation);
