import React, { PropsWithChildren, useEffect, useRef } from 'react';
import {
  Grid,
  Card,
  CardContent,
  makeStyles,
  Theme,
  createStyles,
  List,
  ListItem,
  Typography,
  Divider,
  Dialog,
  DialogContent
} from '@material-ui/core';
import { withTranslation, WithTranslation } from 'react-i18next';
import ReactToPrint from 'react-to-print';
import usePrintPrescription from '../hooks/usePrintPrescription';
import ComponentPlaceholder from '../hocs/ComponentPlaceholder';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "33.33%",
      flexShrink: 0
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary
    },
    card: {
      minWidth: 275,
    },
  }),
);

const renderPatientData = (patientData, classes, t) => (
  <Card className={classes.card}>
    <CardContent>
      {t('recipeForm.patientLabel')} {patientData.firstName} {patientData.lastName}, {patientData.idNumber}
    </CardContent>
  </Card>
)

const renderPrescription = (prescriptionData, classes, t) => {
  const {
    title,
    photo
  } = prescriptionData;

  return (
    <Card className={classes.card}>
      <CardContent>
        <List className={classes.root}>
          <ListItem>
            <img src={`data:image/png;base64,${photo}`} alt={title} />
          </ListItem>
          <ListItem>
            <Typography className={classes.heading}>{t('recipeForm.title')}</Typography>
            <Typography className={classes.secondaryHeading}>
              {prescriptionData.title}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography className={classes.heading}>{t('recipeForm.notes')}</Typography>
            <Typography className={classes.secondaryHeading}>
              {prescriptionData.notes}
            </Typography>
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
}

export interface PrintPrescriptionProps extends PropsWithChildren<WithTranslation> {
  patientData: Patient;
  prescriptionData: any;
  patinetId?: string;
  isOpen: boolean;
  onClose: () => void;
}

export interface PrintContentProps extends PropsWithChildren<any> {
  profileData: Patient;
  prescriptionData: any;
  classes: any;
  t?: any;
  i18n?: any;
  tReady?: any;
}

class PrintContent extends React.Component<PrintContentProps> {
  render() {
    const {
      profileData,
      prescriptionData,
      classes,
      t
    } = this.props;
  
    return (
      <Grid container spacing={1} >
        <Grid item xs={12}>
          {profileData ? renderPatientData(profileData, classes, t) : null}
        </Grid>
  
        <Divider />
  
        <Grid item xs={12}>
          {renderPrescription(prescriptionData, classes, t)}
        </Grid>
      </Grid>
    );
  }
}

const PrintPrescription = (props: PrintPrescriptionProps) => {
  const {
    patientData,
    prescriptionData,
    patinetId,
    isOpen,
    onClose,
    t
  } = props,
    {
      initialValues,
      profileData
    } = usePrintPrescription(
      patientData,
      (prescriptionData && prescriptionData.patientId) || patinetId,
      prescriptionData && prescriptionData.pharmacyId,
      t
    ),
    classes = useStyles(),
    componentRef = useRef(),
    printButtonRef = useRef();

  useEffect(() => {
    if (profileData) {
      setTimeout(() => {
        if(printButtonRef && printButtonRef.current) {
          const hasOnAfterPrintSupport = window.onafterprint;

          if (hasOnAfterPrintSupport) {
            window.onafterprint = () => {
              onClose();
            }
          }
  
          (printButtonRef.current as any).click();
  
          if (!hasOnAfterPrintSupport) {
            onClose();
          }
        }

      }, 500);
    }
  }, [profileData]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="add-receipe-dialog-title"
      disableEscapeKeyDown={false}
      disableBackdropClick={false}
      transitionDuration={0}
      fullScreen={!initialValues.error}
      className={'print-content'}
    >
      <DialogContent>

        <ComponentPlaceholder
          isComponentLoading={initialValues.isLoading}
          isComponentError={initialValues.error !== undefined}
          error={initialValues.error}
          onErrorHandler={onClose}
          errorButtonLabel={t('recipeForm.errorButtonLabelModal')}
        >
          <ReactToPrint
            trigger={() => <button ref={printButtonRef as any}>Print this out!</button>}
            content={() => componentRef.current as any}
          />
          <PrintContent
            profileData={profileData as Patient}
            prescriptionData={prescriptionData}
            t={t}
            classes={classes}
            ref={componentRef as any}
          />
        </ComponentPlaceholder>
      </DialogContent>
    </Dialog>
  )
};

export default withTranslation()(PrintPrescription);
