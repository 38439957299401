import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  useParams
} from "react-router-dom";
import PrescriptionListComponent from '../../components/PrescriptionsList/PrescriptionList';
import EditPrescriptionModalContainer from '../../containers/modals/EditPrescriptionModalContainer';
import DeletePrescriptionModal from '../../containers/DeletePrescriptionModal';
import AddPrescriptionModalContainer from '../../containers/modals/AddPrescriptionModalContainer';
import usePrescriptionLoader from './usePrescriptionLoader';
import ComponentPlaceholder from '../../hocs/ComponentPlaceholder';
import { Divider } from '@material-ui/core';
import PatientDetailsShort from '../../components/PatientDetailsShort/PatientDetailsShort';
import ChoosePatient from '../../containers/ChoosePatientContainer';
import { redirectToPatientRecipies } from './routes';
import PrintPrescription from '../../containers/PrintPrescription';

const PatientPrescriptions = (props) => {
  const actions: string[] = [
    'add',
    'edit',
    'delete',
    'print'
  ],
    { patientId } = useParams(),
    { t } = props,
    { initialValues, profileData } = usePrescriptionLoader(patientId, t),
    onClose = () => { };

  const handlePatientSelect = (patient) => {
    if (patient) {
      redirectToPatientRecipies(patient.id);
    }
  }

  return (
    <ComponentPlaceholder
      isComponentLoading={initialValues.isLoading}
      isComponentError={initialValues.error !== undefined}
      error={initialValues.error}
      onErrorHandler={onClose}
      errorButtonLabel={t('recipeForm.errorButtonLabelModal')}
    >
      <PatientDetailsShort profileData={profileData}>
        <ChoosePatient onSelect={handlePatientSelect} />
      </PatientDetailsShort>

      <Divider />
      
      <PrescriptionListComponent
        pageSize={props.pageSize || 10}
        editPrescriptionDialog={EditPrescriptionModalContainer}
        deletePrescriptionDialog={DeletePrescriptionModal}
        addPrescriptionDialog={AddPrescriptionModalContainer}
        printPrescriptionDialog={PrintPrescription}
        actions={actions}
        patientData={profileData}
        patientId={(profileData as any || {}).id}
      />
    </ComponentPlaceholder>
  );
};

export default withTranslation()(PatientPrescriptions);
