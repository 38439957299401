import React, { useContext } from 'react';
import { useDispatch, connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  useParams
} from "react-router-dom";
import {
  Container,
  CssBaseline,
  Typography,
} from '@material-ui/core';
import {
  updatePharmacyProfile,
} from '../../services/pharmaciesService';
import {
  notifcationError,
  notificationSuccess
} from '../../reducers/notificationReducer';

import editPharmacyStyles from '../../styles/editPharmacy';
import RegistrationForm from '../../components/RegistrationForm/RegistrationForm';
import editPharmacyForm from '../../forms/editPharmacy/editPharmacy';
import editPharmacyValidations from '../../forms/editPharmacy/editPharmacyValidations';
import ComponentPlaceholder from '../../hocs/ComponentPlaceholder';
import { redirectToDashboard } from '../../helpers/redirect';
import { FORM_ERROR } from 'final-form';
import useRegions from '../../hooks/useRegions';
import RequestErrorHeader from '../../components/FormElements/RequestErrorHeader';
import { PharmacyDataContext } from '../../providers/PharmacyDataProvider';
import { RegionContext } from '../../providers/RegionProvider';

const EditPharmacyProfile = props => {
  const dispatch = useDispatch(),
    { t } = props,
    classes = editPharmacyStyles(),
    { handleCountyChange, handleRegionChange } = useRegions(),
    regionProps = useContext(RegionContext),
    pharmacyContext = useContext(PharmacyDataContext);

  const handleSubmit = async (formData) => {
    try {
      await updatePharmacyProfile(pharmacyContext.profileData.id, formData);
      redirectToDashboard();

      setTimeout(() => {
        dispatch(notificationSuccess(t('pharmacyProfile.updateComplete')));
      }, 0);
    } catch (err) {
      dispatch(notifcationError(err.requestError.message));

      return {
        [FORM_ERROR]: err.requestError.message
      };
    };
  };

  const handleCancel = () => { 
    redirectToDashboard();
  }
  return (
    <ComponentPlaceholder
      isComponentLoading={pharmacyContext.isLoading}
      isComponentError={pharmacyContext.isError}
      error={pharmacyContext.error}
      onErrorHandler={redirectToDashboard}
      errorButtonLabel={t('pharmacyProfile.errorButtonLabel')}
    >
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            {t('pharmacyProfile.editProfile')}
          </Typography>

          <RequestErrorHeader
            severity='error'
            requestError={(regionProps as any).countyError}
          />
          <RequestErrorHeader
            severity='error'
            requestError={(regionProps as any).cityError}
          />

          <RegistrationForm
            {...props}
            initialValues={pharmacyContext.profileData}
            onSubmit={handleSubmit}
            regionProps={regionProps}
            validationSchema={editPharmacyValidations(t)}
            createFormFields={editPharmacyForm}
            submitButtonLabel={t('pharmacyProfile.registerButtonLabel')}
            onCancel={handleCancel}
            onCountyChange={handleCountyChange}
            onRegionChange={handleRegionChange}
          />
        </div>
      </Container>
    </ComponentPlaceholder>
  )
};

export default withTranslation()(EditPharmacyProfile);

